import { NavLink } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
    paddingTop: "150px",

    "& .auc-text-wrapper a": {
      color: theme.palette.primary.main,
    },
  },
  mainHeading: {
    fontSize: "65px",
    fontWeight: 700,
    lineHeight: "83px",

    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
      marginTop: theme.spacing(3),
      lineHeight: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "30px",
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "20px",
      marginTop: theme.spacing(3),
    },
  },
  smallHeading: {
    fontWeight: "bold",
    marginTop: "60px",
    marginBottom: "25px",
    fontSize: "25px",
  },
  extraSmallHeading: {
    fontWeight: "bold",
    marginTop: "60px",
    fontSize: "20px",
    lineHeight: "30px",
  },
}));

function TermsMedicalExpert(props) {
  document.body.style.background = "#fff";

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <div className={`${classes.termsWrapper} auc-text-wrapper`}>
          <Typography
            align="center"
            className={classes.mainHeading}
            variant="h1"
          >
            Terms of Use
          </Typography>
          <Typography align="center">
            Date last modified: October 26, 2020
          </Typography>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              What is this document?
            </Typography>
          </p>
          <p>
            These Terms of Use ("Terms" or "TOU") is an agreement between you
            and Tova Medical, LLC. ("Tova Medical"). It describes the rules you
            agree to follow when using our mobile applications and website(s)
            (the "Apps"), including when you view or input content on or into
            the Apps.
          </p>
          <p>
            The Tova Medical{" "}
            <NavLink className={classes.navLink} to="/privacy">
              Privacy Statement
            </NavLink>{" "}
            and{" "}
            <NavLink className={classes.navLink} to="/cookies">
              Cookie Policy
            </NavLink>{" "}
            govern all privacy and data use related to our Apps. These are
            separate documents, but are incorporated into this TOU, and you
            should read them.
          </p>
          <p>
            These Terms of Use apply to your use of Tova Medical as a Medical
            Expert. Members and patients participating on Tova Medical are
            subject to different guidelines and terms, referenced below (which
            can be found{" "}
            <NavLink className={classes.navLink} to="/terms/">
              here
            </NavLink>
            ). If you are viewing these Terms of Use for Medical Experts in
            error and you are a patient or a member, please review and accept
            the Terms of Use for Users.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Definitions
            </Typography>
          </p>
          <p>
            To make this document shorter and clearer, we've included some
            definitions:
          </p>
          <ul>
            <li>
              <p>
                "Tova Medical" or the "Apps" means{" "}
                <NavLink className={classes.navLink} to="">
                  https://www.TovaMedical.com
                </NavLink>{" "}
                and related web sites and Tova Medical's mobile applications
                (including Tova Health, Tova Health for Doctors, and any other
                Apps we offer).{" "}
              </p>
            </li>
            <li>
              <p>
                "Content" means text, graphics, images, and any other material
                entered, processed, contained on or accessed through the Apps,
                including Content created, modified, or submitted by Medical
                Experts.
              </p>
            </li>
            <li>
              <p>"Services" means services provided through the Apps.</p>
            </li>
            <li>
              <p>
                "Premium Services" means paid services provided through the
                Apps.
              </p>
            </li>
            <li>
              <p>
                "Medical Expert" means a medical specialist or professional
                accepted to participate on or through the Apps.
              </p>
            </li>
            <li>
              <p>
                "Virtual Consult" means a consultation between a Medical Expert
                and a patient on the Apps.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Introduction
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Tova Medical Services.
            </Typography>
          </p>
          <p>
            Tova Medical connects individuals with doctors and trusted health
            information. Tova Medical offers standard (free) and premium (paid)
            services, available through the same Apps. Tova Medical standard
            services encompass health information and not healthcare. Tova
            Medical Premium Services may be used for healthcare via virtual
            doctor consults.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              These Terms of Use are an Agreement.
            </Typography>
          </p>
          <p>
            Generally, this agreement governs your use of the Apps. Other terms
            may apply to your use of a specific feature. If there is a conflict
            between this TOU and terms posted for a specific feature, the latter
            terms apply to your use of that feature or part.
          </p>
          <p>
            <Typography className={classes.extraSmallHeading} variant="h3">
              THESE TERMS OF USE REQUIRE THE USE OF ARBITRATION TO RESOLVE
              DISPUTES AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT
              OF A DISPUTE. BY ACCEPTING THESE TERMS, YOU ARE WAIVING THE RIGHT
              TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
              REPRESENTATIVE PROCEEDING. SEE{" "}
            </Typography>
            <NavLink
              className={classes.navLink}
              to="/terms/medical-experts/#arbitrationAgreement"
            >
              <Typography className={classes.extraSmallHeading} variant="h3">
                ARBITRATION AGREEMENT
              </Typography>
            </NavLink>
            <Typography className={classes.smallHeading} variant="h3">
              .
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              We May Update this Agreement.
            </Typography>
          </p>
          <p>
            These Terms of Use may change on a going-forward basis at any time
            upon 7 days' notice. Please check these Terms periodically for
            changes. If a change to these Terms materially modifies your rights
            or obligations, we may require that you accept the modified Terms in
            order to continue to use the Service. Material modifications are
            effective upon your acceptance of the modified Terms. Immaterial
            modifications are effective upon publication. If you do not agree to
            all of these Terms of Use, please do not use Tova Medical.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Tova Medical Medical Experts
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Independence of Tova Medical Medical Experts.
            </Typography>
          </p>
          <p>
            Tova Medical Medical Experts, professionals, and specialists
            utilizing or featured on Tova Medical are not employees or
            independent contractor service providers of Tova Medical. Any
            opinions, advice, or information expressed by any such individuals
            are those of the individual and the individual alone, and they do
            not reflect the opinions of Tova Medical. Tova Medical does not
            recommend or endorse any specific tests, physicians, products,
            procedures, opinions, or other information that may be mentioned on
            Tova Medical or by a licensee of Tova Medical. (Medical Experts on
            Tova Medical Premium Services are independent contractors of
            separate professional corporations.)
          </p>
          <p>
            The inclusion of Medical Experts, professionals, and specialists on
            Tova Medical or in any professional directory on Tova Medical does
            not imply Tova Medical&rsquo;s recommendation or endorsement of such
            professional nor is such information intended as a tool for
            verifying the credentials, qualifications, or abilities of any
            professional. SUCH INFORMATION IS PROVIDED ON AN "AS-IS" BASIS AND
            TOVA MEDICAL DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED,
            INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. TOVA MEDICAL
            SHALL IN NO EVENT BE LIABLE TO YOU OR TO ANYONE FOR ANY DECISION
            MADE OR ACTION TAKEN BY ANY PARTY (INCLUDING, WITHOUT LIMITATION,
            ANY USER) IN RELIANCE ON INFORMATION ABOUT MEDICAL EXPERTS,
            PROFESSIONALS, AND SPECIALISTS ON TOVA MEDICAL. The use of Tova
            Medical by any entity or individual to verify the credentials of
            professionals or specialists is prohibited.
          </p>
          <p>
            All opinions and statements expressed by Tova Medical Medical
            Experts on or through Tova Medical are solely the individual,
            independent opinions and statements of such individuals and do not
            reflect the opinions of Tova Medical, its affiliates, or any other
            organizations or institutions to which such Tova Medical Medical
            Expert or such specialist or professional is affiliated with or
            provides services through.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Your Account and Your Use of Tova Medical
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              You are responsible for your account. Keep your password and real
              name private.
            </Typography>
          </p>
          <p>
            Accurate and complete registration information is required to use
            Tova Medical. You are solely responsible for the security of your
            passwords and for any use of your account, including any access to
            personal information in your account. If you suspect unauthorized
            use of your account, change your password immediately. Allowing any
            other person or entity to use your identity for posting on or using
            Tova Medical is not permitted.
          </p>
          <p>
            We reserve the right to revoke or deactivate your username and
            password at any time. You may terminate your account and these Terms
            at any time by selecting to deactivate your account in the settings
            page or visiting the customer service help center at{" "}
            <NavLink className={classes.navLink} to="/support">
              https://support.TovaMedical.com
            </NavLink>
            .
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              You must be an adult to use Tova Medical.
            </Typography>
          </p>
          <p>You must be at least 18 years old to use Tova Medical.</p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Your use must be Legal and Appropriate.
            </Typography>
          </p>
          <p>
            Your use of Tova Medical and any Content and Services must comply
            with all applicable federal and state laws, regulations, and
            ordinances. You may not access our networks, computers, or the
            Content and Services in any manner that could damage, disable,
            overburden, or impair them, or interfere with any other person's use
            and enjoyment. You may not attempt to gain unauthorized access to
            any Content or Services, other accounts, computer systems, or
            networks connected to Tova Medical, the Content, or Services. You
            may not use any automated means (such as a scraper) to access Tova
            Medical, the Content, or Services. Unauthorized access includes
            using credentials to access Tova Medical. Any attempt by any
            individual or entity to solicit login information of any other user
            or Tova Medical Medical Expert, or to access any such account, is an
            express and direct violation of these Terms and of applicable law,
            including relevant privacy and security laws and laws prohibiting
            unfair or unethical business practices.
          </p>
          <p>
            We maintain guidelines and a code of conduct for both Premium
            Services users and Medical Experts who use Tova Medical. By using
            our services, including Tova Medical Premium Services, you agree to
            abide by our{" "}
            <NavLink
              className={classes.navLink}
              to="/terms/medical-experts/#expertGuidelines"
            >
              Guidelines
            </NavLink>
            .
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              We'll send you notices and information.
            </Typography>
          </p>
          <p>
            For more details about when and how we can communicate with you,
            please consult our{" "}
            <NavLink className={classes.navLink} to="/privacy/statement/">
              Privacy Statement
            </NavLink>
            .
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              You agree to arbitration.
            </Typography>
          </p>
          <p>
            Most concerns or disputes can be resolved quickly by visiting the
            customer service help center at{" "}
            <NavLink className={classes.navLink} to="/support">
              https://support.TovaMedical.com
            </NavLink>
            . In the unlikely event that we are unable to resolve a legitimate
            legal complaint, you agree to resolve those disputes through binding
            arbitration or small claims instead of in courts of general
            jurisdiction. By using our Services, you agree to arbitrate any
            disputes with us under the terms of our{" "}
            <NavLink
              className={classes.navLink}
              to="/terms/medical-experts/#arbitrationAgreement"
            >
              Arbitration Agreement
            </NavLink>
            .
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Content and Services
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Tova Medical does not guarantee the accuracy of Third Party
              Content.
            </Typography>
          </p>
          <p>
            Tova Medical has no editorial control over or responsibility for
            Content provided by third parties. Any opinions, statements,
            products, services, or other information expressed or made available
            by third parties (including Medical Experts) or users on Tova
            Medical are those of such third parties or users. Tova Medical does
            not have any obligation to monitor such third party Content. We make
            no representations about the accuracy or reliability of any opinion,
            statement, or other information provided by any third party, and we
            do not represent or warrant that your use of the Content displayed
            or referenced on Tova Medical will not infringe the rights of third
            parties not owned by or affiliated with Tova Medical.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Tova Medical may not be used for illegal purposes.
            </Typography>
          </p>
          <p>
            The Content and Services may not be used for any illegal purpose.
            You may not access our networks, computers, or the Content and
            Services in any manner that could damage, disable, overburden, or
            impair them, or interfere with any other person's use and enjoyment.
            You may not attempt to gain unauthorized access to any Content or
            Services, other accounts, computer systems, or networks connected to
            Tova Medical, the Content, or Services. You may not use any
            automated means (such as a scraper) to access Tova Medical, the
            Content, or Services for any purpose. Such unauthorized access
            includes, but is not limited to, using another person's login
            credentials to access Tova Medical. Any attempt by any individual or
            entity to solicit login information of any other user or Tova
            Medical Medical Expert or to access any such account is an express
            and direct violation of these Terms of Use and of applicable law,
            including relevant privacy and security laws and laws prohibiting
            unfair or unethical business practices.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Tova Medical does not make recommendations or endorsements.
            </Typography>
          </p>
          <p>
            We do not recommend or endorse any specific Content, Services,
            tests, doctors, products, procedures, opinions, or other information
            that may be mentioned on Tova Medical. Reliance on Tova Medical
            Content or Services is solely at your own risk. Some Content and
            Services, including posts by Medical Experts, may be or have been
            provided by third parties directly and are not reviewed or certified
            by Tova Medical. We cannot guarantee that the Content and Services
            will help you achieve any specific goals or results. Content
            regarding dietary supplements or other treatments or regimens has
            not been evaluated by the Food and Drug Administration and is not
            intended to diagnose, treat, cure, or prevent any disease.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              We are not responsible for anything outside of Tova Medical.
            </Typography>
          </p>
          <p>
            The Apps may contain links to other apps, web sites, information,
            software, data, or other content, online or offline ("External
            Content and Services"). Such External Content and Services and
            related information are outside of our control. We do not control,
            endorse, verify the truth or accuracy of, or review content outside
            of Tova Medical, and we are not responsible for such content. We do
            not warrant, nor are we in any way responsible for, information,
            software, data, or privacy policies related or pertaining to
            External Content and Services.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Tova Medical may communicate with you via email, SMS, text and
              mobile push notification.
            </Typography>
          </p>
          <p>
            When you install our app on your mobile device, you may agree to
            receive push notifications, which are messages an app sends you on
            your mobile device when the app is not on. You can turn off
            notifications by visiting your mobile device's "settings" page.
          </p>
          <p>
            We may send you emails concerning our products and services, as well
            as those of third parties. You may opt out of promotional emails by
            following the unsubscribe instructions in the promotional email
            itself.
          </p>
          <p>
            Tova Medical and those acting on our behalf may send you text (SMS)
            messages to the phone number you provide us. These messages may
            include operational messages about your use of the Services, as well
            as marketing messages. If you opt out, you may continue to receive
            text messages for a short period while Tova Medical processes your
            request, and you may also receive text messages confirming the
            receipt of your opt-out request. Opting out of receiving operational
            text messages may impact the functionality that the Service provides
            to you. Text messages may be sent using an automatic telephone
            dialing system. Your agreement to receive text messages is not a
            condition of any purchase or use of the Service. Standard messaging,
            data, and other fees may be charged by your carrier.
          </p>
          <p>
            Your carrier may prohibit or restrict certain mobile features, and
            certain mobile features may be incompatible with your carrier or
            mobile device. As applicable, instructions regarding how to opt-out
            of mobile features will be disclosed in connection with such
            features (instructions typically require you to text a keyword, such
            as "STOP," "CANCEL," "UNSUBSCRIBE," to the applicable shortcode for
            the mobile feature, or to change your profile settings inside the
            Apps).
          </p>
          <p>
            You agree to notify Tova Medical of any changes to your mobile
            number and update your account(s) on the Tova Medical Platforms. You
            also understand and agree that by receiving communications you will
            be charged by your wireless or internet provider and that such
            emails, SMS, or mobile telephone notification may be generated by
            automated systems.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              You agree that we may send you limited personal information by
              email, SMS, text, and mobile push notification.
            </Typography>
          </p>
          <p>
            When you use action-oriented features on Tova Medical and Tova
            Medical Premium Services (such as subscribing to a health checklist
            or participating in a Virtual Consult), you agree that Tova Medical
            and Tova Medical Medical Group, P.C. may send you automated content
            via email, mobile telephone, or other contact information provided
            by you in your account settings. This content may contain protected
            health information under HIPAA, including content related to
            conditions, treatments, and medications. You understand and agree
            that by using these features, you are expressly opting into
            receiving your own protected health information by email, SMS/text,
            or mobile push notifications. These communications from the Apps are
            not encrypted. Although unlikely, it is possible for these
            communications to be intercepted or accessed without your
            authorization. By using the Apps, you release Tova Medical from any
            liability arising from or related to any such interception or
            unauthorized access.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Mandatory Removal of Content and Services.
            </Typography>
          </p>
          <p>
            If you violate any of these Terms of Use, your permission to use the
            Content and Services automatically terminates and you must
            immediately destroy any copies you have made of any portion of the
            Content. (For more information, see{" "}
            <NavLink
              className={classes.navLink}
              to="/terms/medical-experts/#termination"
            >
              Termination
            </NavLink>
            .)
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Use of Your Information
            </Typography>
          </p>
          <p>
            We only use data you share with Tova Medical as set forth in the
            Tova Medical{" "}
            <NavLink className={classes.navLink} to="/privacy">
              Privacy Statement
            </NavLink>
            , your account settings, and in accordance with applicable law.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              We cannot control external communications (including email, SMS,
              and notifications).
            </Typography>
          </p>
          <p>
            Email, short message services (SMS), text message communications,
            and mobile push notifications from the Apps are not encrypted. You
            can opt out of receiving email, SMS/text messages, and mobile push
            notifications. Although unlikely, it is possible for these
            communications to be intercepted or accessed without your
            authorization. By using the Apps, you release Tova Medical from any
            liability arising from or related to any such interception or
            unauthorized access.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Advertising
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              We Do Not Advertise.
            </Typography>
          </p>
          <p>There is no paid advertising on Tova Medical.</p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Property Rights
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Tova Medical owns or has rights to the Content and Services.
            </Typography>
          </p>
          <p>
            When you use the Apps, you do so under a license from us. Subject to
            your complete and ongoing compliance with these Terms, Tova Medical
            grants you, solely for your personal, non-commercial use, a limited,
            non-exclusive, non-transferable, non-sublicensable, revocable
            license to: (a) install and use one object code copy of our mobile
            App obtained from a legitimate marketplace on a mobile device that
            you own or control; and (b) access and use the Content and Services.
            This means that you may not use the Apps for any commercial purpose,
            that we can take away your right to use the Apps, and that you
            cannot give this license away to someone else. All right, title, and
            interest in and to the Apps, Services, and the Content, together
            with all related intellectual property rights are the property of
            Tova Medical or our affiliates, excluding your rights under
            applicable law to any information or Content related to Virtual
            Consults on Tova Medical Premium Services. Rights retained by other
            parties in the Content are their respective rights. Tova Medical
            reserves all rights to the Content not granted expressly in these
            Terms.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              You agree not to infringe our Copyrights, Trademarks, Trade
              Secrets, Patents, or other intellectual property rights.
            </Typography>
          </p>
          <p>
            Tova Medical and other related marks are registered trademarks of
            Tova Medical, Inc. Any other trademark, brand, or content on Tova
            Medical that is not the property of Tova Medical is the property of
            its respective owner. You agree not to violate, or encourage others
            to violate, any right of a third party, including by infringing or
            misappropriating any third party intellectual property rights. You
            may not reproduce, create derivative works of, distribute, publicly
            display the Content (or any portion of it) without our prior written
            consent. However, you may use App features to repost Content or
            portions of Content, including through other third party
            applications and mediums (such as Facebook or Twitter), so long as
            you do not modify that Content or the functionality of those
            features. This re-posting right does not create any additional
            rights in such Content. Additionally, you may not use any metatags
            or any other "hidden text" utilizing the name "Tova Medical" without
            our prior written permission.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              You agree not to access, attempt to access, or use our data
              without our permission.
            </Typography>
          </p>
          <p>
            Except and solely to the extent such a restriction is impermissible
            under applicable law, you may not: (a) reproduce, distribute,
            publicly display, or publicly perform the Apps, Services, or
            Content; (b) make modifications to the Apps, Services, or Content;
            or (c) interfere with or circumvent any feature of the Apps,
            including any security or access control mechanism. If you are
            prohibited under applicable law from using the Service, you may not
            use it. You agree not to access, attempt to access, request access
            not authorized by the Apps or use any App Content or data without
            our permission. This means that you agree not to transmit, download,
            upload, post, sell, rent, license, transfer, disclose, mirror,
            frame, reverse engineer, decompile, disassemble, or use any aspect
            of the Apps or any Content, in whole or in part, in any form or by
            any means.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Contact us if you believe materials on our Apps infringe your
              copyright.
            </Typography>
          </p>
          <p>
            If you believe any materials accessible on or from Tova Medical
            infringe your valid and enforceable copyright, you may request
            removal of (or access to) those materials (or access thereto) from
            us by contacting us at Support@TovaMedical.com.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Submissions
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              You agree to abide by our Submission Guidelines.
            </Typography>
          </p>
          <p>
            You agree that you will not upload or transmit any communications or
            content of any type that infringes or violates any rights of any
            party. The personal information you submit to Tova Medical is
            governed by the{" "}
            <NavLink className={classes.navLink} to="/privacy">
              Privacy Statement
            </NavLink>{" "}
            (the terms of which govern in the event of any inconsistency with
            this TOU). You agree that submissions will comply with Tova
            Medical's Guidelines, and if you make any posting to Tova Medical,
            you agree to{" "}
            <NavLink
              className={classes.navLink}
              to="/terms/medical-experts/#expertGuidelines"
            >
              Medical Expert Guidelines
            </NavLink>
            .
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              You give us rights in what you submit.
            </Typography>
          </p>
          <p>
            Don't submit anything to us if you don't want to give us rights to
            it. If you wish to keep any content, business information, ideas,
            concepts or inventions private or proprietary, do not submit them
            on, through, or to Tova Medical, by email or otherwise. With the
            exception of any personal data or information you submit (which
            shall be maintained in accordance with our Privacy Statement) and
            other information governed by the Health Insurance Portability and
            Accountability Act of 1996, Public Law 104-191 and the rules and
            regulations promulgated thereunder (as amended to date, "HIPAA"), or
            other applicable laws, if you make any submissions (by email or
            otherwise) on, to or through Tova Medical, including but not limited
            to media (including photographs), data, questions, comments,
            suggestions, business information, ideas, concepts or inventions
            (collectively "Submissions"), you make such submission without any
            restrictions or expectation of compensation, privacy, or
            confidentiality. You agree that your Submissions may be used by us
            without restriction for any purpose whatsoever. By making any a
            Submission, you grant Tova Medical a worldwide, non-exclusive,
            irrevocable, royalty-free, fully paid right and license (with the
            right to sublicense) to host, store, transfer, display, perform,
            reproduce, modify for the purpose of formatting for display, and
            distribute your Submissions, in whole or in part, in any media
            formats and through any media channels now known or hereafter
            developed. This means Tova Medical has the complete right to freely
            use, create derivative works from and modify, such Submissions in
            any way, commercial or otherwise (including developing and marketing
            products or features using such information), and for any purpose
            whatsoever and without limitation. Tova Medical may sublicense its
            rights.
          </p>
          <p>
            By making any a Submission, you further agree to indemnify Tova
            Medical and its affiliates, directors, officers, Medical Experts,
            and employees, and to hold them harmless from any and all claims and
            expenses, including attorneys' fees, arising from your Submissions,
            or your failure to comply with these Terms.
          </p>
          <p>
            Tova Medical does not accept unsolicited recruiter or similar
            submissions. Any candidate submissions by a recruiter or other third
            party without a valid and signed recruiting agreement in place with
            Tova Medical prior to such submission will not be subject to any
            recruiter or similar fees.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Tova Medical Medical Experts
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              About Tova Medical Medical Experts.
            </Typography>
          </p>
          <p>
            Medical Experts on the Apps include respected doctors throughout the
            United States, Mexico, and Canada. Only U.S., Canada, and
            Mexico-licensed doctors may participate as Medical Experts on Tova
            Medical (including Tova Medical Premium Services). Doctors whose
            licenses are or become suspended or revoked, for any reason, are not
            permitted and agree not to participate in any way, including but not
            limited to submitting Content to Tova Medical as a Medical Expert on
            Tova Medical or participating on Tova Medical Premium Services.
            Supplemental Terms of Use apply to Medical Experts who participate
            on Tova Medical (including those who provide Premium Services).
          </p>
          <p>
            In the case of Mexico and/or Canada, physicians may also participate
            in the programs mentioned above that meet the applicable conditions
            for practicing medicine, as well as Tova Medical's policies and
            Terms of Use.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Termination
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              All of our members are required to honor this Agreement.
            </Typography>
          </p>
          <p>
            Your permission to use the Apps, Content, and Services ends
            immediately if you violate any of the terms of this Agreement. We
            may place limits on, modify, or terminate your right to access and
            use Apps and the Services and/or Content at any time for any reason
            or no reason, with or without notice. This suspension or termination
            may delete information, files, and other previously available
            Content. We also reserve the right to modify or discontinue the
            Services at any time (including by limiting or discontinuing certain
            features of the Apps), temporarily or permanently, without notice to
            you. We will have no liability whatsoever on account of any change
            to the Services or any suspension or termination of your access to
            or use of the Services.
          </p>
          <p>
            You may terminate your account at any time by visiting the customer
            service help center at{" "}
            <NavLink className={classes.navLink} to="/support">
              https://support.TovaMedical.com
            </NavLink>
            . If you terminate your account, you remain obligated to pay all
            outstanding fees, if any, incurred prior to termination relating to
            your use of the Services.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Effect of Termination.
            </Typography>
          </p>
          <p>
            Upon termination of these Terms: (a) your license rights will
            terminate and you must immediately cease all use of the Service; (b)
            you will no longer be authorized to access your account or the
            Service; (c) you must pay Tova Medical any unpaid amount that was
            due prior to termination; and (d) all payment obligations accrued
            prior to termination, this section, and sections regarding
            Submissions, ownership, indemnities, disclaimer of warranties,
            limitations on liability, and the Arbitration Agreement will
            survive.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Indemnification &amp; Exclusions and Limitations
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Exclusion of Warranties.
            </Typography>
          </p>
          <p>
            THE APPS AND THE CONTENT AND SERVICES ARE PROVIDED "AS IS" AND ON AN
            "AS AVAILABLE" BASIS WITHOUT WARRANTY OR CONDITION OF ANY KIND,
            EITHER EXPRESS OR IMPLIED. NEITHER WE, TOVA MEDICAL MEDICAL EXPERTS,
            NOR ANY OF OUR LICENSORS MAKE ANY EXPRESS WARRANTIES, AND WE AND
            EACH OF THEM DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING THE IMPLIED
            WARRANTIES OF ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, AND NON-INFRINGEMENT. NEITHER WE, TOVA MEDICAL MEDICAL
            EXPERTS, NOR ANY OF OUR LICENSORS MAKE ANY WARRANTY THAT CONTENT OR
            SERVICES SATISFY GOVERNMENT REGULATIONS, INCLUDING THOSE REQUIRING
            DISCLOSURE OF INFORMATION ON PRESCRIPTION DRUG PRODUCTS. TOVA
            MEDICAL AND THE CONTENT AND SERVICES WERE DEVELOPED FOR USE IN THE
            UNITED STATES, AND NEITHER WE NOR ANY OF OUR LICENSORS MAKE ANY
            REPRESENTATION CONCERNING TOVA MEDICAL AND THE CONTENT OR SERVICES
            WHEN USED IN ANY OTHER COUNTRY.
          </p>
          <p>
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
            FROM THE SERVICE OR TOVA MEDICAL, OR ANY MATERIALS OR CONTENT
            AVAILABLE THROUGH THE APPS, WILL CREATE ANY WARRANTY THAT IS NOT
            EXPRESSLY STATED IN THESE TERMS.
          </p>
          <p>
            Specifically, and without limiting the foregoing, we, our licensors,
            and our suppliers, make no representations or warranties about: (i)
            the accuracy, reliability, completeness, currentness, or timeliness
            of the Content provided on or through the use of the App; or (ii)
            regulations requiring disclosure of information on prescription drug
            products or the approval or compliance of any software tools with
            Tova Medical. Any location data accessed via the Apps may be
            inaccurate or incomplete and any use of such data is at your own
            risk.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Limitation of Liability of Tova Medical and Third Party
              Beneficiaries.
            </Typography>
          </p>
          <p>
            WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE
            SERVICE AND YOUR DEALING WITH ANY OTHER USER, INCLUDING MEDICAL
            EXPERTS. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE
            SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT
            RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY OR ANY LOSS OF DATA,
            INCLUDING SUBMISSIONS.
          </p>
          <p>
            To the fullest extent permitted by law, in no event will Tova
            Medical, its licensors, suppliers, or any third parties mentioned on
            Tova Medical be liable for any personal injury, including death,
            attributable to or caused by your use or misuse of Tova Medical or
            Content (including Medical Expert Content). Any claims arising in
            connection with your use of the Apps, Services, or any Content must
            be brought within one (1) year of the first date of the event giving
            rise to such action. Remedies under these Terms are exclusive and
            are limited to those expressly provided for in these Terms. You
            expressly agree that Medical Experts are third party beneficiaries
            under these Terms and may enforce the rights hereunder, including
            Limitation of Liability rights.
          </p>
          <p>
            NEITHER WE, TOVA MEDICAL MEDICAL EXPERTS, NOR ANY OF OUR LICENSORS
            MAY BE HELD LIABLE UNDER THIS AGREEMENT FOR ANY DAMAGES OTHER THAN
            DIRECT DAMAGES, EVEN IF THE PARTY KNOWS OR SHOULD KNOW THAT OTHER
            DAMAGES ARE POSSIBLE, OR THAT DIRECT DAMAGES ARE NOT A SATISFACTORY
            REMEDY. THESE LIMITATIONS APPLY TO YOU ONLY TO THE EXTENT THEY ARE
            LAWFUL IN YOUR JURISDICTION. EXCEPT AS PROVIDED IN THE{" "}
            <NavLink
              className={classes.navLink}
              to="/terms/medical-experts/#arbitrationAgreement"
            >
              ARBITRATION AGREEMENT
            </NavLink>
            , NEITHER US, THE MEDICAL EXPERTS ON TOVA MEDICAL, NOR ANY OF OUR
            LICENSORS MAY BE HELD LIABLE UNDER THIS AGREEMENT TO ANY USER FOR
            ANY CLAIMS (IN AGGREGATE OVER ALL TIME) FOR MORE THAN THE GREATER
            OF: (I) FEES PAID BY THE USER TO TOVA MEDICAL OVER THE COURSE OF THE
            SIX (6) MONTHS IMMEDIATELY PRIOR TO THE INCIDENT GIVING TO THE
            ALLEGED DAMAGES; OR (II) FOR USERS WHO HAVE NOT SO USED PAID PREMIUM
            SERVICES, TO ONE HUNDRED DOLLARS ($100).
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Limitations of Liability and Indemnity for Expert Content.
            </Typography>
          </p>
          <p>
            Informational content on the Apps that is created, modified,
            submitted, or validated by Medical Experts or other healthcare
            experts (collectively, "Expert Content") is subject to the following
            additional terms and conditions and agreements by You.
          </p>
          <p>
            Expert Content is for informational purposes only. For medical
            advice, treatment, or diagnosis, see your personal doctor or
            healthcare provider. Tova Medical is not responsible for Expert
            Content. The authors or posters of Expert Content ("Posters") are
            solely responsible for such content. No representations, warranties,
            or guarantees of any kind are made regarding the Expert Content.
            Under no circumstances shall any party be liable (to you or to any
            other person) for any damages or harm (of any type or under any
            legal theory) resulting from or related to the Expert Content. No
            party shall have any liability for: (a) any loss or injury caused,
            in whole or in part, by a Poster's actions, omissions, or
            negligence, in procuring, compiling, or delivering information
            within or through Expert Content; (b) any errors, omissions, or
            inaccuracies in Expert Content (regardless of cause), or delays or
            interruptions in delivery of such information; or (c) any decision
            made or action taken or not taken in reliance upon such information.
            You agree to indemnify and hold the Poster (and the Poster's
            employer and/or sponsor) harmless from any claim or demand,
            including attorneys' fees, made by any third party as a result of
            any violation of law that occurs by you through your use of Expert
            Content or this Site and/or anything you do using Expert Content,
            our Apps and/or the information contained therein.
          </p>
          <p>
            IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT OR MATERIALS ON OUR
            SITE, OR ANY SERVICES OR INFORMATION AVAILABLE THROUGH THE SITE,
            YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING
            OUR SITE. THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS
            AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Licensed Content
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Certain Content may be licensed from third-parties.
            </Typography>
          </p>
          <p>
            The licenses for some of this Content may contain additional terms.
            When such Content licenses contain additional terms, we will make
            these terms available to you on those pages, in the Terms of Use, or
            in the Additional Information section of our Apps.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Foreign Languages
            </Typography>
          </p>
          <p>
            Where Tova Medical Content or Services (including these Terms) are
            translated into languages other than English, all such translations
            are for the convenience of our users only, and Tova Medical is not
            responsible or liable in the event of any translation inaccuracy.
            The English-language version of these Terms shall control and apply
            In the event of any conflict with content or translation. You
            understand that Content, including but not limited to questions and
            answers, may not have the same meaning in translation, and that
            treatments (including but not limited to medication names) and other
            information may differ from country to country and in different
            languages and may not be available in all places. Additionally, you
            understand and agree that Virtual Consults with Medical Experts may
            not be available in languages other than English, and you agree not
            to conduct a consultation in a language in which you are not
            proficient without the use of a professional translator. If you
            choose to use a translator in connection with a Virtual Consult, you
            understand that Tova Medical is not liable for any errors or
            omissions in translation.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              General Legal Terms
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              General Terms.
            </Typography>
          </p>
          <p>
            These Terms, and the other agreements referenced in it (like our{" "}
            <NavLink className={classes.navLink} to="/privacy">
              Privacy Statement
            </NavLink>{" "}
            and{" "}
            <NavLink className={classes.navLink} to="/cookies">
              Cookie Policy
            </NavLink>
            ), are the entire agreement between you and us relating to the Tova
            Medical Apps. Your use of the Services is subject to all additional
            terms, policies, rules, or guidelines applicable to the Service or
            certain features of the Service that we may post on or link to from
            the Service (the "Additional Terms"). All Additional Terms are
            incorporated by this reference into, and made a part of these Terms.
            These Terms replace any prior agreements unless such prior or
            subsequent agreement explicitly provides otherwise and specifically
            references these Terms. If there is any conflict between these Terms
            and a mutually signed written agreement between you and us related
            to Tova Medical, the signed written agreement will control. You may
            not assign or transfer these Terms or your rights under these Terms,
            in whole or in part, by operation of law or otherwise, without our
            prior written consent. We may assign these Terms at any time without
            notice or consent.
          </p>
          <p>
            If we choose not to enforce any provision of these Terms, we retain
            the right to enforce it in the future. This means that the failure
            to enforce any provision of these Terms does not constitute a waiver
            of that provision. If any provision in these Terms is found to be
            unenforceable, that provision and any related provisions will be
            interpreted to best accomplish the unenforceable provision's
            essential purpose.
          </p>
          <p>
            This agreement is governed by California law, excluding California's
            choice-of-law rules. THE EXCLUSIVE VENUE FOR ANY DISPUTE RELATING TO
            THIS AGREEMENT IS SANTA CLARA COUNTY, CALIFORNIA. YOU AND US CONSENT
            TO THE PERSONAL JURISDICTION OF THESE COURTS. Nothing in this
            agreement limits either party's ability to seek equitable relief.
          </p>
          <p>
            IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT ON OUR APPS, OR ANY
            SERVICES OR INFORMATION AVAILABLE ON OR THROUGH THE APPS, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING OUR APPS.
            THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT
            FAIL THEIR ESSENTIAL PURPOSE.
          </p>
          <p>
            The Apps and Services are offered by Tova Medical, Inc. located 954
            PR-25, Suite 205, #10254, Puerto Rico, US. You may contact us by
            sending correspondence to that address or by visiting the customer
            service help center at{" "}
            <NavLink className={classes.navLink} to="/support">
              https://support.TovaMedical.com
            </NavLink>
            .
          </p>
          <p>
            If you are a California resident, under California Civil Code
            Section 1789.3, you may contact the Complaint Assistance Unit of the
            Division of Consumer Services of the California Department of
            Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202,
            Sacramento, California 95834, or by telephone at (800) 952-5210 in
            order to resolve a complaint regarding the Service or to receive
            further information regarding the use of the Service.
          </p>
          <p>
            We are under no obligation to provide support for the Service. In
            instances where we may offer support, the support will be subject to
            published policies.
          </p>
          <p>
            Notice Regarding Apple. This section only applies to the extent you
            are using our mobile application on an iOS device. You acknowledge
            that these Terms are between you and Tova Medical only, not with
            Apple Inc. ("Apple"), and Apple is not responsible for the App or
            the content thereof. Apple has no obligation to furnish any
            maintenance and support services with respect to the App. If the App
            fails to conform to any applicable warranty, you may notify Apple
            and Apple will refund any applicable purchase price for the mobile
            application to you; and, to the maximum extent permitted by
            applicable law, Apple has no other warranty obligation with respect
            to the App. Apple is not responsible for addressing any claims by
            you or any third party relating to the App or your possession and/or
            use of the App, including: (a) product liability claims; (b) any
            claim that the App fails to conform to any applicable legal or
            regulatory requirement; or (c) claims arising under consumer
            protection or similar legislation. Apple is not responsible for the
            investigation, defense, settlement and discharge of any third party
            claim that the App and/or your possession and use of the App
            infringe a third party's intellectual property rights. You agree to
            comply with any applicable third party terms when using the App.
            Apple and Apple's subsidiaries are third party beneficiaries of
            these Terms, and upon your acceptance of these Terms, Apple will
            have the right (and will be deemed to have accepted the right) to
            enforce these Terms against you as a third party beneficiary of
            these Terms. You hereby represent and warrant that: (i) you are not
            located in a country that is subject to a U.S. Government embargo,
            or that has been designated by the U.S. Government as a "terrorist
            supporting" country; and (ii) you are not listed on any U.S.
            Government list of prohibited or restricted parties.
          </p>
          <p>
            CVS and MinuteClinic, LLC and its subsidiaries and managed entities
            are not affiliated with Tova Medical. The names and symbols of CVS
            and its affiliates and subsidiaries, including MinuteClinic, LLC are
            owned by and proprietary to CVS, and have been licensed for use by
            Tova Medical.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Medical Expert Guidelines
            </Typography>
          </p>
          <p>
            By participating in Tova Medical or Tova Medical Premium Services
            you agree to abide by the following guidelines.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              General Guidelines.
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Respect.
                </Typography>{" "}
                You agree to engage with Medical Experts in a respectful manner
                and to refrain from inappropriate language and behavior.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Appropriate Use.
                </Typography>{" "}
                You agree to only use Tova Medical and Tova Medical Premium
                services in a manner that is not: unlawful, threatening,
                harassing, abusive, defamatory, slanderous, libelous, harmful to
                minors, vulgar, gratuitously violent, obscene, pornographic,
                indecent, lewd, invasive of another's privacy, or racially,
                ethnically or otherwise offensive, hateful, or abusive.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Guidelines for Posting Content (Including Answers) (Non-Premium
              Services).
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Informational Answers Only.{" "}
                </Typography>
                Only informational answers for educational purposes are allowed.
                No personal, individualized health answers are permitted.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  No Obligation to Answer Questions.{" "}
                </Typography>
                You have no obligation to answer any submitted question.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Only Make Useful Submissions.
                </Typography>{" "}
                Make Expert Submissions only when you reasonably believe that
                they are likely to be useful and helpful to Tova Medical
                members.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Only Agree when you Agree.{" "}
                </Typography>
                Agree with Expert Submissions only when you have fully reviewed
                and actually agree with the content, and do not agree with your
                own Submissions.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Avoid Practicing Medicine.{" "}
                </Typography>
                Answers can provide general guidance, context, and illustration
                and you may refer to general medical conditions, symptoms and
                treatments, but please do not provide a formal diagnosis or
                prescribe any specific treatment in your answers. Answers should
                not provide specific treatment recommendations or give the
                impression that they are a substitute for a real-life
                consultation with a doctor. Tova Medical Premium Services may be
                used for individualized or diagnostic answers and prescribing
                medications (where appropriate). YOU ARE NOT PERMITTED TO
                PRACTICE MEDICINE ON OR THROUGH TOVA MEDICAL, EVEN IF YOU ARE
                INTERACTING WITH AN ACTUAL PATIENT OF YOURS. ALL MEDICAL
                DIAGNOSIS, TREATMENT, PRESCRIPTION, AND ACTUAL CARE MUST OCCUR
                ON TOVA MEDICAL PREMIUM SERVICES OR OUTSIDE OF TOVA MEDICAL,
                REGARDLESS OF ANY EXISTING RELATIONSHIP BETWEEN THE PARTIES.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Avoid Revealing Private Information.{" "}
                </Typography>
                Help us keep the personal information of our members
                confidential. Please don't refer to members in or on Content in
                public areas by their real names, even if this information has
                been disclosed to you.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Avoid Offending Others.{" "}
                </Typography>
                Responses should always be appropriate. Brief, casual, and
                friendly responses are the most useful. Help Tova Medical remain
                a trusted resource, by avoiding potentially offensive content
                whenever possible.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Avoid Suggesting a Physician-Patient Relationship.{" "}
                </Typography>
                Share your professional, clinical expertise though answers that
                are evidence-based and consistent with accepted standards in the
                medical community, but refrain from using language that suggests
                an answer is provided for a single member or a duty of care and
                a physician-patient relationship.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Avoid Commercial Responses.{" "}
                </Typography>
                Using answers as a vehicle to promote a product or service is
                prohibited. You are allowed to link to external websites that
                are both appropriate and related to your responses, but answers
                on Tova Medical should be complete and should not be used for
                primarily promotional purposes. Please do not plagiarize, and
                cite sources where applicable.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Avoid Advertising or Specifically Soliciting Patients.{" "}
                </Typography>
                Users on Tova Medical can find your professional contact
                information through your virtual practice, and can contact you
                for an appointment. However, please avoid violating medical
                board rules regarding advertising and patient solicitation.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Avoid Disclosing Confidential Material.{" "}
                </Typography>
                Be careful not to discuss privileged or confidential details of
                past or current patients, or accidentally revealing confidential
                information in an indirect way that could be traced back to
                specific patients.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Avoid Violating Board Rules.{" "}
                </Typography>
                Avoid other actions that violate board or other rules that apply
                to you.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Rules Governing Other Submissions.{" "}
                </Typography>
                All other information, including suggestions, feedback, ideas,
                concepts and any other materials you disclose or offer to us on
                or in connection with Tova Medical or any Content, are governed
                by the Terms of Use. This means that these submissions are made
                without any restrictions and without any expectation of
                confidentiality or compensation. You agree not to assert any
                property right or moral right of any kind with respect to any
                such submissions. You agree to our standard terms governing
                media submissions, except for the prohibition against uploading
                a facial portrait image of yourself for your profile picture.
                You agree to your likeness, photograph, name, biographical
                information and any and all other information appearing on the
                Apps.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Guidelines Specific to Premium Services and Virtual Consults.
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Application Only.{" "}
                </Typography>
                Participation as a Medical Expert in Tova Medical Premium
                Services is subject to a separate application.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Additional Contractual Terms.{" "}
                </Typography>
                The terms of any related contracts or agreements entered into in
                connection with providing Tova Medical Premium Services apply to
                the provision of such services.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Care Limitations.{" "}
                </Typography>
                You agree to notify a patient whenever in-person care is
                recommended, including when a user appears to be repeatedly
                using or overusing Virtual Consults.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Prescriptions and Lab Tests.{" "}
                </Typography>
                Prescriptions and lab tests, where available, are provided at
                the sole discretion of the consulting Medical Expert. Patients
                who are located outside the U.S. may not receive lab orders and
                may receive prescription recommendations only.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Prescriptions and Lab Tests Limitations.{" "}
                </Typography>
                The following medication categories cannot be prescribed or
                recommended in Virtual Consults on Tova Medical: DEA category
                I-IV medications, lifestyle medications (such as weight loss or
                hair loss medication), and U.S.-state regulated medications. Lab
                tests are currently ordered using Quest Diagnostics, and it may
                not be possible to order all of Quest Diagnostics lab tests (if
                any) in all U.S. states.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Duration of Treatment.{" "}
                </Typography>
                It is up to you to determine the appropriate duration of any
                treatment, if any, made available through a Virtual Consult.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="h3">
                  Appearance.{" "}
                </Typography>
                You agree to be appropriately and professionally dressed and to
                be in an environment that is appropriate for the delivery of
                virtual care.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Tova Medical Arbitration Agreement
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Summary.
            </Typography>
          </p>
          <p>
            Tova Medical's Terms require the use of arbitration to resolve
            disputes and also limits the remedies available to you in the event
            of a dispute. Most concerns can be resolved quickly by visiting the
            customer service help center at{" "}
            <NavLink className={classes.navLink} to="/support">
              https://support.TovaMedical.com
            </NavLink>
            . In the unlikely event that we are unable to resolve a legitimate
            legal complaint, we each agree to resolve those disputes through
            binding arbitration or small claims instead of in courts of general
            jurisdiction. Arbitration is more informal than a lawsuit in court.
            Arbitration uses a neutral arbitrator instead of a judge or jury,
            allows for more limited discovery than in court, and is subject to
            very limited review by courts. Arbitrators can award the same
            damages and relief that a court can award. Any arbitration under
            this Agreement will take place on an individual basis; class
            arbitrations and class actions are not permitted. We request that
            you work with us in good faith to resolve any disputes for 30 days
            after notifying us of such issues before filing arbitration. You
            understand that any award available under arbitration is subject to
            the limitations in the Tova Medical TOUs. YOU UNDERSTAND AND AGREE
            THAT, BY ENTERING INTO THESE TERMS, YOU AND TOVA MEDICAL ARE EACH
            WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
            ACTION.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Agreement.
            </Typography>
          </p>
          <p>
            First things first: let's try to sort it out. We want to address
            your concerns without a formal arbitration or case. Before filing a
            claim against Tova Medical, you agree to make a good faith effort to
            try to resolve the dispute informally by contacting{" "}
            <NavLink
              className={classes.navLink}
              to="mailto:Support@TovaMedical.com"
            >
              Support@TovaMedical.com
            </NavLink>{" "}
            and responding promptly to any related communications. We'll try to
            resolve the dispute by contacting you via email. If a dispute is not
            resolved within 30 days of submission, you or Tova Medical may bring
            a formal proceeding.
          </p>
          <p>
            This Arbitration Agreement ("Arbitration Agreement") is a condition
            of the Terms of Use of Tova Medical and the Apps. The effective date
            of this agreement is January 3, 2021 (th e "Effective Date").
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              General.
            </Typography>
          </p>
          <p>
            If you reside in the United States or are otherwise subject to the
            US Federal Arbitration Act, you agree that any and all disputes or
            claims that have arisen or may arise between us - except as set
            forth below ("Exceptions") - shall be resolved exclusively through
            final and binding arbitration, rather than in court. The Federal
            Arbitration Act governs the interpretation and enforcement of this
            Arbitration Agreement.
          </p>
          <p>
            Any arbitration proceedings shall be conducted by the American
            Arbitration Association ("AAA") under its rules and procedures
            applicable at that time, including the AAA's Supplementary
            Procedures for Consumer-Related Disputes (to the extent applicable),
            as modified by our Arbitration Agreement. You may review those rules
            and procedures, and obtain a form for initiating arbitration
            proceedings at the AAA's website. The AAA Rules and filing forms are
            available online at{" "}
            <a target="_blank" href="www.adr.org">
              www.adr.org
            </a>
            , by calling the AAA at 1-800-778-7879, or by contacting Tova
            Medical. The arbitration shall be held in Santa Clara County,
            California or at another mutually agreed location. If the reasonable
            value of the relief sought is US$10,000 or less, either of us may
            elect to have the arbitration conducted by telephone or based solely
            on written submissions, which election shall be binding on us
            subject to the arbitrator's discretion to require an in-person
            hearing. Attendance at an in-person hearing may be made by telephone
            by you and/or us, unless the arbitrator requires otherwise.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Exceptions.
            </Typography>
          </p>
          <p>
            Notwithstanding the foregoing, nothing in this Arbitration Agreement
            will be deemed to waive, preclude, or otherwise limit the right of
            either party to: (a) bring an individual action in small claims
            court; (b) pursue an enforcement action through the applicable
            federal, state, or local agency if that action is available; (c)
            seek injunctive relief in a court of law in aid of arbitration; or
            (d) to file suit in a court of law to address an intellectual
            property infringement claim.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Notice.
            </Typography>
          </p>
          <p>
            A party who intends to seek arbitration must first send to the
            other, by certified mail, a written Notice of Dispute ("Notice").
            The Notice to Tova Medical should be addressed to: General Counsel,
            Tova Medical, 954 PR-25, Suite 205, #10254, Puerto Rico, US ("Notice
            Address"). The Notice must (a) describe the nature and basis of the
            claim or dispute; and (b) set forth the specific relief sought and
            the specific applicable legal basis for the requested relief
            ("Demand"). The parties will make good faith efforts to resolve the
            claim directly, but if the parties do not reach an agreement to do
            so within 30 days after the Notice of Arbitration is received, you
            or Tova Medical may commence an arbitration proceeding. All
            arbitration proceedings between the parties will be confidential
            unless otherwise agreed by the parties in writing. During the
            arbitration, the amount of any settlement offer made by you or Tova
            Medical must not be disclosed to the arbitrator until after the
            arbitrator makes a final decision and award, if any.
          </p>
          <p>
            The arbitrator will decide the substance of all claims in accordance
            with the laws of the State of California, including recognized
            principles of equity, and will honor all claims of privilege
            recognized by law. The arbitrator shall not be bound by rulings in
            prior arbitrations involving different Tova Medical users, but is
            bound by rulings in prior arbitrations involving the same user to
            the extent required by applicable law. The arbitrator's award shall
            be final and binding and judgment on the award rendered by the
            arbitrator may be entered in any court having jurisdiction over the
            parties, except for a limited right of appeal under the Federal
            Arbitration Act.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Opt-Out.
            </Typography>
          </p>
          <p>
            If you do not wish to resolve disputes by binding arbitration, you
            may opt out of this Arbitration Agreement within 30 days after the
            date that you agree to the Terms by sending a letter to General
            Counsel, Tova Medical, Inc., 954 PR-25, Suite 205, #10254, Puerto
            Rico, US that specifies: your full legal name, the email address
            associated with your account on the Service, and a statement that
            you wish to opt out of arbitration ("Opt-Out Notice"). Once Tova
            Medical receives your Opt-Out Notice, this Arbitration Agreement
            will be void and any action arising out of the Terms will be
            resolved under the governing law and jurisdiction set forth in the
            Terms. The remaining provisions of the Terms will not be affected by
            your Opt-Out Notice.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Fees.
            </Typography>
          </p>
          <p>
            The AAA rules will govern the payment of all filing, administration
            and arbitrator fees, unless our Arbitration Agreement expressly
            provides otherwise. If the amount of any claim in an arbitration is
            US$5,000 or less, Tova Medical will pay all arbitrator fees
            associated with the arbitration, so long as: (a) you make a written
            request for such payment of fees and submit it to the AAA with your
            Demand for Arbitration; and (b) your claim is not determined by the
            arbitrator to be frivolous or without merit under Tova Medical's
            Terms of Use or otherwise ("Frivolous Claims"). In such case, we
            will make arrangements to pay all necessary fees directly to the
            AAA. If the amount of the claim exceeds US$10,000 and you are able
            to demonstrate that the costs of arbitration will be prohibitive as
            compared to the costs of litigation, Tova Medical will pay as much
            of the filing, administration, and arbitrator fees as the arbitrator
            deems necessary to prevent the arbitration from being
            cost-prohibitive. If the arbitrator determines the claim(s) you
            assert in the arbitration are Frivolous Claims, you agree to
            reimburse Tova Medical for all fees associated with the arbitration
            paid by Tova Medical. Tova Medical's obligations to pay these fees
            shall be made after a preliminary determination that the claims are
            not barred or limited by the Tova Medical Terms of Use. Bared or
            limited claims constitute Frivolous Claims.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS
            </Typography>
          </p>
          <p>
            YOU AND TOVA MEDICAL AGREE, AS PART OF THE ARBITRATION AGREEMENT,
            THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
            INDIVIDUAL BASIS AND NOT AS PART OF ANY PURPORTED CLASS OR
            REPRESENTATIVE ACTION OR PROCEEDING. WE REFER TO THIS AS THE
            "PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS". UNLESS BOTH YOU
            AND WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN
            YOUR OR OUR CLAIM WITH ANOTHER PERSON'S OR PARTY'S CLAIMS, AND MAY
            NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
            REPRESENTATIVE OR CLASS PROCEEDING. THE ARBITRATOR MAY ONLY AWARD
            RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) IN
            FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
            NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL
            CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER TOVA MEDICAL USERS.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Enforceability.
            </Typography>
          </p>
          <p>
            Except with respect to the Prohibition of Class and Representative
            Actions, if a court decides that any part of this Arbitration
            Agreement is invalid or unenforceable, the other parts of this
            Arbitration Agreement shall continue to apply. If a court decides
            that the Prohibition of Class and Representative Actions is invalid
            or unenforceable, then the entire Arbitration Agreement shall be
            null and void.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Modifications.
            </Typography>
          </p>
          <p>
            If Tova Medical makes any future change to this Arbitration
            Agreement, other than a change to Tova Medical's Notice Address, you
            may reject the change by sending us written notice within 30 days of
            the change to the Notice Address, in which case your account with
            Tova Medical will be immediately terminated and this arbitration
            provision, as in effect immediately prior to the changes you
            rejected will survive.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              CLAIMS ARE TIME-BARRED
            </Typography>
          </p>
          <p>
            You agree that regardless of any statute or law to the contrary or
            the applicable dispute resolution process, any claim or cause of
            action you may have arising out of or related to use of the Service
            or otherwise under these must be filed within one (1) year after
            such claim or cause of action arose or you hereby agree to be
            forever barred from bringing such claims.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Additional Terms: Medical Experts
            </Typography>
          </p>
          <p>
            THESE ADDITIONAL TERMS APPLY TO TOVA MEDICAL MEDICAL EXPERTS
            ("ADDITIONAL EXPERT TERMS") WHEN USING TOVA MEDICAL AS A TOVA
            MEDICAL MEDICAL EXPERT. IF YOU ARE A TOVA MEDICAL MEDICAL EXPERT YOU
            AGREE TO ABIDE BY ALL TOVA MEDICAL TERMS OF USE AND THAT WHERE AN
            ADDITIONAL EXPERT TERM CONTRADICTS A MEMBER TERM OF USE, THE
            ADDITIONAL EXPERT TERM SHALL PREVAIL. THESE ADDITIONAL EXPERT TERMS
            APPLY TO YOUR USE OF TOVA MEDICAL AS A TOVA MEDICAL MEDICAL EXPERT;
            IF YOU USE THE APPS AS A MEMBER, YOU AGREE TO ALL MEMBER TERMS OF
            USE.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Medical Experts: Information.
            </Typography>
          </p>
          <p>
            As a Tova Medical Medical Expert, you are required to register with
            and use your real name, professional contact information, and (if
            you choose to upload a picture) a real image of you in your public
            profile on Tova Medical ("Public Profile"). You consent to Tova
            Medical's use on or in connection with the Apps of your name,
            likeness, photograph, biographical information and other personal
            information provided by you to Tova Medical or that is publicly
            available about you.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Medical Experts: Doctors in Good Standing.
            </Typography>
          </p>
          <p>
            Licensed doctors may apply to and participate as Medical Experts on
            Tova Medical. Doctors whose licenses are or become suspended or
            revoked, for any reason, are not permitted and agree not to
            participate in any way, including but not limited to submitting
            Content to Tova Medical as a Medical Expert on Tova Medical. Tova
            Medical may expand the network to include other licensed medical
            experts.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Medical Experts: You Can Control Your Expert Submissions.
            </Typography>
          </p>
          <p>
            You retain the right to edit and delete your own Expert Submissions
            that appear in your Public Profile at any time. You also retain the
            right to use and create derivative works from your own Expert
            Submissions elsewhere (such as on your own practice website, blog,
            or in your own social media posts).
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Medical Experts: Permission to Post and Use Expert Submissions.
            </Typography>
          </p>
          <p>
            When a Tova Medical Medical Expert submission relates to any Tova
            Medical feature or function and is made for publication on Tova
            Medical (such as an answer to a question) (an "Expert Submission"),
            you grant Tova Medical an unrestricted right without limitation to
            use, reproduce, extract data from or add data to, publish and post
            any such Expert Submissions, including in connection with or on Tova
            Medical, including in connection with your name. This means that
            when you make such a submission to us, you are giving us rights to
            this content, including the right to post it on Tova Medical and use
            it in connection with Tova Medical. You also give us the right to
            give or transfer all rights granted herein to others. While Expert
            Submissions generally are posted to Tova Medical, we do not have an
            obligation to post any particular Expert Submission on Tova Medical
            and reserve the right, at our sole discretion and for any reason
            whatsoever (including but not limited to an Expert Submission being
            reported to us), to not post and/or to remove any Expert Submissions
            at any time.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Medical Experts: Rules when Submitting Expert Content.
            </Typography>
          </p>
          <p>
            So that everyone can enjoy Tova Medical, we have rules for Expert
            Public Submissions. By submitting content to Tova Medical you agree
            to follow our{" "}
            <NavLink
              className={classes.navLink}
              to="/terms/medical-experts/#expertGuidelines"
            >
              Medical Expert Guidelines
            </NavLink>
            .
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Medical Experts: Tova Medical Premium Services.
            </Typography>
          </p>
          <p>
            You will receive compensation for participating in Tova Medical
            Premium Services pursuant to the additional terms applicable to
            those services and related agreements, which are incorporated herein
            by reference. When you sign up for and deliver care through Tova
            Medical Premium Services, you agree to abide by all of the terms of
            the applications you submit to, and the contractual agreements you
            enter into with, Tova Medical or Tova Medical Medical Group, P.C.
            related to those services (including any representations and
            warranties and terms governing indemnification and obligations).
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Medical Experts: Conflicts Disclosure Policy.
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              Disclosure of Relationships with Industry.
            </Typography>
          </p>
          <p>
            You agree to comply with this Conflicts Disclosure Policy ("CDP")
            when using Tova Medical or making Expert Submissions. This CDP
            governs the disclosure of financial interests by Tova Medical
            Medical Experts. Conflicts of interest can be financial and/or
            personal. As used in this policy, "Industry" means any company,
            entity, or third party that produces, manufactures, or distributes a
            pharmaceutical, medical device, implant, or other medical
            care-related product or service.
          </p>
          <p>
            All Medical Experts with a material financial relationship with
            Industry must disclose such a relationship in any answer or other
            similar Content submitted to Tova Medical when such Content mentions
            any product or service in which such an interest exists, with the
            following or similar language: "The author or poster of this content
            has a financial interest in a product or service mentioned herein."
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              No Posting Public Content for Pay
            </Typography>
          </p>
          <p>
            Medical Experts may not post public Content on Tova Medical in
            exchange for compensation of any kind, including but not limited to
            as a paid consultant to any entity (including any company or
            organization) or individual. Medical Experts should ensure that any
            conflict or potential conflict of interest does not affect or appear
            to affect his or her contributions of Content to the Tova Medical.
            Medical Experts approved for Tova Medical Premium Services may
            receive compensation for services rendered through Tova Medical
            Premium Services under the terms governing those services.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="h3">
              No Posting of Content Written by Interested Parties.
            </Typography>
          </p>
          <p>
            Medical Experts may not publish Content under their own names
            written in whole or material part by: (i) parties with a financial
            interest in any product or service mentioned in the Content; or (ii)
            employees of Industry. Accepting compensation for posting Content to
            public areas and/or posting ghostwriting by interested parties on
            Tova Medical is prohibited.
          </p>
          <p> </p>
        </div>
      </Container>
      <div component="div" style={{ marginTop: "150px" }}></div>
      {/* Section 2 */}
      <Footer />
      {/* /Section 2 */}
    </div>
  );
}

export default TermsMedicalExpert;
