import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Account from "./pages/Account";
import Directory from "./pages/Directory";
import Doctor from "./pages/Doctor";
import Home from "./pages/Home";
import Error from "./pages/Error";
import Navbar from "./components/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import axios from "axios";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayJsUtils from "@date-io/dayjs";
import CssBaseline from "@material-ui/core/CssBaseline";
import DoctorApplication from "./pages/auth/doctor/DoctorApplication";
import Applications from "./pages/Applications";
import ShowApplication from "./pages/ShowApplication";
import { connect } from "react-redux";
import PatientSignup from "./pages/auth/patient/PatientSignup";
import PatientSelectPlan from "./pages/auth/patient/PatientSelectPlan";
import PatientInfo from "./pages/auth/patient/PatientInfo";
import DoctorSignup from "./pages/auth/doctor/DoctorSignup";
// import DoctorApplicationSubmitted from "./pages/DoctorApplicationSubmitted";
import DoctorApplicationStatus from "./pages/DoctorApplicationStatus";
import PatientLogin from "./pages/auth/patient/PatientLogin";
import DoctorLogin from "./pages/auth/doctor/DoctorLogin";
import AdminLogin from "./pages/auth/admin/AdminLogin";
import AdminForgotPassword from "./pages/auth/admin/AdminForgotPassword";
import AdminResetPassword from "./pages/auth/admin/AdminResetPassword";
import PatientForgotPassword from "./pages/auth/patient/PatientForgotPassword";
import PatientResetPassword from "./pages/auth/patient/PatientResetPassword";
import DoctorForgotPassword from "./pages/auth/doctor/DoctorForgotPassword";
import DoctorResetPassword from "./pages/auth/doctor/DoctorResetPassword";
import BankAccount from "./pages/BankAccount";
import AddCard from "./pages/AddCard";
import AddBank from "./pages/AddBank";
import AddPaymentMethod from "./pages/AddPaymentMethod";
import PaymentMethods from "./pages/PaymentMethods";
import OneTimePayment from "./pages/OneTimePayment";
import CookiesConsent from "./components/CookiesConsent";
import Analytics from "./pages/Analytics";

import TermsMedicalExpert from "./pages/TermsMedicalExpert";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import CookiePolicy from "./pages/CookiePolicy";
import About from "./pages/About";
import AppProvider from "./context/AppProvider";
import Doctors from "./pages/Doctors/Doctors";
import SingleDoctor from "./pages/Doctors/Doctor";
import Patients from "./pages/Patients/Patients";
import SinglePatient from "./pages/Patients/Patient";
import EditPatient from "./pages/Patients/EditPatient";
import EditDoctor from "./pages/Doctors/EditDoctor";
import logoutAction from "./pages/auth/store/actions/logoutAction";
import Admins from "./pages/Admins/Admins";
import Admin from "./pages/Admins/Admin";
import CreateAdmin from "./pages/Admins/CreateAdmin";
import EditAdmin from "./pages/Admins/EditAdmin";
import Editors from "./pages/Editors/Editors";
import Editor from "./pages/Editors/Editor";
import CreateEditor from "./pages/Editors/CreateEditor";
import EditEditor from "./pages/Editors/EditEditor";
import Settings from "./pages/Settings";
import SupportUsers from "./pages/SupportUser/SupportUsers";
import SupportUser from "./pages/SupportUser/SupportUser";
import CreateSupportUser from "./pages/SupportUser/CreateSupportUser";
import EditSupportUser from "./pages/SupportUser/EditSupportUser";
import Newsroom from "./pages/Newsroom/Newsroom";
import utils from "./helpers/utils";
import Relations from "./pages/Investor/Relations";

function App(props) {
  document.body.style.overflowX = "hidden";
  document.body.style.width = "100% !important";

  useEffect(() => {
    if (
      props?.user &&
      props?.user["http://tova_roles"] &&
      props?.user["http://tova_roles"].includes("admin")
    ) {
      setInterval(() => {
        let sub = props.user.sub;
        utils.isAccountExists(sub.slice(6, sub.length));
      }, 1000 * 60 * 5);
    }
  }, [props.user]);

  // Load interceptor
  axios.interceptors.request.use(
    (config) => {
      if (config.url.indexOf("public") < 0) {
        // Only try to get a token for non public urls (secured ones)

        if (props?.auth) {
          config.headers.Authorization = `Bearer ${props.auth.access_token}`;
        }

        return Promise.resolve(config);
      }
      return Promise.resolve(config);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        error?.response?.status === 403 &&
        error?.response?.data?.isBanned === true
      ) {
        props.logout();
      }

      return Promise.reject(error);
    }
  );

  return (
    <React.Fragment>
      <AppProvider>
        <CssBaseline />
        <Navbar></Navbar>
        {/* <Container maxWidth="lg">
        <Typography component="div" style={{ backgroundColor: '#cfe8fc', height: 'calc(100vh - 100px)' }}> */}
        <MuiPickersUtilsProvider utils={DayJsUtils}>
          <main>
            <Switch>
              <Route exact path="/individual/signup/account-info">
                <PatientInfo />
              </Route>
              <Route exact path="/individual/signup/select-plan/:patientId?">
                <PatientSelectPlan />
              </Route>
              <Route exact path="/individual/signup">
                <PatientSignup />
              </Route>
              <Route exact path="/individual/login">
                <PatientLogin />
              </Route>
              <Route exact path="/doctor/signup">
                <DoctorSignup />
              </Route>
              <Route exact path="/doctor/login">
                <DoctorLogin />
              </Route>
              <Route exact path="/admin/login">
                <AdminLogin />
              </Route>

              <Route exact path="/admin/login">
                <AdminLogin />
              </Route>
              <Route exact path="/admin/forgot/password">
                <AdminForgotPassword />
              </Route>
              <Route exact path="/admin/reset/password/:token/:id">
                <AdminResetPassword />
              </Route>
              <Route exact path="/individual/forgot/password">
                <PatientForgotPassword />
              </Route>
              <Route exact path="/individual/reset/password/:token/:id">
                <PatientResetPassword />
              </Route>
              <Route exact path="/doctor/forgot/password">
                <DoctorForgotPassword />
              </Route>
              <Route exact path="/doctor/reset/password/:token/:id">
                <DoctorResetPassword />
              </Route>

              <Route exact path="/">
                <Home />
              </Route>

              <Route exact path="/apply">
                <DoctorApplication />
              </Route>
              <Route exact path="/termsmedical">
                <TermsMedicalExpert />
              </Route>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/cookies">
                <CookiePolicy />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              {/* <ProtectedRoute
                exact
                path="/application-submitted"
                component={DoctorApplicationSubmitted}
              /> */}

              <ProtectedRoute
                exact
                path="/application-status"
                component={DoctorApplicationStatus}
              />
              <ProtectedRoute
                exact
                path="/application-approved"
                component={DoctorApplicationStatus}
              />

              <ProtectedRoute exact path="/account" component={Account} />
              <ProtectedRoute exact path="/settings" component={Settings} />
              <ProtectedRoute exact path="/analytics" component={Analytics} />
              <ProtectedRoute exact path="/directory" component={Directory} />
              <ProtectedRoute exact path="/doctor/:id?" component={Doctor} />

              <ProtectedRoute
                exact
                path="/applications/:id"
                component={ShowApplication}
              />
              <ProtectedRoute
                exact
                path="/applications"
                component={Applications}
              />
              <ProtectedRoute exact path="/get/paid" component={BankAccount} />
              <ProtectedRoute exact path="/card/add" component={AddCard} />
              <ProtectedRoute exact path="/bank/add" component={AddBank} />

              <ProtectedRoute
                excact
                path="/payment/methods/add"
                component={AddPaymentMethod}
              />

              <ProtectedRoute
                excact
                path="/payment/methods"
                component={PaymentMethods}
              />

              <ProtectedRoute excact path="/doctors" component={Doctors} />
              <ProtectedRoute
                excact
                path="/account/doctor/:id/view"
                component={SingleDoctor}
              />
              <ProtectedRoute
                excact
                path="/patients/page/:pageNum"
                component={Patients}
              />
              <ProtectedRoute excact path="/patients" component={Patients} />
              <ProtectedRoute
                excact
                path="/patient/:id/view"
                component={SinglePatient}
              />
              <ProtectedRoute
                excact
                path="/patient/:id/edit"
                component={EditPatient}
              />
              <ProtectedRoute
                excact
                path="/one-time-payment"
                component={OneTimePayment}
              />
              <ProtectedRoute
                excact
                path="/doctor/:id/edit"
                component={EditDoctor}
              />
              {/* admin routes*/}
              <ProtectedRoute excact path="/admins" component={Admins} />
              <ProtectedRoute excact path="/admin/:id/view" component={Admin} />
              <ProtectedRoute
                excact
                path="/admin/create"
                component={CreateAdmin}
              />
              <ProtectedRoute
                excact
                path="/admin/:id/edit"
                component={EditAdmin}
              />
              {/* editor routes*/}
              <ProtectedRoute excact path="/editors" component={Editors} />
              <ProtectedRoute
                excact
                path="/editor/:id/view"
                component={Editor}
              />
              <ProtectedRoute
                excact
                path="/editor/create"
                component={CreateEditor}
              />
              <ProtectedRoute
                excact
                path="/editor/:id/edit"
                component={EditEditor}
              />
              {/* support users routes*/}
              <ProtectedRoute
                excact
                path="/support-users"
                component={SupportUsers}
              />
              <ProtectedRoute
                excact
                path="/support-user/:id/view"
                component={SupportUser}
              />
              <ProtectedRoute
                excact
                path="/support-user/create"
                component={CreateSupportUser}
              />
              <ProtectedRoute
                excact
                path="/support-user/:id/edit"
                component={EditSupportUser}
              />
              <Route exact path="/newsroom">
                <Newsroom />
              </Route>
              <Route exact path="/investor/relations">
                <Relations />
              </Route>
              <Route path="*">
                <Error code="404" />
              </Route>
            </Switch>
          </main>
        </MuiPickersUtilsProvider>
        {/* </Typography>
      </Container> */}
        <CookiesConsent />
      </AppProvider>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth0.auth,
    user: state.auth0.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (url) => dispatch(logoutAction(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
