import { NavLink } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
    paddingTop: "150px",

    "& .auc-text-wrapper a": {
      color: theme.palette.primary.main,
    },
  },
  mainHeading: {
    fontSize: "65px",
    fontWeight: 700,
    lineHeight: "83px",

    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
      marginTop: theme.spacing(3),
      lineHeight: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "30px",
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "20px",
      marginTop: theme.spacing(3),
    },
  },
  smallHeading: {
    fontWeight: "bold",
    marginTop: "60px",
    marginBottom: "25px",
    fontSize: "25px",
  },
  extraSmallHeading: {
    fontWeight: "bold",
    marginTop: "60px",
    fontSize: "20px",
    lineHeight: "30px",
  },
}));

function Terms(props) {
  document.body.style.background = "#fff";

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <div className={`${classes.termsWrapper} auc-text-wrapper`}>
          <Typography
            align="center"
            className={classes.mainHeading}
            variant="h1"
          >
            Terms of Use
          </Typography>
          <Typography align="center">
            Date last modified:March 11, 2021
          </Typography>

          <Typography className={classes.smallHeading} variant="h3">
            What is this document?
          </Typography>

          <p>
            These Terms of Use ("Terms" or "TOU") is an agreement between you
            and Tova Tova Medical LLC. ("Tova Medical"). It describes the rules
            you agree to follow when using our mobile applications and
            website(s) (the "Apps"), including when you ask questions and when
            you view or input content on or into the Apps.
          </p>
          <p>
            These Terms of Use apply to your use of Tova Medical as a patient or
            member. Medical Experts participating on Tova Medical are subject to
            different guidelines and terms found in{" "}
            <NavLink to="/termsmedical">Medical Expert Terms of Use</NavLink>.
            If you are viewing these Terms of Use for members and patients in
            error and you are a Medical Expert, please review and accept the
            Terms of Use for Medical Experts
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Definitions
            </Typography>
          </p>
          <p>
            To make this document shorter and clearer, we've included some
            definitions:
          </p>
          <ul>
            <li>
              <p>
                "24/7 Doctor Visits" means Tova Medical's virtual urgent care
                service.
              </p>
            </li>
            <li>
              <p>
                "Tova Medical" or the "Apps" means{" "}
                <NavLink to="">https://www.TovaMedical.com</NavLink>and related
                web sites and Tova Medical's mobile applications (including Tova
                Medical, Tova Medical for Doctors, and any other Apps we offer).
              </p>
            </li>
            <li>
              <p>
                "Content" means text, graphics, images, and any other material
                entered, processed, contained on or accessed through the Apps,
                including Content created, modified, or submitted by Medical
                Experts.
              </p>
            </li>
            <li>
              <p>
                "Tova Medical's Premium Plan" means the paid membership plan
                offered through the Apps referred to as "Tova Medical Prime".
              </p>
            </li>
            <li>
              <p>"Services" means services provided through the Apps.</p>
            </li>
            <li>
              <p>
                "Medical Expert" means a medical specialist or professional
                accepted to participate on or through the Apps.
              </p>
            </li>
            <li>
              <p>
                "Virtual Consult" means a consultation between a Medical Expert
                and a patient on the Apps including, but not limited to, 24/7
                Doctor Visits.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Introduction
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Services.
            </Typography>
          </p>
          <p>
            Tova Medical connects you with doctors and trusted health
            information to help you feel good and to live a happier, healthier
            life. Tova Medical offers standard (free) services, paid services,
            as well as a paid membership option, available through the same
            Apps. Tova Medical standard services encompass health information
            and not healthcare. Tova Medical's Premium Plan is a membership
            program that provides members access to discounts and enhanced
            services.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Remember:
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                Always consult with your existing doctor or healthcare provider
                or use a Virtual Consult whenever you have a personal question
                about a medical condition or symptom.
              </p>
            </li>
            <li>
              <p>
                Never disregard professional medical advice, or delay seeking
                medical advice or treatment, because of something you read or
                learn on Tova Medical. Always consult with your existing doctor
                or healthcare provider or use a Virtual Consult whenever you
                have a personal question about a medical condition or symptom.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.extraSmallHeading} variant="h3">
                  IMMEDIATELY CALL 911 OR YOUR LOCAL EMERGENCY SERVICE OR YOUR
                  DOCTOR IF YOU BELIEVE YOU MAY OR DO HAVE A MEDICAL EMERGENCY.
                </Typography>
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              These Terms of Use are an Agreement.
            </Typography>
          </p>
          <p>
            Generally, this agreement governs your use of the Apps. Other terms
            may apply to your use of a specific feature. If there is a conflict
            between this TOU and terms posted for a specific feature, the latter
            terms apply to your use of that feature or part.
          </p>
          <p>
            <Typography className={classes.extraSmallHeading} variant="h3">
              THESE TERMS OF USE REQUIRE THE USE OF ARBITRATION TO RESOLVE
              DISPUTES AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT
              OF A DISPUTE. BY ACCEPTING THESE TERMS, YOU ARE WAIVING THE RIGHT
              TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
              REPRESENTATIVE PROCEEDING. SEE
            </Typography>{" "}
            <NavLink to="/terms/#arbitrationAgreement">
              <Typography className={classes.extraSmallHeading} variant="h3">
                ARBITRATION AGREEMENT.
              </Typography>
            </NavLink>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We May Update this Agreement.
            </Typography>
          </p>
          <p>
            These Terms of Use may change on a going-forward basis at any time
            upon 7 days' notice. Please check these Terms periodically for
            changes. If a change to these Terms materially modifies your rights
            or obligations, we may require that you accept the modified Terms in
            order to continue to use the Service. Material modifications are
            effective upon your acceptance of the modified Terms. Immaterial
            modifications are effective upon publication. If you do not agree to
            all of these Terms of Use, please do not use Tova Medical.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              What We Do and What We Don't Do (Tova Medical)
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Support Your Health Decisions through Information.
            </Typography>
          </p>
          <p>
            Tova Medical is designed to support the health decisions and choices
            that you make. Always use common sense when making health decisions,
            and consult with your doctor whenever appropriate. Tova Medical
            cannot make decisions for you, but can help you find health
            information. On Tova Medical you can ask informational questions and
            find educational answers and health by Medical Experts. Except as
            may be provided via a Virtual Consult, this information is not
            medical care and no doctor-patient relationship is created by this
            use of the free services, such as our Q&amp;A and symptom assessment
            tools. Content and non-Virtual Care Services on Tova Medical are not
            a substitute for medical advice, diagnosis, treatment, or care from
            your physician. Except as may be provided via a Virtual Consult,
            information on Tova Medical is not a substitute for a formal
            diagnosis, physical examination, or prescription and should not be
            used to treat a medical condition. Do not ignore or delay obtaining
            professional medical advice because of information accessed through
            the Apps.
          </p>
          <p>
            Please use Tova Medical's Virtual Consults or see your doctor in
            person if you are looking for a personal medical evaluation,
            diagnosis, prescription, or treatment.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Provide Access to Doctors for Care (Virtual Consults).
            </Typography>
          </p>
          <p>
            Via Tova Medical Virtual Consults, you can obtain care (if you are
            located in a state where such service is available). Additional
            terms apply to Virtual Consults. Tova Medical Virtual Consults may
            support doctor-patient relationships. Virtual Consults may be used
            where an ongoing doctor-patient relationship exists, or where a
            temporary doctor-patient relationship is created by the use of
            Virtual Consults.
          </p>
          <p>
            Whenever we use the words "your physician" or "your doctor" or
            "healthcare provider" or similar words on Tova Medical, including in
            these Terms of Use, we mean your personal doctor with whom you have
            of an actual, mutually acknowledged, doctor/healthcare
            provider-patient relationship. If you participate in a Virtual
            Consult on Tova Medical, you understand and agree that the Medical
            Experts on Tova Medical are not your primary care physician, and you
            agree not to use Tova Medical Virtual Consults as a substitute for
            interactions with your primary care physician or health insurance.
            (Where available, you may also use Virtual Consults with your
            existing doctor(s).
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Provide Information without Recommendation or Endorsement.
            </Typography>
          </p>
          <p>
            We do not recommend or endorse any specific Content, Services,
            tests, doctors, products, procedures, opinions, or other information
            that may be mentioned on Tova Medical. Tova Medical is designed to
            be used with common sense, and in connection with the advice of your
            doctor or healthcare provider. Reliance on Tova Medical Content or
            Services is solely at your own risk. Some Services and Content
            (including posts by Medical Experts) may not be reviewed or
            certified by Tova Medical. We cannot guarantee that the Content and
            Services will help you achieve any specific goals or results.
            Content regarding dietary supplements or other treatments or
            regimens have not been evaluated by the Food and Drug Administration
            and is not intended to diagnose, treat, cure, or prevent any
            disease.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We're Not for Use in Emergencies.
            </Typography>
          </p>
          <p>
            Never use Tova Medical or Content or Services in a potential or
            actual medical emergency.
            <Typography className={classes.smallHeading} variant="p">
              CALL 911 OR YOUR DOCTOR FOR ALL MEDICAL EMERGENCIES.
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Medical Experts
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Independence of Tova Medical Medical Experts.
            </Typography>
          </p>
          <p>
            The inclusion of Medical Experts, professionals and specialists on
            Tova Medical or in any professional directory on Tova Medical does
            not imply Tova Medical's recommendation or endorsement of such
            professional nor is such information intended as a tool for
            verifying the credentials, qualifications, or abilities of any
            professional. SUCH INFORMATION IS PROVIDED ON AN "AS-IS" BASIS AND
            TOVA MEDICAL DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED,
            INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. TOVA MEDICAL
            SHALL IN NO EVENT BE LIABLE TO YOU OR TO ANYONE FOR ANY DECISION
            MADE OR ACTION TAKEN BY ANY PARTY (INCLUDING, WITHOUT LIMITATION,
            ANY USER) IN RELIANCE ON INFORMATION ABOUT MEDICAL EXPERTS,
            PROFESSIONALS AND SPECIALISTS ON TOVA MEDICAL. The use of Tova
            Medical by any entity or individual to verify the credentials of
            professionals or specialists is prohibited.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Your Account and Your Use of Tova Medical
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You are responsible for your account. Keep your password and real
              name private.
            </Typography>
          </p>
          <p>
            Accurate and complete registration information is required to use
            Tova Medical. You are solely responsible for the security of your
            passwords and for any use of your account, including any access to
            personal information in your account. If you suspect unauthorized
            use of your account, change your password immediately. Allowing any
            other person or entity to use your identity for posting on or using
            Tova Medical is not permitted. Do not include your real name or any
            other information that could be used to identify you in anything you
            post that is publicly visible on Tova Medical (such as in questions
            or other public submissions).
          </p>
          <p>
            We reserve the right to revoke or deactivate your username and
            password at any time. You may terminate your account and these Terms
            at any time by selecting to deactivate your account in the settings
            page or visiting the customer service help center at{" "}
            <NavLink to="/support">https://support.TovaMedical.com</NavLink>.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You must be an adult to use Tova Medical.
            </Typography>
          </p>
          <p>
            Currently, you must be at least 18 years old to use Tova Medical and
            to participate in a Virtual Consult.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Your use must be Legal and Appropriate.
            </Typography>
          </p>
          <p>
            Your use of Tova Medical and any Content and Services must comply
            with all applicable federal and state laws, regulations, and
            ordinances. You may not access our networks, computers, or the
            Content and Services in any manner that could damage, disable,
            overburden, or impair them, or interfere with any other person's use
            and enjoyment. You may not attempt to gain unauthorized access to
            any Content or Services, other accounts, computer systems, or
            networks connected to Tova Medical, the Content, or Services. You
            may not use any automated means (such as a scraper) to access Tova
            Medical, the Content, or Services. Unauthorized access includes
            using credentials to access Tova Medical. Any attempt by any
            individual or entity to solicit login information of any other user
            or Tova Medical Medical Expert, or to access any such account, is an
            express and direct violation of these Terms and of applicable law,
            including relevant privacy and security laws and laws prohibiting
            unfair or unethical business practices.
          </p>
          <p>
            We maintain guidelines and a code of conduct for users of Tova
            Medical. By using our Services you agree to abide by our{" "}
            <NavLink to="/terms/#userGuidelines">Guidelines</NavLink>.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We'll send you notices and information.
            </Typography>
          </p>
          <p>
            For more details about when and how we can communicate with you,
            please consult our{" "}
            <NavLink to="/privacy">Privacy Statement</NavLink>.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Content and Services
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical is a Free Service That Offers Paid Services.
            </Typography>
          </p>
          <p>
            It's free to ask health questions and receive informational health
            answers and tips on Tova Medical. Tova Medical also provides
            optional Services and Content that can be accessed for a fee, such
            as Virtual Consults. Tova Medical also offers Tova Medical's Premium
            Plan, a paid membership plan that includes discounts and enhanced
            services. We will never ask you for your credit card or other
            payment information unless you indicate that you would like to use a
            paid service.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical is for personal use.
            </Typography>
          </p>
          <p>
            Tova Medical is a service for individuals to use to support their
            personal health decisions. You may use Tova Medical for personal,
            but not for commercial purposes in accordance with all applicable
            federal, state and local laws. The Content is licensed only for the
            personal, household, and educational use of a single individual. No
            commercial use or commercial redistribution of any Content is
            permitted. Licensed U.S. doctors and other qualified medical experts
            may apply to participate on Tova Medical for educational purposes as
            Medical Experts, or to provide Virtual Consults. Companies or other
            organizations may not become registered members or use our Apps
            through individual members, but we do offer enterprise services for
            organizations.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical does not guarantee the accuracy of Third Party
              Content.
            </Typography>
          </p>
          <p>
            Tova Medical has no editorial control over or responsibility for
            Content provided by third parties. Any opinions, statements,
            products, services, or other information expressed or made available
            by third parties (including Medical Experts) or users on Tova
            Medical are those of such third parties or users. Tova Medical does
            not have any obligation to monitor such third party Content. We make
            no representations about the accuracy or reliability of any opinion,
            statement, or other information provided by any third party, and we
            do not represent or warrant that your use of the Content displayed
            or referenced on Tova Medical will not infringe the rights of third
            parties not owned by or affiliated with Tova Medical.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We are not responsible for anything outside of Tova Medical.
            </Typography>
          </p>
          <p>
            The Apps may contain links to other apps, web sites, information,
            software, data, or other content, online or offline ("External
            Content and Services"). Such External Content and Services and
            related information are outside of our control. We do not control,
            endorse, verify the truth or accuracy of, or review content outside
            of Tova Medical, and we are not responsible for such content. We do
            not warrant, nor are we in any way responsible for, information,
            software, data, or privacy policies related or pertaining to
            External Content and Services.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical may communicate with you via email, SMS, text and
              mobile push notification.
            </Typography>
          </p>
          <p>
            When you install our app on your mobile device, you may agree to
            receive push notifications, which are messages an app sends you on
            your mobile device when the app is not on. You can turn off
            notifications by visiting your mobile device's "settings" page.
          </p>
          <p>
            We may send you emails concerning our products and services, as well
            as those of third parties. You may opt out of promotional emails by
            following the unsubscribe instructions in the promotional email
            itself.
          </p>
          <p>
            Tova Medical and those acting on our behalf may send you text (SMS)
            messages to the phone number you provide us. These messages may
            include operational messages about your use of the Services, as well
            as marketing messages. If you opt out, you may continue to receive
            text messages for a short period while Tova Medical processes your
            request, and you may also receive text messages confirming the
            receipt of your opt-out request. Opting out of receiving operational
            text messages may impact the functionality that the Service provides
            to you. Text messages may be sent using an automatic telephone
            dialing system. Your agreement to receive text messages is not a
            condition of any purchase or use of the Service. Standard messaging,
            data and other fees may be charged by your carrier.
          </p>
          <p>
            Your carrier may prohibit or restrict certain mobile features, and
            certain mobile features may be incompatible with your carrier or
            mobile device. As applicable, instructions regarding how to opt-out
            of mobile features will be disclosed in connection with such
            features (instructions typically require you to text a keyword, such
            as "STOP," "CANCEL," "UNSUBSCRIBE," to the applicable shortcode for
            the mobile feature, or to change your profile settings inside the
            Apps).
          </p>
          <p>
            You agree to notify Tova Medical of any changes to your mobile
            number and update your account(s) on the Tova Medical Platforms. You
            also understand and agree that by receiving communications you will
            be charged by your wireless or internet provider and that such
            emails, SMS, or mobile telephone notification may be generated by
            automated systems.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You agree that we may send you limited personal information by
              email, SMS, text, and mobile push notification.
            </Typography>
          </p>
          <p>
            When you use action-oriented features on Tova Medical (such as
            subscribing to a health checklist or participating in a Virtual
            Consult), you agree that Tova Medical and Tova Medical Medical
            Group, P.C. may send you automated content via email, mobile
            telephone, or other contact information provided by you in your
            account settings. This content may contain protected health
            information under HIPAA, including content related to conditions,
            treatments, and medications. You understand and agree that by using
            these features, you are expressly opting into receiving your own
            protected health information by email, SMS/text, or mobile push
            notifications. These communications from the Apps are not encrypted.
            Although unlikely, it is possible for these communications to be
            intercepted or accessed without your authorization. By using the
            Apps, you release Tova Medical from any liability arising from or
            related to any such interception or unauthorized access.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Mandatory Removal of Content and Services.
            </Typography>
          </p>
          <p>
            If you violate any of these Terms of Use, your permission to use the
            Content and Services automatically terminates and you must
            immediately destroy any copies you have made of any portion of the
            Content.
          </p>
          <p></p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We cannot control external communications (including email, SMS,
              and notifications).
            </Typography>
          </p>
          <p>
            Email, short message services (SMS), text message communications and
            mobile push notifications from the Apps are not encrypted. You can
            opt out of receiving email, SMS/text messages, and mobile push
            notifications. Although unlikely, it is possible for these
            communications to be intercepted or accessed without your
            authorization. By using the Apps, you release Tova Medical from any
            liability arising from or related to any such interception or
            unauthorized access.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Advertising
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Strictly Limited Content.
            </Typography>
          </p>
          <p>
            To further our mission of making reliable health information
            available to the public, we accept limited advertising and
            sponsorship under strict guidelines. Advertisers and sponsors are
            not permitted to make unsubstantiated health claims or suggest that
            Tova Medical endorses any product or service. Tova Medical does not
            endorse products or services. Advertisements may be placed on our
            website or mobile apps adjacent to content related to advertiser or
            sponsor interest, and also may be presented to users using
            non-personal data. Tova Medical does not permit the collection of
            any personally identifiable information from our site visitors on
            Tova Medical web or mobile apps. For more information see below:
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Advertising and Promotion Criteria
                </Typography>
              </p>
            </li>
          </ul>
          <p>
            Tova Medical offers select advertising opportunities on our site and
            in our apps. Advertising and sponsorship revenue is used to support
            our mission of providing free access to doctor-created health
            content. Criteria are subject to change without notice. For
            information contact:{" "}
            <NavLink to="mailto:Support@TovaMedical.com">
              Support@TovaMedical.com
            </NavLink>{" "}
            .
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Permissible Advertising
                </Typography>
              </p>
            </li>
          </ul>
          <p>
            Corporate image advertising or sponsorship, FDA-approved
            over-the-counter medications or prescription drugs (excluding
            Schedule IV drugs), health-related consumer products, fitness
            products or services, medical products or devices, pharmacies
            (retail and VIPPS-compliant online).
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Prohibited Advertising
                </Typography>
              </p>
            </li>
          </ul>
          <p>
            Alcohol, tobacco products, weapons, health care services (clinics,
            hospitals, procedures, lab testing), legal services, pornography,
            gambling, political ads, social cause ads, religious ads, competitor
            ads, comparative advertising (including any mention of competitive
            brand names within an advertisement), pop-ups and floating content,
            and any ads that enable collection of personally identifiable
            information on the Tova Medical site.
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Limitations
                </Typography>
              </p>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    No substantiated health-related claims (supporting
                    documentation may be required)
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    No implication of endorsement by Tova Medical, Tova Medical
                    affiliated entities, or doctors on Tova Medical
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>No unsolicited commercial messages</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    No collection of user personal information or contact
                    information without express and legally compliant user
                    consent and no appliets, cookies, or files may be placed
                    that transmit personally identifiable information
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Final approval of all content is at the discretion of Tova
                    Medical and verification of content and placement may be
                    required before it is produced on our site or apps
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Paid content must be clearly identifiable as separate and
                    distinct from Tova Medical content
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Tova Medical reserves the right to determine the placement
                    of paid content, and to reject, cancel or remove at any time
                    any paid content from our site or apps for any reason
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>No co-branding of our health information</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>Tova Medical does not endorse any products or services</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    These guidelines are not exhaustive and are subject to
                    change at Tova Medical's sole discretion at any time
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Any paid advertising listings posted on search results may be
            identified as "Ads by Google" and may be removed if they do not meet
            our criteria. Some listings may appear that don't meet our ad
            guidelines because of the way ads are served by Google.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Property Rights
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical owns or has rights to the Content and Services.
            </Typography>
          </p>
          <p>
            When you use the Apps, you do so under a license from us. Subject to
            your complete and ongoing compliance with these Terms, Tova Medical
            grants you, solely for your personal, non-commercial use, a limited,
            non-exclusive, non-transferable, non-sublicensable, revocable
            license to: (a) install and use one object code copy of our mobile
            App obtained from a legitimate marketplace on a mobile device that
            you own or control; and (b) access and use the Content and Services.
            This means that you may not use the Apps for any commercial purpose,
            that we can take away your right to use the Apps, and that you
            cannot give this license away to someone else. All right, title, and
            interest in and to the Apps, Services, and the Content, together
            with all related intellectual property rights are the property of
            Tova Medical or our affiliates, excluding your rights under
            applicable law to any information or Content related to Virtual
            Consults. Rights retained by other parties in the Content are their
            respective rights. Tova Medical reserves all rights to the Content
            not granted expressly in these Terms.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You agree not to infringe our Copyrights, Trademarks, Trade
              Secrets, Patents, or other intellectual property rights.
            </Typography>
          </p>
          <p>
            Tova Medical and other related marks are registered trademarks of
            Tova Medical, Inc. Any other trademark, brand, or content on Tova
            Medical that is not the property of Tova Medical is the property of
            its respective owner. You agree not to violate, or encourage others
            to violate, any right of a third party, including by infringing or
            misappropriating any third party intellectual property rights. You
            may not reproduce, create derivative works of, distribute, publicly
            display the Content (or any portion of it) without our prior written
            consent. However, you may use App features to repost Content or
            portions of Content, including through other third party
            applications and mediums (such as Facebook or Twitter), so long as
            you do not modify that Content or the functionality of those
            features. This re-posting right does not create any additional
            rights in such Content. Additionally, you may not use any metatags
            or any other "hidden text" utilizing the name "Tova Medical" without
            our prior written permission.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You agree not to access, attempt to access, or use our data
              without our permission.
            </Typography>
          </p>
          <p>
            Except and solely to the extent such a restriction is impermissible
            under applicable law, you may not: (a) reproduce, distribute,
            publicly display, or publicly perform the Apps, Services, or
            Content; (b) make modifications to the Apps, Services, or Content;
            or (c) interfere with or circumvent any feature of the Apps,
            including any security or access control mechanism. If you are
            prohibited under applicable law from using the Service, you may not
            use it. You agree not to access, attempt to access, request access
            not authorized by the Apps or use any App Content or data without
            our permission. This means that you agree not to transmit, download,
            upload, post, sell, rent, license, transfer, disclose, mirror,
            frame, reverse engineer, decompile, disassemble, or use any aspect
            of the Apps or any Content, in whole or in part, in any form or by
            any means.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Contact us if you believe materials on our Apps infringe your
              copyright.
            </Typography>
          </p>
          <p>
            If you believe any materials accessible on or from Tova Medical
            infringe your valid and enforceable copyright, you may request
            removal of (or access to) those materials (or access thereto) from
            us by contacting us at Support@TovaMedical.com.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Submissions
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You agree to abide by our Submission Guidelines.
            </Typography>
          </p>
          <p>
            You agree that you will not upload or transmit any communications or
            content of any type that infringes or violates any rights of any
            party. The personal information you submit to Tova Medical is
            governed by the <NavLink to="/privacy/">Privacy Statement</NavLink>
            (the terms of which govern in the event of any inconsistency with
            this TOU). You agree that submissions will comply with Tova
            Medical's Guidelines. If you are a user, you agree to the{" "}
            <NavLink to="/terms/#userGuidelines">User Guidelines</NavLink>. If
            you are a Medical Expert, you agree to the{" "}
            <NavLink to="/termsmedical/#expertGuidelines">
              Medical Expert Guidelines
            </NavLink>
            .
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You give us rights in what you submit.
            </Typography>
          </p>
          <p>
            Don't submit anything to us if you don't want to give us rights to
            it. If you wish to keep any content, business information, ideas,
            concepts or inventions private or proprietary, do not submit them
            on, through, or to Tova Medical, by email or otherwise. With the
            exception of any personal data or information you submit (which
            shall be maintained in accordance with our Privacy Statement) and
            other information governed by the Health Insurance Portability and
            Accountability Act of 1996, Public Law 104-191 and the rules and
            regulations promulgated thereunder (as amended to date, "HIPAA"), or
            other applicable laws, if you make any submissions (by email or
            otherwise) on, to or through Tova Medical, including but not limited
            to media (including photographs), data, questions, comments,
            suggestions, business information, ideas, concepts or inventions
            (collectively "Submissions"), you make such submission without any
            restrictions or expectation of compensation, privacy, or
            confidentiality. You agree that your Submissions may be used by us
            without restriction for any purpose whatsoever. By making any a
            Submission, you grant Tova Medical a worldwide, non-exclusive,
            irrevocable, royalty-free, fully paid right and license (with the
            right to sublicense) to host, store, transfer, display, perform,
            reproduce, modify for the purpose of formatting for display, and
            distribute your Submissions, in whole or in part, in any media
            formats and through any media channels now known or hereafter
            developed. This means Tova Medical has the complete right to freely
            use, create derivative works from and modify, such Submissions in
            any way, commercial or otherwise (including developing and marketing
            products or features using such information), and for any purpose
            whatsoever and without limitation. Tova Medical may sublicense its
            rights.
          </p>
          <p>
            By making any a Submission, you further agree to indemnify Tova
            Medical and its affiliates, directors, officers, Medical Experts,
            and employees, and to hold them harmless from any and all claims and
            expenses, including attorneys' fees, arising from your Submissions,
            or your failure to comply with these Terms.
          </p>
          <p>
            Tova Medical does not accept unsolicited recruiter or similar
            submissions. Any candidate submissions by a recruiter or other third
            party without a valid and signed recruiting agreement in place with
            Tova Medical prior to such submission will not be subject to any
            recruiter or similar fees.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Termination
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              All of our members are required to honor this Agreement.
            </Typography>
          </p>
          <p>
            Your permission to use the Apps, Content, and Services ends
            immediately if you violate any of the terms of this Agreement. We
            may place limits on, modify, or terminate your right to access and
            use Apps and the Services and/or Content at any time for any reason
            or no reason, with or without notice. This suspension or termination
            may delete information, files, and other previously available
            Content. We also reserve the right to modify or discontinue the
            Services at any time (including by limiting or discontinuing certain
            features of the Apps), temporarily or permanently, without notice to
            you. We will have no liability whatsoever on account of any change
            to the Services or any suspension or termination of your access to
            or use of the Services.
          </p>
          <p>
            You may terminate your account at any time by visiting the customer
            service help center at{" "}
            <NavLink to="/support">https://support.TovaMedical.com</NavLink>. If
            you terminate your account, you remain obligated to pay all
            outstanding fees, if any, incurred prior to termination relating to
            your use of the Services.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Effect of Termination.
            </Typography>
          </p>
          <p>
            Upon termination of these Terms: (a) your license rights will
            terminate and you must immediately cease all use of the Service; (b)
            you will no longer be authorized to access your account or the
            Service; (c) you must pay Tova Medical any unpaid amount that was
            due prior to termination; and (d) all payment obligations accrued
            prior to termination, this section, and sections regarding
            Submissions, ownership, indemnities, disclaimer of warranties,
            limitations on liability, and the Arbitration Agreement will
            survive.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Indemnification &amp; Exclusions and Limitations
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Exclusion of Warranties.
            </Typography>
          </p>
          <p>
            THE APPS AND THE CONTENT AND SERVICES ARE PROVIDED "AS IS" AND ON AN
            "AS AVAILABLE" BASIS WITHOUT WARRANTY OR CONDITION OF ANY KIND,
            EITHER EXPRESS OR IMPLIED. NEITHER WE, TOVA MEDICAL MEDICAL EXPERTS,
            NOR ANY OF OUR LICENSORS MAKE ANY EXPRESS WARRANTIES, AND WE AND
            EACH OF THEM DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING THE IMPLIED
            WARRANTIES OF ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, AND NON-INFRINGEMENT. NEITHER WE, TOVA MEDICAL MEDICAL
            EXPERTS, NOR ANY OF OUR LICENSORS MAKE ANY WARRANTY THAT CONTENT OR
            SERVICES SATISFY GOVERNMENT REGULATIONS, INCLUDING THOSE REQUIRING
            DISCLOSURE OF INFORMATION ON PRESCRIPTION DRUG PRODUCTS. TOVA
            MEDICAL AND THE CONTENT AND SERVICES WERE DEVELOPED FOR USE IN THE
            UNITED STATES, AND NEITHER WE NOR ANY OF OUR LICENSORS MAKE ANY
            REPRESENTATION CONCERNING TOVA MEDICAL AND THE CONTENT OR SERVICES
            WHEN USED IN ANY OTHER COUNTRY.
          </p>
          <p>
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
            FROM THE SERVICE OR TOVA MEDICAL, OR ANY MATERIALS OR CONTENT
            AVAILABLE THROUGH THE APPS, WILL CREATE ANY WARRANTY THAT IS NOT
            EXPRESSLY STATED IN THESE TERMS.
          </p>
          <p>
            Specifically, and without limiting the foregoing, we, our licensors,
            and our suppliers, make no representations or warranties about: (i)
            the accuracy, reliability, completeness, currentness, or timeliness
            of the Content provided on or through the use of the App; or (ii)
            regulations requiring disclosure of information on prescription drug
            products or the approval or compliance of any software tools with
            Tova Medical. Any location data accessed via the Apps may be
            inaccurate or incomplete and any use of such data is at your own
            risk.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Limitation of Liability of Tova Medical and Third Party
              Beneficiaries.
            </Typography>
          </p>
          <p>
            WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE
            SERVICE AND YOUR DEALING WITH ANY OTHER USER, INCLUDING MEDICAL
            EXPERTS. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE
            SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT
            RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY OR ANY LOSS OF DATA,
            INCLUDING SUBMISSIONS.
          </p>
          <p>
            To the fullest extent permitted by law, in no event will Tova
            Medical, its licensors, suppliers, or any third parties mentioned on
            Tova Medical be liable for any personal injury, including death,
            attributable to or caused by your use or misuse of Tova Medical or
            Content (including Medical Expert Content). Any claims arising in
            connection with your use of the Apps, Services, or any Content must
            be brought within one (1) year of the first date of the event giving
            rise to such action. Remedies under these Terms are exclusive and
            are limited to those expressly provided for in these Terms. You
            expressly agree that Medical Experts are third party beneficiaries
            under these Terms and may enforce the rights hereunder, including
            Limitation of Liability rights.
          </p>
          <p>
            NEITHER WE, TOVA MEDICAL MEDICAL EXPERTS, NOR ANY OF OUR LICENSORS
            MAY BE HELD LIABLE UNDER THIS AGREEMENT FOR ANY DAMAGES OTHER THAN
            DIRECT DAMAGES, EVEN IF THE PARTY KNOWS OR SHOULD KNOW THAT OTHER
            DAMAGES ARE POSSIBLE, OR THAT DIRECT DAMAGES ARE NOT A SATISFACTORY
            REMEDY. THESE LIMITATIONS APPLY TO YOU ONLY TO THE EXTENT THEY ARE
            LAWFUL IN YOUR JURISDICTION. EXCEPT AS PROVIDED IN THE{" "}
            <NavLink to="/terms/#arbitrationAgreement">
              ARBITRATION AGREEMENT
            </NavLink>
            , NEITHER US, THE MEDICAL EXPERTS ON TOVA MEDICAL, NOR ANY OF OUR
            LICENSORS MAY BE HELD LIABLE UNDER THIS AGREEMENT TO ANY USER FOR
            ANY CLAIMS (IN AGGREGATE OVER ALL TIME) FOR MORE THAN THE GREATER
            OF: (I) FEES PAID BY THE USER TO TOVA MEDICAL OVER THE COURSE OF THE
            SIX (6) MONTHS IMMEDIATELY PRIOR TO THE INCIDENT GIVING TO THE
            ALLEGED DAMAGES; OR (II) FOR USERS WHO HAVE NOT SO USED PAID
            SERVICES, TO ONE HUNDRED DOLLARS ($100).
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Limitations of Liability and Indemnity for Expert Content.
            </Typography>
          </p>
          <p>
            Informational content on the Apps that is created, modified,
            submitted, or validated by Medical Experts or other healthcare
            experts (collectively, "Expert Content") is subject to the following
            additional terms and conditions and agreements by You.
          </p>
          <p>
            Expert Content is for informational purposes only. For medical
            advice, treatment, or diagnosis, see your personal doctor or
            healthcare provider. Tova Medical is not responsible for Expert
            Content. The authors or posters of Expert Content ("Posters") are
            solely responsible for such content. No representations, warranties,
            or guarantees of any kind are made regarding the Expert Content.
            Under no circumstances shall any party be liable (to you or to any
            other person) for any damages or harm (of any type or under any
            legal theory) resulting from or related to the Expert Content. No
            party shall have any liability for: (a) any loss or injury caused,
            in whole or in part, by a Poster's actions, omissions, or
            negligence, in procuring, compiling, or delivering information
            within or through Expert Content; (b) any errors, omissions, or
            inaccuracies in Expert Content (regardless of cause), or delays or
            interruptions in delivery of such information; or (c) any decision
            made or action taken or not taken in reliance upon such information.
            You agree to indemnify and hold the Poster (and the Poster's
            employer and/or sponsor) harmless from any claim or demand,
            including attorneys' fees, made by any third party as a result of
            any violation of law that occurs by you through your use of Expert
            Content or this Site and/or anything you do using Expert Content,
            our Apps and/or the information contained therein.
          </p>
          <p>
            IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT OR MATERIALS ON OUR
            SITE, OR ANY SERVICES OR INFORMATION AVAILABLE THROUGH THE SITE,
            YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING
            OUR SITE. THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS
            AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Licensed Content
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Certain Content may be licensed from third-parties.
            </Typography>
          </p>
          <p>
            The licenses for some of this Content may contain additional terms.
            When such Content licenses contain additional terms, we will make
            these terms available to you on those pages or in the Terms of Use.
          </p>
          <p></p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Foreign Languages
            </Typography>
          </p>
          <p>
            Where Tova Medical Content or Services (including these Terms) are
            translated into languages other than English, all such translations
            are for the convenience of our users only, and Tova Medical is not
            responsible or liable in the event of any translation inaccuracy.
            The English-language version of these Terms shall control and apply
            In the event of any conflict with content or translation. You
            understand that Content, including but not limited to questions and
            answers, may not have the same meaning in translation, and that
            treatments (including but not limited to medication names) and other
            information may differ from country to country and in different
            languages and may not be available in all places. Additionally, you
            understand and agree that Virtual Consults with Medical Experts may
            not be available in languages other than English, and you agree not
            to conduct a consultation with a doctor in a language in which you
            are not proficient without the use of a professional translator. If
            you are connected to a Medical Expert who speaks your language, you
            understand that the doctor may not be a native speaker and that the
            doctor's ability to communicate may be limited. If you choose to use
            a translator in connection with a Virtual Consult, you understand
            that Tova Medical is not liable for any errors or omissions in
            translation.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              General Legal Terms
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              General Terms.
            </Typography>
          </p>
          <p>
            These Terms, and the other agreements referenced in it (like our{" "}
            <NavLink to="/privacy">Privacy Statement</NavLink>and{" "}
            <NavLink to="/cookies">Cookie Policy</NavLink>), are the entire
            agreement between you and us relating to the Tova Medical Apps. Your
            use of the Services is subject to all additional terms, policies,
            rules, or guidelines applicable to the Service or certain features
            of the Service that we may post on or link to from the Service (the
            "Additional Terms"). All Additional Terms are incorporated by this
            reference into, and made a part of these Terms. These Terms replace
            any prior agreements unless such prior or subsequent agreement
            explicitly provides otherwise and specifically references these
            Terms. If there is any conflict between these Terms and a mutually
            signed written agreement between you and us related to Tova Medical,
            the signed written agreement will control. You may not assign or
            transfer these Terms or your rights under these Terms, in whole or
            in part, by operation of law or otherwise, without our prior written
            consent. We may assign these Terms at any time without notice or
            consent.
          </p>
          <p>
            If we choose not to enforce any provision of these Terms, we retain
            the right to enforce it in the future. This means that the failure
            to enforce any provision of these Terms does not constitute a waiver
            of that provision. If any provision in these Terms is found to be
            unenforceable, that provision and any related provisions will be
            interpreted to best accomplish the unenforceable provision's
            essential purpose.
          </p>
          <p>
            This agreement is governed by California law, excluding California's
            choice-of-law rules. THE EXCLUSIVE VENUE FOR ANY DISPUTE RELATING TO
            THIS AGREEMENT IS SANTA CLARA COUNTY, CALIFORNIA. YOU AND US CONSENT
            TO THE PERSONAL JURISDICTION OF THESE COURTS. Nothing in this
            agreement limits either party's ability to seek equitable relief.
          </p>
          <p>
            IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT ON OUR APPS, OR ANY
            SERVICES OR INFORMATION AVAILABLE ON OR THROUGH THE APPS, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING OUR APPS.
            THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT
            FAIL THEIR ESSENTIAL PURPOSE.
          </p>
          <p>
            The Apps and Services are offered by Tova Medical, Inc. located at
            954 PR-25, Suite 205, #10254, Puerto Rico, US. You may contact us by
            sending correspondence to that address or by visiting the customer
            service help center at{" "}
            <NavLink to="/support">https://support.TovaMedical.com</NavLink>.
          </p>
          <p>
            If you are a California resident, under California Civil Code
            Section 1789.3, you may contact the Complaint Assistance Unit of the
            Division of Consumer Services of the California Department of
            Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202,
            Sacramento, California 95834, or by telephone at (800) 952-5210 in
            order to resolve a complaint regarding the Service or to receive
            further information regarding the use of the Service.
          </p>
          <p>
            We are under no obligation to provide support for the Service. In
            instances where we may offer support, the support will be subject to
            published policies.
          </p>
          <p>
            Notice Regarding Apple. This section only applies to the extent you
            are using our mobile application on an iOS device. You acknowledge
            that these Terms are between you and Tova Medical only, not with
            Apple Inc. ("Apple"), and Apple is not responsible for the App or
            the content thereof. Apple has no obligation to furnish any
            maintenance and support services with respect to the App. If the App
            fails to conform to any applicable warranty, you may notify Apple
            and Apple will refund any applicable purchase price for the mobile
            application to you; and, to the maximum extent permitted by
            applicable law, Apple has no other warranty obligation with respect
            to the App. Apple is not responsible for addressing any claims by
            you or any third party relating to the App or your possession and/or
            use of the App, including: (a) product liability claims; (b) any
            claim that the App fails to conform to any applicable legal or
            regulatory requirement; or (c) claims arising under consumer
            protection or similar legislation. Apple is not responsible for the
            investigation, defense, settlement and discharge of any third party
            claim that the App and/or your possession and use of the App
            infringe a third party's intellectual property rights. You agree to
            comply with any applicable third party terms when using the App.
            Apple and Apple's subsidiaries are third party beneficiaries of
            these Terms, and upon your acceptance of these Terms, Apple will
            have the right (and will be deemed to have accepted the right) to
            enforce these Terms against you as a third party beneficiary of
            these Terms. You hereby represent and warrant that: (i) you are not
            located in a country that is subject to a U.S. Government embargo,
            or that has been designated by the U.S. Government as a "terrorist
            supporting" country; and (ii) you are not listed on any U.S.
            Government list of prohibited or restricted parties.
          </p>
          <p>
            CVS and MinuteClinic, LLC and its subsidiaries and managed entities
            are not affiliated with Tova Medical. The names and symbols of CVS
            and its affiliates and subsidiaries, including MinuteClinic, LLC are
            owned by and proprietary to CVS, and have been licensed for use by
            Tova Medical.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              User Guidelines
            </Typography>
          </p>
          <p>
            By using Tova Medical, including Virtual Consults, you agree to
            abide by the following guidelines. Any violation may result in
            limitation, suspension, or termination of your access to our Apps.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              General Guidelines.
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Respect.
                </Typography>
                You agree to engage with Medical Experts in a respectful manner
                and to refrain from inappropriate language and behavior.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Appropriate Use.
                </Typography>
                You agree to only use Tova Medical and Virtual Consults in a
                manner that is not: unlawful, threatening, harassing, abusive,
                defamatory, slanderous, libelous, harmful to minors, vulgar,
                gratuitously violent, obscene, pornographic, indecent, lewd,
                invasive of another's privacy, or racially, ethnically or
                otherwise offensive, hateful, or abusive.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Guidelines for Posting Content (Including Questions).
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Informational Questions Only.
                </Typography>
                Only informational questions submitted for educational purposes
                are allowed. Do not post any facts that give the impression that
                a question is uniquely patient-specific, or are about a specific
                person, including yourself.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Requests for Medical Care, Diagnosis, or Treatment.
                </Typography>
                No requests for prescription, diagnosis, or treatment should be
                made on Tova Medical (for medical care, please use Virtual
                Consults, as appropriate and available). Any second opinions
                received on Tova Medical are not diagnosis, prescription, or
                treatment, and are for informational purposes only.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Guarantees.
                </Typography>
                There is no guarantee that any submitted question will be
                answered; questions are answered at the sole discretion of
                participating Medical Experts. No follow-up questions or
                personal information should be included in any notes or other
                posts on or through Tova Medical.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Only Adults May Post.
                </Typography>
                Minors (persons under the age of18) may not obtain Virtual
                Consults on Tova Medical. Caregivers may post educational
                questions related to authorized care recipients (such as the
                mother posting questions about an infant).
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Posting of Personally Identifiable Information.
                </Typography>
                Do not post name(s), email address(es), or telephone number(s),
                URLs, or any other confidential or personally identifiable
                information about you or any other person or entity on Tova
                Medical. The foregoing does not apply to the extent necessary to
                use Virtual Consults or Tova Medical's Premium Plan in
                accordance with these Terms.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Posting for Illegal Purposes.
                </Typography>
                Do not use Tova Medical for any purpose in violation of local,
                state, federal, or international laws.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Infringing or Impersonating Postings.
                </Typography>
                Do not post material that infringes on the copyrights or other
                intellectual property rights of others (never post a picture of
                another person without permission) or on the privacy or
                publicity rights of others. Do not post impersonating another
                person or entity.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Inappropriate Postings.
                </Typography>
                Do not post material that is unlawful, misleading, obscene,
                sexually explicit, medically inappropriate, derogatory,
                defamatory, threatening, harassing, abusive, slanderous,
                hateful, or embarrassing to any other person or entity, or any
                material that could give rise to any civil or criminal liability
                under applicable law or regulations or that otherwise may be in
                conflict with our Terms or Privacy Statement.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Viruses or Software.
                </Typography>
                You agree that you will not upload any material that contains
                software viruses or any other computer code, files, or programs
                designed to interrupt, destroy or limit the functionality of any
                computer software or the Apps.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Excessive or Inappropriate use.
                </Typography>
                Do not use the Apps excessively, inappropriately, or in ways
                that the Apps were not designed for or that are not, in the
                opinion of Tova Medical Medical Experts, medically appropriate
                or legitimate.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Promotions or Links.
                </Typography>
                Do not post advertisements, solicitations, or links to other
                websites or individuals.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Spam or Schemes.
                </Typography>
                Do not post the same question more than once or "spam" Tova
                Medical. Do not post chain letters or pyramid or other schemes.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Detrimental Behavior.
                </Typography>
                Engaging in any other conduct that restricts or inhibits any
                other person from using or enjoying Tova Medical, or which, in
                the judgment of Tova Medical, exposes us or any of our members,
                partners or suppliers to any liability or detriment of any type.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Guidelines Specific to Virtual Consults and Other Paid Services.
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Treatment.
                </Typography>
                You agree not to make demands for treatment from a Medical
                Expert on Tova Medical.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Care.
                </Typography>
                Medical care (including Virtual Consults with Medical Experts,
                prescriptions, diagnosis, or treatment recommendations) is
                available on Tova Medical, and you agree that such care is
                limited to medically necessary and appropriate care, as
                determined by the treating Medical Expert.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Care Limitations.
                </Typography>
                You may use Virtual Consults regarding discrete and episodic
                medical issues. Examples of inappropriate uses of Virtual
                Consults are (but are not limited to) use of Virtual Consults to
                replace or obtain: emergency care; in-person routine physicals
                and vaccinations (except for vaccines that can be ordered for
                you to get at a pharmacy); health verifications or disability
                forms that require an in-person exam; in-person tests or
                screenings, such as Pap smears, breast exams, prostate exams,
                and strep throat swabs; lifestyle treatments, such as medication
                for smoking cessation, losing weight, stopping addiction, and
                erectile dysfunction; and ongoing care of chronic conditions
                like diabetes, high blood pressure, arthritis, anxiety,
                depression, epilepsy, lupus, and chronic pain (except for
                medical advice of a limited nature); as well as falsifying your
                location, doctor-shopping or otherwise repeating consults for
                the same issue. We reserve the right to review your usage and to
                suspend or terminate your Tova Medical account if we find that
                you have misused Virtual Consults. Our conclusions based on
                those reviews are final.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Prescriptions and Lab Tests.
                </Typography>
                You agree that you do not have a right to expect, demand, or to
                receive any particular prescription or lab test when using Tova
                Medical. Prescriptions and lab tests, where available, are
                provided at the sole discretion of the consulting Medical
                Expert. Patients who are located outside the U.S. may not
                receive lab orders and may receive prescription recommendations
                only.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Prescriptions and Lab Tests Limitations.
                </Typography>
                You understand and agree that not all prescriptions and lab
                tests are available using Tova Medical. The following medication
                categories cannot be prescribed or recommended in Virtual
                Consults on Tova Medical: DEA category I-IV medications,
                lifestyle medications (such as weight loss or hair loss
                medication), and U.S.-state regulated medications. Lab tests are
                currently ordered using Quest Diagnostics, and it may not be
                possible to order all of Quest Diagnostics lab tests (if any) in
                all U.S. states.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Duration of Treatment.
                </Typography>
                You agree that the duration of any treatment prescribed or
                recommended may be limited. It is up to the consulting Medical
                Expert to determine the appropriate duration of any treatment,
                if any, made available through a Virtual Consult. Where
                prescriptions are provided, they may be a short-course to hold
                you over until you are able to consult with your regular doctor.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Dress Code.
                </Typography>
                You agree to be appropriately dressed and to show personal or
                private body parts only when it is of medical relevance to do
                so.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Reservation of Rights.
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Rejection and Removal of content.
                </Typography>
                Subject to applicable law and any related limitations, we
                reserve the right to review any submission or Content on our
                Apps and Services, and to not post such Content or to remove or
                request the removal of any Content for any reason, at any time,
                without prior notice, at our sole discretion.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Termination.
                </Typography>
                We reserve the right to restrict or terminate access to our
                Content or Services by any individual who violates our Terms, or
                engages in any other conduct that, in our sole judgement,
                restricts or inhibits any other person from using or enjoying
                Tova Medical or exposes us or any of our members, partners or
                suppliers to any liability or detriment of any type.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Arbitration Agreement
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Summary.
            </Typography>
          </p>
          <p>
            Tova Medical's Terms require the use of arbitration to resolve
            disputes and also limits the remedies available to you in the event
            of a dispute. Most concerns can be resolved quickly by visiting the
            customer service help center at{" "}
            <NavLink to="/support">https://support.TovaMedical.com</NavLink>. In
            the unlikely event that we are unable to resolve a legitimate legal
            complaint, we each agree to resolve those disputes through binding
            arbitration or small claims instead of in courts of general
            jurisdiction. Arbitration is more informal than a lawsuit in court.
            Arbitration uses a neutral arbitrator instead of a judge or jury,
            allows for more limited discovery than in court, and is subject to
            very limited review by courts. Arbitrators can award the same
            damages and relief that a court can award. Any arbitration under
            this Agreement will take place on an individual basis; class
            arbitrations and class actions are not permitted. We request that
            you work with us in good faith to resolve any disputes for 30 days
            after notifying us of such issues before filing arbitration. You
            understand that any award available under arbitration is subject to
            the limitations in the Tova Medical TOUs. YOU UNDERSTAND AND AGREE
            THAT, BY ENTERING INTO THESE TERMS, YOU AND TOVA MEDICAL ARE EACH
            WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
            ACTION.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Agreement.
            </Typography>
          </p>
          <p>
            First things first: let's try to sort it out. We want to address
            your concerns without a formal arbitration or case. Before filing a
            claim against Tova Medical, you agree to make a good faith effort to
            try to resolve the dispute informally by contacting{" "}
            <NavLink to="mailto:Support@TovaMedical.com">
              Support@TovaMedical.com
            </NavLink>{" "}
            and responding promptly to any related communications. We'll try to
            resolve the dispute by contacting you via email. If a dispute is not
            resolved within 30 days of submission, you or Tova Medical may bring
            a formal proceeding.
          </p>
          <p>
            This Arbitration Agreement ("Arbitration Agreement") is a condition
            of the Terms of Use of Tova Medical and the Apps. The effective date
            of this agreement is August 30, 2014 (the "Effective Date").
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              General.
            </Typography>
          </p>
          <p>
            If you reside in the United States or are otherwise subject to the
            US Federal Arbitration Act, you agree that any and all disputes or
            claims that have arisen or may arise between us - except as set
            forth below ("Exceptions") - shall be resolved exclusively through
            final and binding arbitration, rather than in court. The Federal
            Arbitration Act governs the interpretation and enforcement of this
            Arbitration Agreement.
          </p>
          <p>
            Any arbitration proceedings shall be conducted by the American
            Arbitration Association ("AAA") under its rules and procedures
            applicable at that time, including the AAA's Supplementary
            Procedures for Consumer-Related Disputes (to the extent applicable),
            as modified by our Arbitration Agreement. You may review those rules
            and procedures, and obtain a form for initiating arbitration
            proceedings at the AAA's website. The AAA Rules and filing forms are
            available online at <a href="www.adr.org">www.adr.org</a>, by
            calling the AAA at 1-800-778-7879, or by contacting Tova Medical.
            The arbitration shall be held in Santa Clara County, California or
            at another mutually agreed location. If the reasonable value of the
            relief sought is US$10,000 or less, either of us may elect to have
            the arbitration conducted by telephone or based solely on written
            submissions, which election shall be binding on us subject to the
            arbitrator's discretion to require an in-person hearing. Attendance
            at an in-person hearing may be made by telephone by you and/or us,
            unless the arbitrator requires otherwise.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Exceptions.
            </Typography>
          </p>
          <p>
            Notwithstanding the foregoing, nothing in this Arbitration Agreement
            will be deemed to waive, preclude, or otherwise limit the right of
            either party to: (a) bring an individual action in small claims
            court; (b) pursue an enforcement action through the applicable
            federal, state, or local agency if that action is available; (c)
            seek injunctive relief in a court of law in aid of arbitration; or
            (d) to file suit in a court of law to address an intellectual
            property infringement claim.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Notice.
            </Typography>
          </p>
          <p>
            A party who intends to seek arbitration must first send to the
            other, by certified mail, a written Notice of Dispute ("Notice").
            The Notice to Tova Medical should be addressed to: General Counsel,
            Tova Medical, 954 PR-25, Suite 205, #10254, Puerto Rico, US ("Notice
            Address"). The Notice must (a) describe the nature and basis of the
            claim or dispute; and (b) set forth the specific relief sought and
            the specific applicable legal basis for the requested relief
            ("Demand"). The parties will make good faith efforts to resolve the
            claim directly, but if the parties do not reach an agreement to do
            so within 30 days after the Notice of Arbitration is received, you
            or Tova Medical may commence an arbitration proceeding. All
            arbitration proceedings between the parties will be confidential
            unless otherwise agreed by the parties in writing. During the
            arbitration, the amount of any settlement offer made by you or Tova
            Medical must not be disclosed to the arbitrator until after the
            arbitrator makes a final decision and award, if any.
          </p>
          <p>
            The arbitrator will decide the substance of all claims in accordance
            with the laws of the State of California, including recognized
            principles of equity, and will honor all claims of privilege
            recognized by law. The arbitrator shall not be bound by rulings in
            prior arbitrations involving different Tova Medical users, but is
            bound by rulings in prior arbitrations involving the same user to
            the extent required by applicable law. The arbitrator's award shall
            be final and binding and judgment on the award rendered by the
            arbitrator may be entered in any court having jurisdiction over the
            parties, except for a limited right of appeal under the Federal
            Arbitration Act.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Opt-Out.
            </Typography>
          </p>
          <p>
            If you do not wish to resolve disputes by binding arbitration, you
            may opt out of this Arbitration Agreement within 30 days after the
            date that you agree to the Terms by sending a letter to General
            Counsel, Tova Medical, Inc., 954 PR-25, Suite 205, #10254, Puerto
            Rico, US that specifies: your full legal name, the email address
            associated with your account on the Service, and a statement that
            you wish to opt out of arbitration ("Opt-Out Notice"). Once Tova
            Medical receives your Opt-Out Notice, this Arbitration Agreement
            will be void and any action arising out of the Terms will be
            resolved under the governing law and jurisdiction set forth in the
            Terms. The remaining provisions of the Terms will not be affected by
            your Opt-Out Notice.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Fees.
            </Typography>
          </p>
          <p>
            The AAA rules will govern the payment of all filing, administration
            and arbitrator fees, unless our Arbitration Agreement expressly
            provides otherwise. If the amount of any claim in an arbitration is
            US$5,000 or less, Tova Medical will pay all arbitrator fees
            associated with the arbitration, so long as: (a) you make a written
            request for such payment of fees and submit it to the AAA with your
            Demand for Arbitration; and (b) your claim is not determined by the
            arbitrator to be frivolous or without merit under Tova Medical's
            Terms of Use or otherwise ("Frivolous Claims"). In such case, we
            will make arrangements to pay all necessary fees directly to the
            AAA. If the amount of the claim exceeds US$10,000 and you are able
            to demonstrate that the costs of arbitration will be prohibitive as
            compared to the costs of litigation, Tova Medical will pay as much
            of the filing, administration, and arbitrator fees as the arbitrator
            deems necessary to prevent the arbitration from being
            cost-prohibitive. If the arbitrator determines the claim(s) you
            assert in the arbitration are Frivolous Claims, you agree to
            reimburse Tova Medical for all fees associated with the arbitration
            paid by Tova Medical. Tova Medical's obligations to pay these fees
            shall be made after a preliminary determination that the claims are
            not barred or limited by the Tova Medical Terms of Use. Bared or
            limited claims constitute Frivolous Claims.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS
            </Typography>
          </p>
          <p>
            YOU AND TOVA MEDICAL AGREE, AS PART OF THE ARBITRATION AGREEMENT,
            THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
            INDIVIDUAL BASIS AND NOT AS PART OF ANY PURPORTED CLASS OR
            REPRESENTATIVE ACTION OR PROCEEDING. WE REFER TO THIS AS THE
            "PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS". UNLESS BOTH YOU
            AND WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN
            YOUR OR OUR CLAIM WITH ANOTHER PERSON'S OR PARTY'S CLAIMS, AND MAY
            NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
            REPRESENTATIVE OR CLASS PROCEEDING. THE ARBITRATOR MAY ONLY AWARD
            RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) IN
            FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
            NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL
            CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER TOVA MEDICAL USERS.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Enforceability.
            </Typography>
          </p>
          <p>
            Except with respect to the Prohibition of Class and Representative
            Actions, if a court decides that any part of this Arbitration
            Agreement is invalid or unenforceable, the other parts of this
            Arbitration Agreement shall continue to apply. If a court decides
            that the Prohibition of Class and Representative Actions is invalid
            or unenforceable, then the entire Arbitration Agreement shall be
            null and void.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Modifications.
            </Typography>
          </p>
          <p>
            If Tova Medical makes any future change to this Arbitration
            Agreement, other than a change to Tova Medical's Notice Address, you
            may reject the change by sending us written notice within 30 days of
            the change to the Notice Address, in which case your account with
            Tova Medical will be immediately terminated and this arbitration
            provision, as in effect immediately prior to the changes you
            rejected will survive.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              CLAIMS ARE TIME-BARRED
            </Typography>
          </p>
          <p>
            You agree that regardless of any statute or law to the contrary or
            the applicable dispute resolution process, any claim or cause of
            action you may have arising out of or related to use of the Service
            or otherwise under these must be filed within one (1) year after
            such claim or cause of action arose or you hereby agree to be
            forever barred from bringing such claims.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Additional Terms: Virtual Consults
            </Typography>
          </p>
          <p>
            THE FOLLOWING TERMS APPLY TO YOUR USE OF VIRTUAL CONSULTS PERFORMED
            BY VIDEO, AUDIO (VOICE), OR TEXT CHAT, OR BY ASYNCHRONOUS TEXT INBOX
            CONSULTS (COLLECTIVELY, "VIRTUAL CONSULTS"). BY USING VIRTUAL
            CONSULTS, YOU REPRESENT THAT YOU UNDERSTAND AND AGREE TO ALL OF THE
            FOLLOWING IN ADDITION TO OUR BASIC TERMS OF USE.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Understandings, Agreements, and Representations.
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Medical Agreement.
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                You are entering into an agreement with Tova Medical Medical
                Group, P.C., which shall be a provider of professional medical
                services to you through the Tova Medical Apps.
              </p>
            </li>
            <li>
              <p>
                You are entering into a physician - patient relationship with
                the Medical Expert associated with Tova Medical Medical Group,
                P.C. that personally performs the Virtual Consult for you.
              </p>
            </li>
            <li>
              <p>
                You agree to use your legal name and real medical information in
                Virtual Consults.
              </p>
            </li>
            <li>
              <p>
                You agree to use only one Tova Medical account for maintaining
                your medical records and for doing Virtual Consults.
              </p>
            </li>
            <li>
              <p>
                You represent and warrant to Tova Medical Medical Group, P.C.
                that you have a primary care physician.
              </p>
            </li>
            <li>
              <p>
                You understand and agree that the physicians associated with
                Tova Medical Medical Group, P.C. are only providing minor
                non-emergency primary-care medical services with respect to
                Virtual Consults.
              </p>
            </li>
            <li>
              <p>
                You understand that you should never delay seeking advice from
                you primary care physician or other health professionals due to
                information provided by a Healthcare Provider through Tova
                Medical. You agree to seek emergency help when needed, and to
                continue to consult with your primary care physician as
                recommended by Medical Experts on Tova Medical and by your
                primary care physician.
              </p>
            </li>
            <li>
              <p>
                Tova Medical, LLC. is the provider of certain administrative
                services to Tova Medical Medical Group, P.C. and does not
                provide professional medical services.
              </p>
            </li>
            <li>
              <p>
                You agree that physicians associated with Tova Medical Medical
                Group, P.C., in performing a Virtual Consult, may not prescribe
                for you, the following drugs:
              </p>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    prescriptions for narcotics or DEA (Drug Enforcement
                    Administration) ({" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="http://www.deadiversion.usdoj.gov/schedules/"
                    >
                      http://www.deadiversion.usdoj.gov/schedules/
                    </a>
                    ) controlled substances (Schedule I, II, III, IV);
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    prescriptions for medications that are restricted by states;
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    prescriptions for medications for psychiatric illnesses; or
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    prescriptions for lifestyle medications such as erectile
                    dysfunction or diet drugs.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Tova Medical is a company, not a doctor, and does not practice
                medicine and does not participate in or interfere with the
                practice of medicine by Medical Experts on Tova Medical, each of
                whom is responsible for his or her services and compliance with
                the requirements applicable to his or her profession and
                license.
              </p>
            </li>
            <li>
              <p>
                You understand that if your medical condition warrants emergency
                help, the Medical Experts associated with Tova Medical Medical
                Group, P.C. will direct you to the nearest local hospital
                emergency department or emergency room.
              </p>
            </li>
            <li>
              <p>
                You understand that the services offered by Tova Medical Medical
                Group, P.C. and Tova Medical, LLC. are not health insurance and
                are not a substitute for health insurance. You agree not to use
                such services as health insurance. Virtual Consults are designed
                for use independent of health insurance as an out-of-pocket cost
                service, and unless otherwise expressly stated, associated fees
                may not qualify for insurance or HSA or similar reimbursement.
              </p>
            </li>
            <li>
              <p>
                You agree to refrain from excessive or inappropriate use of
                Virtual Consults, including but not limited to multiple Virtual
                Consults for the same issue without a valid medical reason,
                including but not limited to: initiating a subsequent Virtual
                Consult before you have received the Summary Notes from the
                doctor of the prior Virtual Consult; attempts to obtain
                prescriptions unavailable through Tova Medical Premium Services
                or not previously prescribed to you in a prior Virtual Consult
                for the same issue; multiple Virtual Consults for a symptom or
                condition when you have been advised to seek in-person care; or
                any other behavior deemed by Tova Medical or a doctor to be
                excessive or inappropriate.
              </p>
            </li>
            <li>
              <p>
                Other than for mutually agreed upon in-person care with a
                Medical Expert on Tova Medical, you agree to refrain from
                contacting or seeking to contact a Medical Expert for
                telemedicine care outside of the platform (such as by phone,
                email, or other messaging system). This protects both patients
                and Medical Experts and ensures clinical care is delivered in a
                reliable, continuous, and controlled platform. Tova Medical is
                not responsible for any interactions with Medical Experts not
                conducted on the Tova Medical platform.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Health Information.
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                You agree to the entry of your medical records into the Tova
                Medical Medical Group, P.C. secure computer database and
                understand that all reasonable measures have been taken to
                safeguard your medical information, in accordance with federal
                HIPAA standards, as amended to date. You acknowledge that no
                computer or phone system is totally secure. Tova Medical Medical
                Group, P.C. recognizes your privacy and, in accordance with our
                Privacy Statement, will not release information to anyone
                without your written authorization or as required by law.
              </p>
            </li>
            <li>
              <p>
                Tova Medical maintains protected health information (PHI) in
                compliance with federal privacy and security rules (HIPAA and
                HITECH). Our contractual obligations with health care providers
                are as a Business Associate of health care providers who are
                Covered Entities such rules. Tova Medical collects information
                for the purposes of providing our Services (including Virtual
                Consults), marketing and promoting our Services to you, and for
                market research data.
              </p>
            </li>
            <li>
              <p>
                You understand and agree that Virtual Consults involve the
                communication of your medical information, both orally and
                visually, to Medical Experts and other health care practitioners
                located in other parts of the state/jurisdiction or outside of
                the state/jurisdiction in which you are located at the time of a
                Virtual Consult and afterward.
              </p>
            </li>
            <li>
              <p>
                You also understand that Tova Medical, LLC. a "Covered Entity"
                as defined under the Health Insurance Portability and
                Accountability Act and associated regulations. However, you
                understand that Tova Medical Medical Group, P.C. and the Medical
                Experts associated with Tova Medical Medical Group, P.C. are
                "Covered Entities" that are subject to the provisions of HIPAA
                pursuant to 45 CFR 103. Therefore, you understand and agree that
                your personally identifiable health information ("Health
                Information") provided to Tova Medical Medical Group, P.C. and
                the Medical Expert associated with Tova Medical Medical Group,
                P.C. is subject to or protected by HIPAA. We will maintain the
                privacy of your Health Information as required by HIPAA.
              </p>
            </li>
            <li>
              <p>
                You also understand and agree that by using Tova Medical we may
                automatically send information related to your Virtual
                Consult(s), including related reminders, to you, and you opt in
                to receiving this information by email, mobile notification, or
                using other contact information provided by you in your account
                settings and profile information. You may opt out of receiving
                any such communication via email or mobile notification at any
                time through your account notification settings. If you prefer
                not to receive or do not consent to the receipt of personal
                health information by email or mobile notification, you agree to
                update your account notification settings before using Virtual
                Consults or any features utilizing reminders.
              </p>
            </li>
            <li>
              <p>
                You represent and warrant to us that, to the extent you enter
                "protected health information" (as such term is defined under
                HIPAA) for a third person, you are legally authorized to do so
                on behalf of such third person, and such third person has
                validly consented to your inputting of, and your review of
                "protected health information" of such third person on Tova
                Medical and via the Tova Medical website and mobile apps.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Consult Quality.
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                Virtual Consults are primary care consults, not specialist
                consults, and are not intended to address medical concerns
                typically addressed by medical specialists.
              </p>
            </li>
            <li>
              <p>
                You agree that Medical Experts performing Virtual Consults may
                prescribe allowed medications in such Virtual Consults when in
                the Medical Expert's sole judgement it is medically appropriate
                to do so. You agree that you are not guaranteed any prescription
                in a Virtual Consult. The determination that a medical concern
                warrants a prescription is always made at the discretion of the
                consulting Medical Expert in a Virtual Consult and not prior to
                such a consult. You agree to maintain up-to-date medication
                information in your Health Profile on Tova Medical so that
                consulting Medical Experts may know your medication history.
              </p>
            </li>
            <li>
              <p>
                You understand and agree that Tova Medical and Medical Experts
                on Tova Medical are not responsible for disconnections or
                connection quality issues you may experience during or outside
                of Virtual Consults as a result of your mobile device's or
                computer's internet connectivity.
              </p>
            </li>
            <li>
              <p>
                You agree to refrain from abusive language or engaging in
                inappropriate behavior with Medical Experts during a Virtual
                Consult and agree that a Medical Expert may terminate a Virtual
                Consult at any time should inappropriate behavior or language be
                used, or if in the Medical Expert's sole judgment, the Virtual
                Consult is no longer appropriate or productive.
              </p>
            </li>
            <li>
              <p>
                Tova Medical's Premium Plan subscriptions and Virtual Consult
                fees do not include nor cover the costs of any recommendations
                or treatments associated with any Virtual Consult (including but
                not limited to: prescriptions, lab tests, services or
                treatments, devices, or referrals for non-Tova Medical
                consults).
              </p>
            </li>
            <li>
              <p>
                24/7 Doctor Visits offer primary-care consults from Medical
                Experts only via the Tova Medical Apps. Specialist care is not
                available. Services are provided under an "on call" model where
                you will be connected with the first-available licensed Tova
                Medical Medical Expert. If you select a specific doctor
                (including specialists) for a 24/7 Doctor Visit, this may
                subject to an additional per-consult fee. Tova Medical's Premium
                Plan may not cover the cost of 24/7 Doctor Visits that
                originated from searching for a specific doctor or specialty.
                Pricing will be shown prior to initiating such 24/7 Doctor Visit
                and by participating in such a 24/7 Doctor Visit you agree to
                pay the fees associated with such a consult.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Your Rights.
            </Typography>
          </p>
          <p>
            You have all the following rights with respect to Virtual Consults:
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Free Choice.
                </Typography>
                I have the right to withhold or withdraw my consent to Virtual
                Consults at any time without affecting my right to future care
                or treatment.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Access to Information.
                </Typography>
                I have the right to inspect all medical information transmitted
                during a Virtual Consult, including, without limitation, the
                name of the licensed Medical Expert associated with Tova Medical
                Medical Group, P.C. treating me, and may receive copies of this
                information for a reasonable fee.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Confidentiality.
                </Typography>
                I understand that the laws that protect the confidentiality of
                medical information apply to Virtual Consults, and that no
                information or images from such interaction which identifies me
                will be disclosed to other entities without my consent.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Risks.
                </Typography>
                I understand that there are risks from Virtual Consults,
                including the following: (i) loss of records from failure of
                electronic equipment; (ii) power or other technical failures
                with loss of communication; and (iii) invasion of electronic
                records by outsiders (hackers). Finally, I understand that it is
                impossible to list every possible risk, that my condition may
                not be cured or improved, and in rare cases, may get worse.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Benefits.
                </Typography>
                I understand that I can expect the following benefits from
                Virtual Consults, but that no results can be guaranteed or
                assured: (i) reduced visit time; (ii) rapid innovation of
                treatments; and (iii) focused information.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Follow-up.
                </Typography>
                In the event that the diagnosis and treatment by a Medical
                Expert associated with Tova Medical Medical Group, P.C. does not
                resolve the medical issue for which I sought a 24/7 Doctor
                Visit, I agree to consult with my primary care physician in
                person for follow-up treatment, and/or seek treatment, if
                necessary at a local hospital emergency department.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Consequences.
                </Typography>
                I understand that, by having my consent to live Virtual Consults
                performed by video or telephone or asynchronous inbox consults,
                my Medical Expert associated with Tova Medical Medical Group,
                P.C. may communicate medical information concerning me to
                physicians and other health care practitioners located in other
                parts of the state/jurisdiction or outside the
                state/jurisdiction.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Quality Assurance.
                </Typography>
                I understand that records related to these consults may be
                reviewed under a Tova Medical quality assurance program. Records
                of Tova Medical Quality Assurance Committee records subject to
                confidentiality.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Your Obligations
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Privacy Statement and Cookie Policy.
                </Typography>
                You agree to Tova Medical's{" "}
                <NavLink to="/privacy">Privacy Statement</NavLink>and{" "}
                <NavLink to="/cookies">Cookie Policy</NavLink>, the terms of
                which are incorporated herein by reference.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  True and Accurate Information.
                </Typography>
                You agree that all information you provide on Tova Medical
                Premium Services relates to you and is current, complete, and
                accurate. Additionally, you agree to update and maintain such
                information on Tova Medical Premium Services to ensure that it
                is true, accurate, current and complete at all times.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Payment.
                </Typography>
                If you are using Tova Medical as an individual and you purchase
                access to Tova Medical's Premium Plan or paid Services, you
                agree to the Tova Medical Payment Agreement available at{" "}
                <NavLink to="/terms/payment-agreement/">
                  https://www.TovaMedical.com/terms/payment-agreement/
                </NavLink>
                and to pay all fees or charges to your account in accordance
                with the fees, charges, and billing terms in effect at the time
                a fee or charge is due and payable. Enterprise users are subject
                to the Billing Agreement available at{" "}
                <NavLink to="/payg-agreement/">
                  https://www.TovaMedical.com/payg-agreement/
                </NavLink>
                .
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Emergency Help.
                </Typography>
                You will agree to seek emergency help when needed or as
                recommended by Medical Experts you consult with. You agree to
                continue to consult with your primary care doctor as recommended
                by your primary care doctor or the Medical Experts you consult
                with.
              </p>
            </li>
          </ul>
          <p></p>
          <p style={{ marginTop: "150px" }}></p>
          <Typography
            align="center"
            className={classes.mainHeading}
            variant="h1"
          >
            Terms of Use (Canada)
          </Typography>
          <p>
            <Typography
              align="center"
              className={classes.smallHeading}
              variant="h3"
            >
              Tova Medical Terms of Use (Canada)
            </Typography>
          </p>
          <Typography align="center">
            Date last modified:March 10, 2020
          </Typography>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              What is this document?
            </Typography>
          </p>
          <p>
            This Terms of Use (or "TOU") is an agreement between you and Tova
            Medical, Inc., together with its affiliates and partners ("us",
            "we", or "Tova Medical"). There are rules you agree to follow when
            using our mobile applications and website(s) (the "Apps"), including
            when you ask questions and when you view or input content on or into
            the Apps. You must also agree to the following terms to utilize any
            of the Apps and Services provided by Tova Medical:
          </p>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              <p>
                The Tova Medical{" "}
                <NavLink to="/privacy">Privacy Statement</NavLink>and Tova
                Medical <NavLink to="/cookies">Cookie Policy</NavLink>govern all
                privacy and data use-related aspects of your use of our Apps;
              </p>
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              <p>
                The Apps access the Tova Medical platform, Content and Services
                provided thereby, and the following govern use of the Tova
                Medical platform, Content and Services provided thereby: the
                Tova Medical <NavLink to="/terms/">Terms of Use</NavLink>, Tova
                Medical <NavLink to="/privacy/">Privacy Statement</NavLink>and
                the Tova Medical <NavLink to="/cookies">Cookie Policy</NavLink>.
              </p>
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              <p>
                Tova Medical's{" "}
                <NavLink to="/terms/#codeVirtualConsult">
                  Code of Conduct for Virtual Consults
                </NavLink>
                ,{" "}
                <NavLink to="/terms/#additionalVirtualConsult">
                  Additional Terms: Virtual Consults
                </NavLink>
                and{" "}
                <NavLink to="/terms/#virtualConsultConsent">
                  Virtual Consult Consent
                </NavLink>
                govern your use of Tova Medical for Virtual Consults.
              </p>
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              <p>
                The Tova Medical{" "}
                <NavLink to="/terms/#submissionsMembers">
                  Member Submission Terms and Policies
                </NavLink>
                govern submissions provided to us.
              </p>
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              <p>
                The Tova Medical{" "}
                <NavLink to="/terms/#submissionsExperts">
                  Expert Submissions Terms and Policies
                </NavLink>
                and{" "}
                <NavLink to="/terms/#addtionalTermsExpert">
                  Additional Terms that Apply to Experts
                </NavLink>
                govern submissions provided by Medical Experts.
              </p>
            </li>
          </ol>
          <p>
            While these are separate documents, they are each and all
            incorporated in full into this TOU.
          </p>
          <p>
            IMPORTANT! YOUR ACCESS TO THIS WEBSITE IS SUBJECT TO LEGALLY BINDING
            TERMS AND CONDITIONS. CAREFULLY READ ALL OF THE FOLLOWING TERMS AND
            CONDITIONS BEFORE PROCEEDING. ACCESSING THE APPS IS THE EQUIVALENT
            OF YOUR SIGNATURE AND INDICATES YOUR ACCEPTANCE OF THESE TERMS AND
            CONDITIONS AND THAT YOU INTEND TO BE LEGALLY BOUND BY THEM. IF YOU
            DO NOT AGREE WITH THESE TERMS AND CONDITIONS YOU MUST EXIT THE APPS.
          </p>
          <p>
            When you sign up for any Tova Medical App, Services and/or Virtual
            Consults, you verify that you've read and agree to this TOU when
            using the Apps, Services and/or Virtual Consults.
          </p>
          <p>
            THE TOVA MEDICAL APPS, SERVICES AND VIRTUAL CONSULTS ARE NOT
            SUBSTITUTES FOR ADVICE OR MEDICAL CARE FROM YOUR DOCTOR OR YOUR
            HEALTHCARE PROVIDER.
          </p>
          <p>
            CALL 911 (OR YOUR LOCAL EMERGENCY SERVICE) OR YOUR DOCTOR
            IMMEDIATELY IF YOU BELIEVE YOU MAY OR DO HAVE A MEDICAL EMERGENCY.
          </p>
          <p>
            THE TOVA MEDICAL APPS, SERVICES OR VIRTUAL CONSULTS ARE INTENDED FOR
            USE BY CANADIAN RESIDENTS.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Definitions
            </Typography>
          </p>
          <p>
            To clarify what this document means, certain words with capital
            letters will be defined. Here are the definitions for this
            agreement:
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Apps
                </Typography>
                means <NavLink to="">https://www.TovaMedical.com</NavLink>and
                related websites and Tova Medical's mobile applications we
                release.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  "Content"
                </Typography>
                means text, graphics, images and any other material entered,
                processed, contained on or accessed through the Apps or
                Services, including Content created, modified, validated or
                submitted by Medical Experts.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  "Tova Medical"
                </Typography>
                means Tova Medical Inc. and any and all entities which are
                controlled by Tova Medical or by subsidiaries of Tova Medical.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  "Medical Expert"
                </Typography>
                means an individual accepted to participate in Tova Medical's
                Medical Expert community and who participates on the Apps and
                interacts with others on or through the App or third party
                licensor's apps, or other specialists or professionals who
                participate on either of the Apps in their capacity as experts.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  "Services"
                </Typography>
                means services provided through the Apps, but not Virtual
                Consults.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  "Virtual Consult"
                </Typography>
                means an on demand telemedicine consultation between a Tova
                Medical Practitioner and a patient through the Apps using video,
                audio and/or text chat.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  "Tova Medical Practitioner"
                </Typography>
                means a licensed physician, nurse practitioner or other
                qualified health care practitioner) who has been contracted to
                provide Virtual Consults through the App and/or the Services.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Introduction
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical
            </Typography>
          </p>
          <p>
            Tova Medical Apps, Services and Virtual Consults connect you with
            trusted health information, answers, and tips from leading Medical
            Experts, and access to Virtual Consults with Tova Medical
            Practitioners; all designed to attempt to help you feel good and
            live a happier, healthier life. Tova Medical Services and Virtual
            Consults are different services but can be available through the
            same Apps. Additional terms of use apply to Virtual Consults. Tova
            Medical has partnered with third parties that license its software
            platform to their services, including telemedicine platforms
            accessible via apps of such third parties, and content and services
            that support such apps. To use Tova Medical Apps, Services and/or
            Virtual Consults as provided under license by such third parties you
            must agree to all of the terms and policies such third parties
            impose upon such Apps, Services and/or Virtual Consults, and such
            third parties bear all liabilities for your use of the Apps,
            Services and/or Virtual Consults.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Apps and Services do not provide medical care or
              treatment
            </Typography>
          </p>
          <p>
            THE TOVA MEDICAL APPS, CONTENT AND SERVICES ARE NOT INTENDED FOR
            MEDICAL DIAGNOSIS, TREATMENT, PRESCRIPTIONS OR OTHER MEDICAL
            SERVICES. ALL INFORMATION PROVIDED ON THE TOVA MEDICAL APPS, CONTENT
            AND SERVICES OR IN CONNECTION WITH ANY COMMUNICATIONS SUPPORTED BY
            TOVA MEDICAL, INCLUDING BUT NOT LIMITED TO COMMUNICATIONS WITH
            MEDICAL EXPERTS, IS INTENDED TO BE FOR GENERAL INFORMATIONAL
            PURPOSES ONLY, AND IS IN NO WAY INTENDED TO CREATE A PHYSICIAN -
            PATIENT RELATIONSHIP AS DEFINED BY PROVINCIAL AND TERRITORIAL LAWS;
            THE TOVA MEDICAL APPS, CONTENT AND SERVICES ARE NOT A SUBSTITUTE FOR
            PROFESSIONAL MEDICAL DIAGNOSIS OR TREATMENT; AND RELIANCE ON ANY
            INFORMATION OR CONTENT PROVIDED THROUGH THE TOVA MEDICAL APPS,
            CONTENT AND/OR SERVICES OR ANY MEDICAL EXPERTS IS SOLELY AT YOUR OWN
            RISK.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Virtual Consults may be used for limited medical care
              (as appropriate)
            </Typography>
          </p>
          <p>
            Virtual Consults are medical services provided by Tova Medical
            Practitioners. A Tova Medical Practitioner may provide an
            assessment, offer a diagnosis, order tests and prescribe treatment,
            including prescriptions, as appropriate. Virtual Consults are not a
            substitute for having your own family doctor or primary care
            provider provide care to you, or for in-person health care
            interactions. You agree not to use Virtual Consults as a substitute
            for your own family doctor or primary care provider or in-person
            doctor visits. You understand and agree that prescriptions for
            medications through Virtual Consults are limited in type and
            duration, and Tova Medical Practitioners will not prescribe any
            medications that are outside those permitted via Virtual Consults.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              This TOU is an Agreement
            </Typography>
          </p>
          <p>
            This TOU governs your use of the Apps. Other terms may apply to your
            use of specific features or parts of our Apps. If there is a
            conflict between this TOU and Tova Medical terms posted for a
            specific feature or part of the Apps, Services or Virtual Consults,
            the posted terms apply to your use of that feature or part of the
            Apps, Services or Virtual Consults.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We May Update the TOU
            </Typography>
          </p>
          <p>
            This TOU may change, or otherwise be modified or altered. We will
            provide notice of any changes to the TOU on the Apps. Because your
            use of the Tova Medical Apps, Services or Virtual Consults means you
            agree to this TOU, if we revise and update this TOU, your continued
            use of the Tova Medical Apps, Services or Virtual Consults will mean
            that you accept those changes. You choose whether or not you use
            Tova Medical Apps, Services or Virtual Consults. If you do not agree
            to all of the terms in this TOU or any changed, modified or altered
            version of the TOU, you must not use any Tova Medical Apps, Services
            or Virtual Consults.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              What we do and what we don't do (Tova Medical Apps and Services
              and Virtual Consults)
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Apps and Services
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Do Support Your Health Decisions (Tova Medical Apps and
              Services)
            </Typography>
          </p>
          <p>
            The Tova Medical Apps and Services are designed to support the
            health decisions and choices that you make. These decisions and
            choices are yours, and we believe that you are the best decision
            maker about your health and that these decisions should be made in
            connection with the advice you receive within a formal
            doctor-patient relationship. Always use common sense when making
            health decisions. Tova Medical cannot make decisions for you. We can
            help you find good health information and, where available and
            appropriate, help you to connect with a Tova Medical Practitioner
            for a Virtual Consult or referral for in-person care.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Don't Practice Medicine (Tova Medical Apps and Services)
            </Typography>
          </p>
          <p>
            On Tova Medical Apps and Services you can ask questions and find
            information and related educational answers provided by Medical
            Experts. Tova Medical Apps and Services are not a place for the
            practice of medicine, but Content on Tova Medical can be a resource
            for reliable, relevant general health information. We hope these
            resources will be of value to you. Please use them responsibly.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Don't Create Doctor-Patient Relationships (Tova Medical Apps
              and Services)
            </Typography>
          </p>
          <p>
            The use of any Tova Medical Apps or Services does not create a
            doctor-patient relationship. Tova Medical Apps, Content and Services
            are provided for your information, and should be used for
            informational purposes only. Tova Medical Apps, Content and Services
            are not a substitute for medical advice, diagnosis, treatment, or
            care from your physician or other primary care provider.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Aren't Your Doctor (Tova Medical Apps and Services)
            </Typography>
          </p>
          <p>
            Whenever we use the words "your physician" or "your doctor" or
            "health care provider" or similar words on Tova Medical Apps, and
            Services or terms relating thereto, including in this TOU, we mean
            your personal doctor with whom you have an actual, mutually
            acknowledged, doctor-patient relationship, or your other health care
            providers with whom you have the same kind of formal, real-world
            relationship, within Canada. Medical Experts on Tova Medical are not
            "your" doctor, physician, or healthcare provider.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Don't Provide Personal Diagnosis, Treatment or Prescriptions
              (Tova Medical Apps and Services)
            </Typography>
          </p>
          <p>
            Tova Medical Apps, Content and Services do not constitute the
            practice of medicine or the provision of health care and cannot be
            used for the purpose of medical diagnosis, for a physical
            examination, for obtaining prescriptions, or for treatment. Do not
            use Tova Medical Apps, Content or Services to try to diagnose or
            treat a medical condition yourself.
          </p>
          <p>
            Use Virtual Consults (where appropriate and permitted) for temporary
            or limited medical care, or see your doctor or your healthcare
            provider in person if you are looking for a personal medical
            assessment, evaluation, diagnosis, or prescription.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Virtual Consults
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Do Provide Access to Tova Medical Practitioners for Virtual
              Consults
            </Typography>
          </p>
          <p>
            You can obtain access to a Tova Medical Practitioner for a Virtual
            Consult if: (i) you are located in a province or territory where
            such service is available; and (ii) you have agreed to this TOU,
            including the Tova Medical Virtual Consult Consent.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Do Support Practitioner-Patient Relationships through Virtual
              Consults
            </Typography>
          </p>
          <p>
            A time-limited practitioner-patient relationship is created when
            participate in a Virtual Consult. When you participate in a Virtual
            Consult through the Apps, you understand and agree that the Tova
            Medical Practitioner who provides the Virtual Consult is not your
            doctor or your health care provider. You agree not to use Virtual
            Consults as a substitute for interactions with your doctor or your
            health care provider.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We're Not For Use in Emergencies
            </Typography>
          </p>
          <p>
            Never use Tova Medical Apps, Content or Services or Virtual Consults
            in a potential or actual medical emergency. If there is a potential
            or actual medical emergency, seek immediate emergency assistance.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Remember...
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Always consult your doctor
            </Typography>
          </p>
          <p>
            Your doctor or your healthcare provider knows you and your medical
            history. Consult your doctor or your healthcare provider if you have
            any questions about a symptom or a medical condition, before
            starting or stopping any treatment directed by your doctor or your
            healthcare provider or before taking any drug or changing your diet.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Always...
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                SEEK THE ADVICE OF YOUR DOCTOR OR YOUR OTHER QUALIFIED
                HEALTHCARE PROVIDER WHENEVER YOU HAVE A PERSONAL QUESTION ABOUT
                A MEDICAL CONDITION OR SYMPTOM.
              </p>
            </li>
            <li>
              <p>
                NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE, OR DELAY SEEKING
                MEDICAL ADVICE OR TREATMENT BECAUSE OF ANY CONTENT YOU READ OR
                LEARN ON THE TOVA MEDICAL APPS OR SERVICES OR INFORMATION
                RECEIVED THROUGH A VIRTUAL CONSULT.
              </p>
            </li>
            <li>
              <p>
                CALL 911 (OR YOUR LOCAL EMERGENCY SERVICE) OR YOUR DOCTOR
                IMMEDIATELY IF YOU BELIEVE YOU MAY OR DO HAVE A MEDICAL
                EMERGENCY.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Medical Experts
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Independence of Medical Experts
            </Typography>
          </p>
          <p>
            Content provided by Medical Experts is licensed by Tova Medical from
            such Medical Experts, and sublicensed to you. Medical Experts and
            professionals or specialists utilizing or featured on Tova Medical
            are not employees or independent contractor service providers of
            Tova Medical. Any opinions, advice, or information expressed by any
            such individuals are those of the individual and the individual
            alone and they do not reflect the opinions of Tova Medical. Tova
            Medical does not recommend or endorse any specific Medical Experts,
            tests, physicians, products, procedures, opinions, or other
            information that may be mentioned on Tova Medical Apps, Content or
            Services.
          </p>
          <p>
            The inclusion of Medical Experts, professionals and specialists on
            Tova Medical Apps or Services or in any professional directory on
            Tova Medical Apps or Services does not imply recommendation or
            endorsement of such professional by Tova Medical, nor is such
            information intended as a tool for verifying the credentials,
            qualifications, or abilities of any professional contained therein.
            SUCH INFORMATION IS PROVIDED ON AN "AS-IS" BASIS AND TOVA MEDICAL
            DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING BUT
            NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
            FOR PARTICULAR PURPOSE. TOVA MEDICAL SHALL IN NO EVENT BE LIABLE TO
            YOU OR TO ANYONE FOR ANY DECISION MADE OR ACTION TAKEN BY ANY PARTY
            (INCLUDING, WITHOUT LIMITATION, ANY USER) IN RELIANCE ON INFORMATION
            ABOUT PROFESSIONALS AND SPECIALISTS ON TOVA MEDICAL'S APPS, CONTENT
            OR SERVICES. The use of Tova Medical Apps, Content or Services by
            any entity or individual to verify the credentials of professionals
            or specialists is prohibited.
          </p>
          <p>
            All opinions and statements expressed by Medical Experts on or
            through Tova Medical Apps, Content or Services are solely the
            individual and independent opinions and statements of such
            individuals and do not reflect the opinions of Tova Medical, its
            affiliates or any other organizations or institutions to which such
            Medical Expert or such specialist or professional is affiliated or
            provides services.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Children's Privacy
            </Typography>
          </p>
          <p>
            We are committed to protecting the privacy of children. Tova Medical
            Apps, Content and Services are not intended or designed for
            children. We do not collect personally identifiable information from
            any person we actually know is a child. Registered adult users of
            Tova Medical may use Tova Medical for the benefit of their children.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Your Account and Your Use of Tova Medical Apps and Services
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You are responsible for your account: keep your password and real
              name private.
            </Typography>
          </p>
          <p>
            Accurate and complete registration and account information is
            required to use Tova Medical Apps and Services. You are responsible
            for the security of your passwords and for any use of your account.
            Please immediately notify us of any unauthorized use of your
            password or account. Allowing any other person or entity to use your
            identity for posting on or using Tova Medical Apps, Content or
            Services is not permitted. Do not include your real name or any
            other information that could be used to identify you in anything you
            post that is publicly visible on Tova Medical Apps (such as in
            questions or other submissions that may be publicly displayed or
            accessed by the public).
          </p>
          <p>
            Full access to the Apps is enabled only by usernames and passwords.
            You are fully and solely responsible for any and all use of the
            Apps, Content and Services using your username and password. We
            reserve the right to revoke or deactivate your account, username
            and/or password at any time.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Governing Laws
            </Typography>
          </p>
          <p>
            Tova Medical is subject to applicable Canadian federal and
            provincial privacy laws, as well as US privacy laws. Your personal
            information may be stored, transmitted to or accessed from the US,
            and therefore US privacy laws apply to the extent that your personal
            information is stored, transmitted to and or accessed from the US.
          </p>
          <p>
            Your use of the Tova Medical Apps, Content and Services must comply
            with all applicable federal, provincial and territorial laws,
            regulations, and ordinances.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You must be of the age of majority and an adult to use Tova
              Medical.
            </Typography>
          </p>
          <p>
            Currently, you must be at least the age of majority in your
            jurisdiction of residence to use the Tova Medical Apps or Services
            and/or to register for Virtual Consults. Virtual Consults are
            subject to Tova Medical's Virtual Consult Consent.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We'll send you notices and information.
            </Typography>
          </p>
          <p>
            For more details about when and how we can communicate with you,
            please consult our{" "}
            <NavLink to="/privacy">Privacy Statement</NavLink>.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Code of Conduct for Virtual Consults
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Etiquette
                </Typography>
                As a patient consulting with a Tova Medical Practitioner for a
                Virtual Consult, you agree to engage with the Practitioner in a
                respectful and appropriate manner. You agree to refrain from
                using inappropriate language and/or behaviour.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Treatment
                </Typography>
                You agree that you will not make demands for specific
                medications or treatment in a consultation. A Tova Medical
                Practitioner will undertake an assessment, including a physical
                examination (within the limits of virtual care) and medical
                history, and will determine if she or he can help you with your
                health concern through a Virtual Consult, and if so, provide
                treatment recommendations.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Abuse of Care
                </Typography>
                In receiving a Virtual Consult, you are entitled to receive
                medically necessary and appropriate care within the scope of the
                services offered. You agree not to abuse or overuse the access
                to Tova Medical Practitioners provided to you. Overuse includes,
                but is not limited to, requests for multiple opinions or
                consultations related to a single medical condition. Tova
                Medical Practitioners may discourage this practice and may be
                hesitant to provide an assessment to those patients who attempt
                to seek multiple consults. Tova Medical Practitioners have the
                right, to decline to provide a Virtual Consult and/or refer you
                for in-person care or emergency services if the nature of the
                Virtual Consult is not appropriate for virtual care or multiple
                consults are warranted.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Prescriptions and Lab Tests
                </Typography>
                Even if you believe you need a prescription or lab test, you
                should not expect a prescription or lab test to be ordered from
                any given Virtual Consult. The Tova Medical Practitioner may
                prescribe treatment or order lab tests for the condition
                discussed in a Virtual Consult for patients located in Canada.
                The following medication categories cannot be prescribed or
                recommended in Virtual Consults on Tova Medical: narcotics and
                controlled drugs and substances, lifestyle medications (such as
                weight loss or hair loss medication), and some psychiatric
                medications.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Duration of Treatment
                </Typography>
                There are cases when you may present with a condition that is
                long-standing for which you may have been taking a prescription
                for a long period of time. It is up to the Tova Medical
                Practitioner to determine whether to prescribe a specific
                medication for you, and for what duration. Since Tova Medical
                Practitioners do not have an established doctor-patient
                relationship with you, best practice will be for the
                Practitioner to prescribe (if at all) a short-course of
                medication to hold you over until you are able to consult with
                your regular doctor or health care professional.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Dress Code
                </Typography>
                You agree to be appropriately dressed and to show personal or
                private body parts only when requested and where it is of
                medical relevance to do so.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Content and Services
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Apps, Services and Virtual Consults are for personal
              use.
            </Typography>
          </p>
          <p>
            Tova Medical Apps, Services and Virtual Consults are services for
            individuals to use to support their health decisions. You may use
            Tova Medical Apps, Services and Virtual Consults for personal, but
            not for commercial, purposes in accordance with all applicable
            federal, provincial and local laws. Organizations, companies, and
            businesses may not become registered members or use our Apps,
            Services and Virtual Consults as entities or through individual
            members except with the prior written authorization of Tova Medical.
            The Content is licensed only for the personal, household, and
            educational use of a single individual. No commercial use or
            commercial redistribution of any Content is permitted. Any hyperlink
            or other re-display of the information on another website must
            display the Content full-screen and not within a "frame" on the
            linked or other site, unless expressly pre-approved and permitted by
            Tova Medical in writing. By using the Tova Medical Apps, Services or
            Virtual Consults, you agree not to harvest, scrape or otherwise
            collect any personal information about our members, Medical Experts
            or Tova Medical Practitioners for any reason, including but not
            limited to for sending unsolicited communications, including
            commercial electronic messages or "spam".
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Apps, Services and Virtual Consults must be used
              responsibly.
            </Typography>
          </p>
          <p>
            You agree to use the Tova Medical Apps, Services and Virtual
            Consults responsibly, and in a manner that is not: unlawful,
            threatening, harassing, abusive, defamatory, slanderous, libelous,
            harmful to minors, vulgar, gratuitously violent, obscene,
            pornographic, indecent, lewd, invasive of another's privacy, or
            racially, ethnically or otherwise offensive, hateful or abusive. You
            also agree not to intentionally overuse any Apps, Services or
            Virtual Consults, or use any Apps, Services or Virtual Consults for
            medically inappropriate purposes or other inappropriate purposes.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Apps, Services and Virtual Consults may not be used
              for illegal purposes.
            </Typography>
          </p>
          <p>
            The Tova Medical Apps, Content, Services and Virtual Consults may
            not be used for any illegal purpose. You may not access our
            networks, computers, or the Apps, Content, Services and Virtual
            Consults in any manner that could damage, disable, overburden, or
            impair them, or interfere with any other person's use and enjoyment.
            You may not attempt to gain unauthorized access to any Apps,
            Content, Services or Virtual Consults, other accounts, computer
            systems, or networks connected with Tova Medical, or the Apps,
            Content, Services or Virtual Consults. You may not use any automated
            means (such as a scraper) to access the Apps, Content, Services or
            Virtual Consults for any purpose. Such unauthorized access includes,
            but is not limited to, using another person's login credentials to
            access the Apps, Content, Services or Virtual Consults. Any attempt
            by any individual or entity to solicit login information of any
            other user of the Apps, Content, Services or Virtual Consults, any
            Tova Medical Practitioner or any Medical Expert, or to access the
            Tova Medical Apps, Services or Video Consult accounts is an express
            and direct violation of this TOU and of applicable law, including
            relevant privacy and security laws and laws prohibiting unfair or
            unethical business practices.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Apps, Content and Services do not make
              recommendations or endorsements.
            </Typography>
          </p>
          <p>
            We do not recommend or endorse any specific Content, Services,
            tests, doctors, products, procedures, opinions, or other information
            that may be mentioned on Tova Medical Apps, Content or Services.
            Tova Medical Apps, Content and Services are designed to be used with
            common sense, and in connection with the advice of your doctor or
            your healthcare provider. Reliance on Tova Medical Apps, Content and
            Services is solely at your own risk. Some Content and Services,
            including posts by Medical Experts, may be or have been provided by
            third parties directly and are not reviewed or certified by Tova
            Medical. We cannot guarantee that the Apps, Content and Services
            will help you achieve any specific goals or results. Tova Medical
            Content and Services, including as it relates to dietary supplements
            or other treatments or regimens has not been evaluated by Health
            Canada and is not intended to diagnose, treat, cure, or prevent any
            disease.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical does not guarantee the accuracy of Third Party
              Content.
            </Typography>
          </p>
          <p>
            Tova Medical has no editorial control over or responsibility for
            Content provided by third parties or users, and any opinions,
            statements, products, services or other information expressed or
            made available by third parties (including Medical Experts) or users
            on Tova Medical Apps, Content or Services are those of such third
            parties or users. We make no representations about the accuracy or
            reliability of any opinion, statement or other information provided
            by any third party or user, and do not represent or warrant that
            your use of the Apps, Content, Services or Virtual Consults
            displayed or referenced on Tova Medical Apps will not infringe
            rights of third parties not owned by or affiliated with Tova
            Medical.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We are not responsible for anything outside of Tova Medical Apps.
            </Typography>
          </p>
          <p>
            Third party input into Content, Services and Virtual Consults
            ("Third Party Input"), as well as any of the following in the Apps,
            Content, Services or Virtual Consults: links to other apps, websites
            or information, third party software, third party data, or other
            third party content (including opinions, claims, comments), online
            or offline, and related information (collectively with Third Party
            Input being "External Content and Services"), are outside of our
            control. We do not control, endorse, verify the truth or accuracy
            of, or review any External Content and Services, and we are not
            responsible for such External Content and Services, whether or not
            any link or reference thereto is incorporated in the Tova Medical
            Apps. The External Content and Services contained in any third party
            apps, websites, information, or software to which a link is provided
            in the Apps are those of the companies or individuals responsible
            for such External Content and Services and cannot be attributed to
            Tova Medical. We do not warrant, nor are we in any way responsible
            for, information, software, data, or privacy policies, or any terms
            of use related or pertaining to External Content and Services. By
            using the Apps, you expressly release Tova Medical from any and all
            liability arising from your use of any External Content and
            Services. Accordingly, when you leave the Apps or submit information
            to any External Content and Services accessible from the Apps, the
            terms and conditions, privacy policy and other policies relating to
            such External Content and Services will apply to you.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You are responsible for your use of our Mobile Features.
            </Typography>
          </p>
          <p>
            The software platforms available via the Apps (the "Tova Medical
            Platforms") offer features and services available to you via your
            mobile phone or other mobile device. These features and services may
            include the ability to upload content to the Tova Medical Platforms,
            receive messages from the Tova Medical Platforms, download
            applications to your mobile phone or access Tova Medical Platforms
            features (collectively, the "Mobile Features"). Standard or other
            messaging, data and other fees may be charged by your carrier for
            use of Mobile Features. Your carrier may prohibit or restrict
            certain Mobile Features, and certain Mobile Features may be
            incompatible with your carrier or mobile device. Contact your
            carrier with questions regarding these issues, as Tova Medical bears
            no responsibility for any such issues. As applicable, instructions
            regarding how to opt-out of Mobile Features will be disclosed in
            connection with Mobile Features by Tova Medical. The instructions
            will typically require you to text a keyword (such as "STOP,"
            "CANCEL," "END," "UNSUBSCRIBE," "QUIT," etc.) to the applicable
            shortcode for the Mobile Feature, or to change your profile settings
            inside the Apps. You agree to notify Tova Medical of any changes to
            your mobile number and update your account(s) on the Tova Medical
            Platforms to reflect this change. Tova Medical bears no
            responsibility whatsoever for any failure to provide any Mobile
            Features to you due to any error or other inaccuracy in your account
            or the mobile number you provide to us.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You agree that we may send you limited personal information by
              email, SMS and mobile telephone notification.
            </Typography>
          </p>
          <p>
            When you use action-oriented features on Tova Medical Apps and
            Services (including subscribing to a checklist or participating in a
            Virtual Consult), you agree that Tova Medical may send you
            automatically via e-mail, mobile telephone or other contact
            information provided by you in your account settings, content
            including but not limited to, reminders relating to health
            checklists or doctor recommended actions for you. The content in
            those reminders may contain your personal or personal health
            information, including content related to conditions, treatments,
            and medications. You understand and agree that by using any
            action-oriented feature, you are consenting to receiving your own
            personal health information by email, short message service (SMS) or
            mobile telephone notification, which is not secure. You also
            understand and agree that by receiving personal or personal health
            information by e-mail, SMS or mobile telephone notification, you
            will be charged by your wireless or internet provider and that such
            e-mails, SMS or mobile telephone notification may be generated by an
            automated dialing system. You control the utility, and the privacy
            of the reminders you receive. In your account settings, you can
            control what information is sent to you, and where it's sent, and
            you can unsubscribe or opt-out from receiving reminders via email,
            SMS or mobile telephone notification, or any of them at any time. If
            you prefer not to receive personal information by email or mobile
            notification, you agree to update your account notification
            settings.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Mandatory Removal of Content and Services
            </Typography>
          </p>
          <p>
            If you violate this TOU, your permission to use the Apps, Content,
            Services and Virtual Consults automatically terminates and you must
            immediately destroy any copies you have made of any portion of the
            Content. (For more information, see{" "}
            <NavLink to="/terms/#terminationCanada">"Termination"</NavLink>.)
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Use of Your Information
            </Typography>
          </p>
          <p>
            We only use data you share with Tova Medical as set forth in the
            Tova Medical <NavLink to="/privacy">Privacy Statement</NavLink>,
            your account settings, and in accordance with applicable law.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We cannot control external communications (including email, SMS,
              and notifications).
            </Typography>
          </p>
          <p>
            Email, SMS, text message communications and mobile push
            notifications from the Apps are not encrypted. You can opt out of
            receiving email, SMS, text messages, and mobile push notifications.
            Although unlikely, it is possible for these communications to be
            intercepted or accessed without your authorization, and by using the
            Apps, you release Tova Medical from any liability arising from or
            related to any such interception or unauthorized access.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Advertising
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We Do Not Advertise.
            </Typography>
          </p>
          <p>There is no paid advertising on Tova Medical.</p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Intellectual Property Rights
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical owns or has rights to the Content and Services.
            </Typography>
          </p>
          <p>
            Tova Medical has all rights to permit its approved Tova Medical
            members to use or access the Apps in Canada. When you use the Apps,
            you do so in accordance with a license granted to you by Tova
            Medical that is a personal, revocable, non-assignable, and
            non-exclusive license right to access and use the Apps and Services
            in accordance with this TOU for the time period during which such
            Apps and Services are made available to you by Tova Medical, solely
            for your personal use. This means that you may not use any of the
            Apps, Content, Services or Virtual Consults for any commercial
            purpose, that we can take away your right to use any of the Apps,
            Services or Virtual Consults at any time and at our sole discretion,
            that you cannot give this license away to someone else, and that
            other Tova Medical Apps and Services members, user and account
            holders can use our Apps, Content, Services and Virtual Consults as
            permitted by Tova Medical. All right, title and interest in and to
            the Apps, Services, and the Content, together with all intellectual
            property rights therein and relating thereto (including without
            limitation all engagement, rating, and ranking systems and
            methodologies on the Apps) are the property of Tova Medical, Tova
            Medical or our affiliates, excluding your rights under law to access
            any personal health information related to Virtual Consults, subject
            to applicable laws.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You must ask our permission to use our Trademarks.
            </Typography>
          </p>
          <p>
            Tova Medical has obtained or applied for various trademarks and
            service marks. The mark Tova Medical, the Tova Medical logo, other
            Tova Medical trademarks and service marks and other Tova Medical
            logos and product and service names are our trademarks (the "Tova
            Medical Marks"). If you would like to use or display any Tova
            Medical Marks, you must ask our permission. You agree not to display
            or use in any manner the Tova Medical Marks without our prior
            written permission. Any use you are permitted by our prior written
            authorization to make of any Tova Medical Marks must be in
            accordance to the media use guidelines we publish, and all benefit
            therefrom shall accrue to Tova Medical. All trademarks, brands, and
            content on Tova Medical Apps and Services are the property of their
            respective owners, and nothing in this TOU grants you any rights
            whatsoever in such trademarks, brands and content.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You agree not to infringe our Copyrights, Trademarks, Trade
              Secrets, Patents or other intellectual property rights.
            </Typography>
          </p>
          <p>
            You agree not to engage in any activity that would constitute
            patent, copyright. industrial design, trademark, trade secret or any
            other type of proprietary or intellectual property infringement with
            respect to the Apps, Content, Services or Virtual Consults, or
            assist or induce any other person or entity to do so.
          </p>
          <p>
            You may not reproduce, create derivative works of, distribute,
            publicly perform or publicly display the Content or any portion
            thereof without the prior written consent of Tova Medical. You may,
            however, use the Apps as they are designed and intended to be used,
            including using App features which allow you to re-post Content or
            portions of Content, including those which permit you to distribute
            Content through other third party applications and mediums (such as
            Facebook or Twitter), so long as you do not modify that Content or
            the functionality of those features in any way and attribution to
            the author of the Content is included in such re-post. This right to
            re-post Content does not create for you or grant to you any
            additional rights in such Content, including but not limited to any
            right of ownership. Additionally, you may not use any metatags or
            any other "hidden text" utilizing the work "Tova Medical" without
            prior written permission.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You agree not to access, attempt to access, or use our Data
              without our Permission.
            </Typography>
          </p>
          <p>
            You agree not to access, attempt to access, request access or use of
            any Apps, Content, Services or any of our data of the data relating
            to any of the foregoing (collectively the "Data"), without our prior
            written authorization. This means that you agree not to download,
            upload, post, license, disclose, modify, translate, decompile, copy,
            distribute, reverse engineer, disassemble, broadcast, transmit,
            publish, remove or alter any proprietary notices or labels, license,
            sublicense, transfer, sell, mirror, frame, exploit, rent, lease,
            private label, grant a security interest in, or otherwise use the
            Apps, Content Services, or Data therein or relating thereto in whole
            or in part, in any form or by any means, unless expressly permitted
            herein or you have our prior written authorization to do, and that
            of Tova Medical for any of the foregoing that is owned by Tova
            Medical. Moreover, you shall not, nor shall you permit any third
            party or person to, disable, circumvent, or otherwise avoid any
            security device, digital rights management, mechanism, protocol, or
            procedure implemented by Tova Medical for use of the Apps, Content,
            Services or Data therein or relating thereto.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Contact us if you believe materials on our Apps infringe your
              copyright.
            </Typography>
          </p>
          <p>
            If you believe any materials accessible on or from Tova Medical or
            the Apps, Content or Services infringe your valid and enforceable
            copyright, you may request removal of (or access to) those materials
            (or access thereto) from us by contacting us and providing us with
            information. We will review the information that you provide and may
            request removal of such materials by Tova Medical or remove such
            content if within Tova Medical's ability, at our sole discretion in
            accordance with applicable laws.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Submissions
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You agree to abide by our Submission Guidelines
            </Typography>
          </p>
          <p>
            You agree that you will not upload or transmit any communications or
            content, or provide us with any information, of any type that
            infringes or violates any rights of any party. The personal
            information you submit to Tova Medical is governed by the{" "}
            <NavLink to="/privacy/">Privacy Statement</NavLink>, the terms of
            which shall govern in the event of any inconsistency with this TOU
            and if you provide us with any personal information of any third
            party you hereby represent and warrant that you have all requisite
            consents to provide us with such information for use in accordance
            with this TOU and our Privacy Statement. If you are a member, you
            agree to comply with the{" "}
            <NavLink to="/terms/#memberGuidelines">
              Member Submission Terms and Policies
            </NavLink>
            . If you are a Medical Expert, you agree to comply with the{" "}
            <NavLink to="/terms/medical-experts/#expertGuidelines">
              Expert Submission Terms and Policies
            </NavLink>
            .
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You give us rights in what you submit.
            </Typography>
          </p>
          <p>
            With the exception of any personal data or information you submit
            (which shall be maintained in accordance with our Privacy Statement
            and other information governed by applicable Canadian and provincial
            privacy laws, rules and regulations), if you make any submissions
            (by email or otherwise) on, to or through Tova Medical Apps and
            Services, including but not limited to data, questions, comments,
            suggestions, business information, ideas, concepts, techniques or
            inventions (the "Submissions"), you make such Submissions without
            any restrictions or expectation of compensation or of
            confidentiality, and you agree that your Submissions may be used
            without restriction for any purpose whatsoever, and you
            automatically grant, or have obtained rights from the owner of such
            Submissions and the intellectual property relating thereto to
            automatically and expressly grant, Tova Medical and third parties
            that license the Tova Medical Apps and Services a royalty-free,
            perpetual, irrevocable, worldwide, nonexclusive license right, with
            a right of sublicense, to freely use the whole or any portion of
            such Submissions in any way, commercial or otherwise, and for any
            purpose whatsoever, including the right to copy, reproduce, modify,
            adapt, publish, transmit, translate, create derivative works from,
            publicly perform or display (in any media or form now known or
            hereafter developed), create improvements and derivative works from,
            develop inventions from and manufacture, sell, lease or otherwise
            use such inventions, marketing any product or service relating to or
            developed from the Submissions, communicate to the public, or
            otherwise use the Submissions. You further waive any and all of your
            moral rights in the Submissions in favour of Tova Medical, and have
            caused any other owner of such Submissions and the intellectual
            property relating thereto to waive their moral rights in the
            Submissions in favour of Tova Medical. All Submissions will be
            treated as non-confidential and nonproprietary at Tova Medical's
            sole discretion.
          </p>
          <p>
            Do not provide any Submissions to us if you don't want to give us
            rights to the Submissions. If you wish to keep any business
            information, ideas, concepts or inventions private or proprietary,
            do not submit them on, through, or to Tova Medical, by email, posts,
            any means of uploading information to the Apps, Services or
            otherwise.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Medical Experts
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              About Tova Medical Medical Experts
            </Typography>
          </p>
          <p>
            Medical Experts on the Apps include respected doctors, specialists
            or professionals throughout the US, Mexico, UK and Canada. Only US,
            Mexico, UK and Canadian-licensed doctors, specialists or
            professionals may participate as Medical Experts on Tova Medical.
            Medical Experts whose licenses are or become suspended or revoked,
            for any reason, are not permitted and agree not to participate in
            any way, including but not limited to submitting Content as a
            Medical Expert. Medical Experts are subject to Tova Medical's{" "}
            <NavLink to="/terms/#submissionsExperts">
              Expert Submission Terms and Policies
            </NavLink>
            and{" "}
            <NavLink to="/terms/#addtionalTermsExpert">
              Additional Terms that Apply to Experts
            </NavLink>
            .
          </p>
          <p>
            Virtual Consults are provided by Tova Medical Practitioners (who may
            or may not also be Medical Experts) who are licensed by their
            provincial health regulatory body. The Tova Medical Practitioner
            conducting the Virtual Consult may not be physically located in the
            province in which you reside. Depending on the licensing
            requirements of the province in which you are located, the Tova
            Medical Practitioner may or may not need to be licensed in your
            province.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Personally Identifiable Information
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Our Members have a Right to the Privacy of their Identity and
              Personal Information.
            </Typography>
          </p>
          <p>
            Tova Medical does not disclose personally identifiable information
            about our members. Please see our{" "}
            <NavLink to="/privacy">Privacy Statement</NavLink>for details.
            Virtual Consults are subject to the Tova Medical's{" "}
            <NavLink to="/terms/#additionalVirtualConsult">
              Additional Terms: Virtual Consult
            </NavLink>
            and{" "}
            <NavLink to="/terms/#virtualConsultConsent">
              Virtual Consult Consent
            </NavLink>
            .
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Termination
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              All of Our Members are Required to Honour this TOU.
            </Typography>
          </p>
          <p>
            Your permission to use the Apps, Services, Content and Virtual
            Consults ends immediately if you violate any of the terms of this
            TOU. We may place limits on, modify, or terminate your right to
            access and use Tova Medical Apps, Services, Virtual Consults and/or
            Content at any time. This suspension or termination may involve the
            deletion of information, files, and other previously available
            Content and/or Submissions. Tova Medical reserves the right to
            terminate your rights to the Apps, Content, Services and Virtual
            Consults without any notification to you, in its sole discretion. In
            the event your account is suspended or terminated, you will be
            contacted via email.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Changes to this TOU
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              We may Update these Terms of Use.
            </Typography>
          </p>
          <p>
            When we make changes, we will revise the "last modified" date at the
            top of this TOU. We encourage you to review this TOU periodically.
            Your continued use of Tova Medical Apps, Content, Services and/or
            Virtual Consults constitutes your agreement to the changed, modified
            or altered TOU, as set out herein.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Indemnification &amp; Exclusions and Limitations
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Exclusion of Warranties
            </Typography>
          </p>
          <p>
            THE APPS, CONTENT, SERVICES AND VIRTUAL CONSULTS ARE PROVIDED "AS
            IS." NEITHER WE, TOVA MEDICAL PRACTITIONERS, MEDICAL EXPERTS, NOR
            ANY OF OUR LICENSORS MAKE ANY EXPRESS WARRANTIES, AND WE AND EACH OF
            THEM DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING IMPLIED WARRANTIES
            OF ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. NEITHER WE NOR ANY OF OUR LICENSORS MAKE ANY
            WARRANTY THAT CONTENT OR SERVICES SATISFY GOVERNMENT REGULATIONS.
            TOVA MEDICAL APPS, CONTENT, SERVICES AND VIRTUAL CONSULTS WERE
            DEVELOPED FOR USE IN CANADA, AND NEITHER WE NOR ANY OF OUR LICENSORS
            MAKE ANY REPRESENTATION CONCERNING TOVA MEDICAL APPS, CONTENT,
            SERVICES OR VIRTUAL CONSULTS WHEN USED IN ANY OTHER COUNTRY.
          </p>
          <p>
            SOME CANADIAN JURISDICTIONS PROHIBIT THE DISCLAIMER OF CERTAIN
            WARRANTIES OR CONDITIONS OR THE LIMITATION OF CERTAIN TYPES OF
            LIABILITY. IN SUCH CIRCUMSTANCES, TO THE EXTENT THAT SUCH
            PROHIBITIONS PROHIBIT ANY EXCLUSIONS AND LIMITATIONS IN THESE TERMS
            OF USE, SUCH EXCLUSIONS AND LIMITATIONS WILL NOT APPLY TO YOU
            STRICTLY TO THE EXTENT NECESSARY TO MAKE THESE TERMS OF USE
            CONSISTENT WITH SUCH PROHIBITIONS.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Limitation of Liability of Tova Medical
            </Typography>
          </p>
          <p>
            We cannot and do not assume any responsibility or liability for the
            use or misuse, by you or any third party, of any Content,
            Submissions, or other information submitted, transmitted, or
            received via our Apps and Services or Virtual Consults.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Use at Your Own Risk
            </Typography>
          </p>
          <p>
            The Apps, Content, Services and Virtual Consults are provided on as
            "as is" basis. NEITHER WE, TOVA MEDICAL PRACTITIONERS, MEDICAL
            EXPERTS, NOR ANY OF OUR LICENSORS MAY BE HELD LIABLE UNDER THIS TOU
            FOR ANY DAMAGES OTHER THAN DIRECT DAMAGES, EVEN IF THE PARTY KNOWS
            OR SHOULD KNOW THAT OTHER DAMAGES ARE POSSIBLE, OR THAT DIRECT
            DAMAGES ARE NOT A SATISFACTORY REMEDY. THE LIMITATIONS IN THIS
            SECTION APPLY TO YOU ONLY TO THE EXTENT THEY ARE LAWFUL IN YOUR
            JURISDICTION. WE DISCLAIM ALL WARRANTIES EXPRESS OR IMPLIED,
            STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES'
            RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. Specifically, and
            without limiting the foregoing, We, Tova Medical Practitioners,
            Medical Experts, our licensors, and our suppliers, make no
            representations or warranties about: (i) the accuracy, reliability,
            completeness, currency, or timeliness of the Content provided on or
            through the use of Tova Medical Apps, Content, Services or Virtual
            Consults, whether by Tova Medical or any other party; or (ii) the
            satisfaction of any government regulations requiring disclosure of
            information on prescription drug products or the approval or
            compliance of any software tools with Tova Medical. Any location
            data accessed via the Apps may be inaccurate or incomplete and any
            use of such data is at your own risk.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Specific Limitation of Liability Regarding Expert Content on Tova
              Medical.
            </Typography>
          </p>
          <p>
            Content or Submissions on the Apps or Services or created, modified,
            submitted or validated by Medical Experts or other healthcare
            experts (collectively, "Expert Content") are subject to the
            following additional terms and conditions. (When we use the term
            Content or Submission elsewhere in this TOU, those terms include
            Expert Content.) The author or third party posting such content
            ("Poster") is solely responsible for the Expert Content. While we
            hope that you will find the Expert Content informative and
            educational, neither we nor the Poster make any representations or
            warranties with respect to any information offered or provided
            within or through the Expert Content regarding treatment of medical
            conditions, actions, or application of medication, or otherwise.
            Under no circumstances, as a result of your use of the Expert
            Content or the Apps and Services, will Tova Medical, the Expert
            Content Poster or such Poster's employer or sponsor be liable to you
            or to any other person for any damages or harm-including any direct,
            indirect, special, incidental, exemplary, consequential or other
            damages under any legal theory, including, without limitation, tort,
            contract, strict liability or otherwise, even if advised of the
            possibility of such damages. Without limiting the generality of the
            foregoing, Tova Medical, the Poster, and such Poster's employer
            and/or sponsor, shall have absolutely no liability in connection
            with Tova Medical Apps or Services or for: (a) any loss or injury
            caused, in whole or in part, by the Poster's actions, omissions, or
            negligence, in procuring, compiling, or delivering information
            within or through Expert Content; (b) any errors, omissions, or
            inaccuracies in such information regardless of how caused, or delays
            or interruptions in delivery of such information; or (c) any
            decision made or action taken or not taken in reliance upon such
            information. This means you should not rely on the Expert Content or
            make medical or other important decisions based on it, and it also
            means that Tova Medical and the Posters are not responsible for what
            you do or don't do with the Expert Content or the Content. For
            medical advice, treatment, or diagnosis, see your personal doctor or
            healthcare provider. You agree to indemnify and hold Tova Medical,
            the Poster, and the Poster's employer and/or sponsor, harmless from
            any claim or demand, including attorneys' fees, made by any third
            party as a result of (1) any Expert Content, Content or other
            content posted or made available by you, including that made
            available via Expert Content, (2) any violation of any law that
            occurs due to your use of Expert Content or the Apps or Services
            and/or (3) anything you do using Expert Content, the Apps or
            Services and/or the information contained in any of the foregoing.
          </p>
          <p>
            NEITHER WE, THE TOVA MEDICAL PRACTITIONERS, MEDICAL EXPERTS, NOR ANY
            OF OUR LICENSORS MAY BE HELD LIABLE UNDER THIS TOU FOR MORE THAN ONE
            HUNDRED DOLLARS ($100).
          </p>
          <p>
            Tova Medical Tova Medical Practitioners, Medical Experts, Tova
            Medical licensors, Tova Medical suppliers, and any third parties
            mentioned on Tova Medical Apps or Services are not liable for any
            personal injury, including death, attributable to or caused by your
            use or misuse of Tova Medical Apps, Content (including member or
            user supplied Content, or Medical Expert supplied Content), Services
            or Virtual Consults. Any claims arising in connection with your use
            of Tova Medical Apps, Content, Services or Virtual Consults must be
            brought within one (1) year of the first date of the event giving
            rise to such action. Remedies under this TOU are exclusive and are
            limited to those expressly provided for in this TOU. The limitations
            of liability in this section do not apply to breaches of
            intellectual property provisions, or any of your obligations herein,
            by you, or your indemnification obligations relating hereunder.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Remedies
            </Typography>
          </p>
          <p>
            IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT OR MATERIALS ON OUR
            APPS, OR ANY SERVICES OR VIRTUAL CONSULTS, OR ANY INFORMATION
            AVAILABLE THROUGH THE APPS, CONTENT, SERVICES OR VIRTUAL CONSULTS,
            YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING
            OUR APPS, CONTENT, SERVICES AND VIRTUAL CONSULTS. THIS LIMITATION
            APPLIES EVEN IF YOUR REMEDIES UNDER THIS TOU FAIL OF THEIR ESSENTIAL
            PURPOSE.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Licensed Content
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Certain Content may be licensed from third-parties.
            </Typography>
          </p>
          <p>
            The licenses for some of this Content may contain additional terms.
            When such Content licenses contain additional terms, we will make
            these terms available to you on those pages or in the Terms of Use.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              General Legal Terms
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              General Terms
            </Typography>
          </p>
          <p>
            This TOU is the entire agreement between you and us relating to Tova
            Medical Apps, Content, Services and Virtual Consults. This TOU
            replaces any prior agreements unless such prior or subsequent
            agreement explicitly provides otherwise and specifically references
            this TOU. If there is any conflict between this TOU and a mutually
            signed written agreement between you and us related to Tova Medical
            Apps, Content, Services or Virtual Consults, the signed written
            agreement will control.
          </p>
          <p>
            We reserve the right to modify or discontinue our Apps, Content,
            Services and Virtual Consults (including but not limited to Tova
            Medical Apps and Services and Virtual Consults) with or without
            notice to you, and you agree that we are not liable to you or any
            third party should we modify or discontinue any services, and that
            your only recourse is to cease using the Apps, Content, Services and
            Virtual Consults. Continued use of Apps, Content, Services or
            Virtual Consults following any such changes will indicate your
            acknowledgement of such changes and satisfaction with the Apps,
            Content, Services and Virtual Consults as modified.
          </p>
          <p>
            Other parties may have rights under this TOU. A "third party
            beneficiary" is another party (for example, a company) who is not
            directly mentioned in an agreement, but who may have some rights
            arising out of an agreement. Our licensors may be third party
            beneficiaries to this TOU pursuant to our agreements with them. To
            the extent our licensors are third party beneficiaries to this TOU,
            the rights and protections provided to us under this TOU inure to
            their benefit.
          </p>
          <p>
            If we provide you with a translation of the English language version
            of this TOU, the English language version of this TOU will control
            if there is any conflict.
          </p>
          <p>
            If we choose not to enforce any provision of this TOU, we retain the
            right to enforce it in the future. This means that the failure to
            enforce any provision of this TOU does not constitute a waiver of
            that provision. If any provision in this TOU is found to be
            unenforceable, it and any related provisions will be interpreted to
            best accomplish the unenforceable provision's essential purpose.
          </p>
          <p>
            This TOU is governed by the laws of the province of Quebec. Nothing
            in this TOU limits either party's ability to seek equitable relief.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Guidelines: Members
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Member Submissions Terms and Policies
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              The Basics
            </Typography>
          </p>
          <p>
            On Tova Medical Apps only informational questions submitted for
            educational purposes are allowed. No personal health questions are
            permitted - questions may describe a general situation,
            illustration, illness or symptoms but please do not provide facts
            that give questions the impression of being unique to a single
            patient.
          </p>
          <p>
            No requests for prescription, diagnosis or treatment should be made
            on Tova Medical (for medical care, please use Tova Medical Prime and
            Concierge, as appropriate and available). Any second opinions
            received on Tova Medical are not diagnosis, prescription, or
            treatment, and are for informational purposes only. The content of
            any response is at the sole discretion of the Medical Expert
            answering the question.
          </p>
          <p>
            There is no guarantee that any submitted question will be answered;
            questions are answered at the sole discretion of participating
            Medical Experts. No follow up questions or personal information
            should be included in Thanks notes or other posts on or through Tova
            Medical.
          </p>
          <p>
            IN CONSIDERATION FOR PERMISSION TO USE TOVA MEDICAL YOU AGREE TO
            ABIDE BY ALL APPLICABLE TERMS OF USE WHEN POSTING AND NOT TO DO ANY
            OF THE FOLLOWING, WHICH CAN RESULT IN YOUR IMMEDIATE ACCOUNT
            CANCELLATION:
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Excluding Tova Medical Premium Services, No Individual or
                  Specific Patient Questions.
                </Typography>
                Do not post any facts that give the impression that a question
                is uniquely patient-specific, or are about a specific person,
                including yourself.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Only Adults May Post.
                </Typography>
                Minors may not create their own profiles on Tova Medical and
                Tova Medical does not permit the posting of questions by persons
                under 13 years of age. Caregivers may post educational questions
                related to authorized care recipients (such as the mother
                posting questions about an infant).
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Posting of Personally Identifiable Information.
                </Typography>
                Do not post name(s), email address(es), or telephone number(s),
                URLs, or any other confidential or Personally Identifiable
                Information for you or any other person or entity on Tova
                Medical.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Posting for Illegal Purposes.
                </Typography>
                Do not use Tova Medical for any purpose in violation of local,
                state, federal, or international laws.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Infringing or Impersonating Postings.
                </Typography>
                Do not post material that infringes on the copyrights or other
                intellectual property rights of others or on the privacy or
                publicity rights of others; do not post impersonating another
                person or entity.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Inappropriate Postings.
                </Typography>
                Do not post material that is unlawful, misleading, obscene,
                derogatory, defamatory, threatening, harassing, abusive,
                slanderous, hateful, or embarrassing to any other person or
                entity as determined by us in our sole discretion.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Excessive or Inappropriate use.
                </Typography>
                Do not use the Apps excessively or inappropriately or in ways
                that the Apps were not designed for or that are not, in the
                opinion of Tova Medical doctors, medically appropriate or
                legitimate.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Promotions or Links.
                </Typography>
                Do not post advertisements or solicitations or links to other
                websites or individuals.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Spam or Schemes.
                </Typography>
                Do not post the same question more than once or "spam" Tova
                Medical; no posting of chain letters or pyramid or other
                schemes.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Detrimental Behavior.
                </Typography>
                Engaging in any other conduct that restricts or inhibits any
                other person from using or enjoying Tova Medical, or which, in
                the judgment of Tova Medical, exposes us or any of our members,
                partners or suppliers to any liability or detriment of any type.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Data Mining.
                </Typography>
                Do not use any robot, spider, other automatic device process or
                means, or similar data gathering, data mining, or extraction
                methods to access the Apps or Services for any purpose including
                monitoring or copying any of the material on the Apps or
                Services;
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Monitoring or Copying.
                </Typography>
                Do not use any manual or digital process to monitor or copy any
                of the material on the Apps or Services or for any other
                unauthorized purpose without the prior written consent of Tova
                Medical;
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Misrepresentation.
                </Typography>
                Do not use the Apps or Services to fraudulently misrepresent
                yourself, impersonate another person, engage in false
                advertising; to defraud or defame any person, or to engage or
                otherwise participate in any ponzi scheme, pyramid scheme, chain
                letter, unsolicited bulk or commercial emails, or to collect any
                personal or personally identifiable information from any user of
                the Apps or Services with that person's knowledge or consent;
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Interference.
                </Typography>
                Do not create derivative works of, reverse engineer, decompile,
                disassemble, adapt, translate, transmit, arrange, modify, copy,
                bundle, sell, sub-license, export, merge, transfer, adapt, loan,
                rent, lease, assign, share, outsource, host, publish, make
                available to any person or otherwise use, either directly or
                indirectly, the content in whole or in part, in any form or by
                any means whatsoever, be they physical, electronic or otherwise
                the Apps, Services, Content or Submissions; or remove any
                copyright, trademark, or other proprietary rights notice from
                the Apps, Content or Services; or attempt to defeat any security
                measures that we take to protect the Apps, Content or Services.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  No Use Non-compliant with TOU.
                </Typography>
                Use the Apps, Content or Services other than for their intended
                purpose, or in any manner not expressly permitted in this TOU.
              </p>
            </li>
          </ul>
          <p>
            Such unauthorized use is a material breach of this TOU, and may also
            violate applicable laws including without limitation copyright and
            trademark laws, the laws of privacy and publicity, and applicable
            communications regulations and statutes.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Rules for Submitting Questions or Posting Content on Tova Medical
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You Agree to Our Terms and Conditions and Rules
            </Typography>
          </p>
          <p>
            By using (including by accessing or attempting to access) Tova
            Medical Apps or Services you agree that we have the right (but are
            not obligated) to: investigate an allegation that a communication,
            Submission or Content does not conform to this TOU and determine in
            our sole discretion to remove or request the removal of the
            communication, Submission or Content; remove Submission or Content
            (including member-submitted questions) that we determine, in our
            sole discretion, to be abusive, illegal, or disruptive, or that
            otherwise fails to conform to this TOU; terminate a member's access
            to Tova Medical Apps or Services upon any breach of any of this TOU;
            terminate a member's access to Tova Medical Apps or Services if the
            member's registration information and/or email address is no longer
            valid; and remove any communication in Tova Medical Apps and
            Services; regardless of whether such communication violates these
            standards. We reserve the right to take any other action we deem
            necessary to protect the personal safety of our members, visitors,
            and the public.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Only Submit what You have a Right to Submit
            </Typography>
          </p>
          <p>
            If you make any such submission you agree that you will not send or
            transmit to Tova Medical any communication or content that infringes
            or violates any rights of any party. If you submit any business
            information, idea, concept or invention to Tova Medical by email or
            otherwise, you agree such submission is non-confidential for all
            purposes. You agree to only post or upload Media (like photos) that
            you have taken yourself or that you have all rights to post or
            transmit and license, and which do not violate copyright, trademark,
            privacy or any other rights of any other person. By uploading any
            media on Tova Medical, you are representing and warranting to us
            that you have permission from all persons appearing in your media
            for you to use the media in this way, and to grant the rights
            described in these Terms of Use. Never post a picture with someone
            else unless you have their explicit permission.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Be a Responsible and Sensitive Community Member
            </Typography>
          </p>
          <p>
            It is strictly prohibited to upload media of any kind that contain
            expressions of hate, abuse, offensive images or conduct, obscenity,
            pornography, sexually explicit, medically inappropriate, or any
            material that could give rise to any civil or criminal liability
            under applicable law or regulations or that otherwise may be in
            conflict with these TOU our{" "}
            <NavLink to="/privacy/statement/">Privacy Statement</NavLink>or any
            other Tova Medical policy.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Don't Upload any Viruses or Software
            </Typography>
          </p>
          <p>
            You agree that you will not upload any material that contains
            software viruses or any other computer code, files or programs
            designed to interrupt, destroy or limit the functionality of any
            computer software or this Web site.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You Grant Us a Right when You Upload or Submit Media or Content
            </Typography>
          </p>
          <p>
            We need to have the right to display the content and media you
            upload to us. By uploading any media, like a photo, (a) you grant to
            us a perpetual, non-exclusive, worldwide, royalty-free license to
            use, copy, print, display, reproduce, modify, publish, post,
            transmit and distribute the media and any material included in the
            media; and (b) you certify that any person pictured in the submitted
            media (or, if a minor, his/her parent/legal guardian) authorizes
            Tova Medical to use, copy, print, display, reproduce, modify,
            publish, post, transmit and distribute the media and any material
            included in such media; and (c) you agree to indemnify Tova Medical
            and its affiliates, directors, officers and employees and hold them
            harmless from any and all claims and expenses, including attorneys'
            fees, arising from the media and/or your failure to comply with
            these terms.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              You Understand that We may Review and Reject any Submission
            </Typography>
          </p>
          <p>
            We reserve the right to review all media or any other submission
            prior to or after submission to the site and to remove any media or
            any submission for any reason, at any time, without prior notice, at
            our sole discretion.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Guidelines: Medical Experts
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Tova Medical Expert Submissions Terms and Policies
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Expert Submissions
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Please note: The following guidelines apply to Tova Medical
              Services, where there is no provision of care and the practice of
              medicine is not permitted. Different guidelines apply to Tova
              Medical Virtual Consults, where you can engage in virtual video /
              audio / text consultations with patients within the context of a
              doctor-patient relationship.
            </Typography>
          </p>
          <p>
            If you are a Medical Expert, by submitting Content to Tova Medical
            you agree to abide by these guidelines and terms. A few things you
            should know:
          </p>
          <ul>
            <li>
              <p>
                You are only allowed to provide informational answers for
                educational purposes
              </p>
            </li>
            <li>
              <p>No personal, individualized health answers are permitted</p>
            </li>
            <li>
              <p>You have no obligation to answer any submitted question</p>
            </li>
            <li>
              <p>
                You should make expert submissions only when you reasonably
                believe that they are likely to be useful and helpful to Tova
                Medical members
              </p>
            </li>
            <li>
              <p>
                You should agree with expert submissions only when you have
                fully reviewed and actually agree with the content, and do not
                agree with your own Submissions
              </p>
            </li>
            <li>
              <p>
                If you participate in the Tova Medical University Program you
                must: edit student and assistant answers before verifying them
                if you believe that you can improve the educational and
                informational quality of the answers, and verify student and
                assistant answers only when you reasonably believe that they are
                likely to be useful and helpful to Tova Medical members and when
                they meet the same standards you would apply to your own answers
              </p>
            </li>
          </ul>
          <p>
            IN CONSIDERATION OF PERMISSION TO USE THE PUBLIC AREAS OF THE APPS
            YOU AGREE TO ABIDE BY ALL OTHER TERMS OF USE WHEN POSTING AND NOT TO
            DO ANY OF THE FOLLOWING, WHICH CAN RESULT IN YOUR IMMEDIATE
            SUSPENSION OR TERMINATION AS A MEDICAL EXPERT:
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Avoid Practicing Medicine
            </Typography>
          </p>
          <p>
            Tova Medical Medical Expert answers should be relevant content that
            is educational and informational. Answers can provide general
            guidance, context, and illustration. You are welcome to refer to
            general medical conditions, symptoms and treatments, but please do
            not diagnose any individual member or prescribe any specific
            treatment in your answers. Individualized or diagnostic answers are
            not allowed and prescribing medications is not allowed. Answers
            should not provide specific treatment recommendations or give the
            impression that they are a substitute for a real-life consultation
            with a doctor. YOU ARE NOT PERMITTED TO PRACTICE MEDICINE ON OR
            THROUGH TOVA MEDICAL APPS, CONTENT OR SERVICES, EVEN IF YOU ARE
            INTERACTING WITH AN ACTUAL PATIENT OF YOURS. ALL MEDICAL DIAGNOSIS,
            TREATMENT, PRESCRIPTION AND ACTUAL CARE MUST OCCUR ON TOVA MEDICAL
            VIRTUAL CONSULTS OR OUTSIDE OF TOVA MEDICAL, REGARDLESS OF ANY
            EXISTING RELATIONSHIP BETWEEN THE PARTIES.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Avoid Revealing Private Information
            </Typography>
          </p>
          <p>
            Feel free to interact professionally with our members and create
            long-lasting relationships with those in your community. However,
            help us keep the personal information of our members confidential.
            Please don't refer to members in or on Submissions or Content in
            public areas of the Apps by their real names, even if this
            information has been disclosed to you.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Avoid Offending Others
            </Typography>
          </p>
          <p>
            Responses should always be appropriate. Brief, casual, and friendly
            responses are the most useful. Help Tova Medical remain a trusted
            resource, by avoiding potentially offensive content whenever
            possible.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Avoid Communications that Suggest a Physician-Patient Relationship
            </Typography>
          </p>
          <p>
            Share your professional clinical expertise though answers that are
            evidence-based and consistent with accepted standards in the medical
            community. Tova Medical Apps and Services are for general questions.
            Refrain from using language that suggests an answer is provided for
            a single member. Unless you are in a private conversation with a
            user on Tova Medical Apps or Services, refrain from answering
            questions that solicit your individual opinion as a single Medical
            Expert. Avoid answering questions or providing answers that suggest
            a duty of care and a physician-patient relationship.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Avoid Commercial Responses
            </Typography>
          </p>
          <p>
            You are allowed to link to external websites that are both
            appropriate and related to your responses, but answers on Tova
            Medical Apps and Services should be complete and should not be used
            for primarily promotional purposes. Please do not plagiarize and
            cite sources where applicable. Using answers as a vehicle to promote
            a product or service is prohibited and is also contrary to your
            professional regulatory obligations. Providing members with useful
            and beneficial information and attracting new patients through
            useful Submissions or Content is permitted, but mere schilling is
            not.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Avoid Advertising or Specifically Soliciting Patients
            </Typography>
          </p>
          <p>
            Patients on Tova Medical Apps and Services can find your
            professional contact information through your virtual practice, and
            can contact you for an appointment. However, please avoid violating
            professional regulatory rules regarding advertising and patient
            solicitation.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Avoid Disclosing Confidential Material
            </Typography>
          </p>
          <p>
            Be careful not to discuss privileged or confidential details of past
            or current patients, or accidentally reveal confidential information
            in an indirect way - such as by discussing hypothetical
            illustrations that could be traced back to specific patients.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Avoid Violating Professional Practice Rules
            </Typography>
          </p>
          <p>
            Avoid and refrain from other actions that violate professional
            practice laws, regulations, practice standards, codes of ethics and
            other professional obligations that apply to you.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Rules Governing Other Submissions
            </Typography>
          </p>
          <p>
            All other information, including suggestions, feedback, ideas,
            concepts and any other materials you disclose or offer in connection
            with Tova Medical Apps or Services, or any Submission or Content
            (the "Feedback"), is governed by the general member submission terms
            of this Agreement. This means that these Submissions are made
            without any restrictions and without any expectation of
            confidentiality or compensation. You agree not to assert any
            intellectual property right or moral right of any kind with respect
            to any such Submissions. You further assign all rights in such
            Feedback to us and waive all moral rights therein. You agree to our
            standard terms governing media Submissions and the{" "}
            <NavLink to="/terms/medical-experts/#expertGuidelines">
              Expert Submission Terms and Policies
            </NavLink>
            , except for the prohibition against uploading a facial portrait
            image of yourself for your profile picture. You agree to your
            likeness, photograph, name, biographical information and any and all
            other information appearing therewith to be provided through the
            Apps and otherwise utilized by Tova Medical.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Additional Terms: Virtual Consults
            </Typography>
          </p>
          <p>
            The following terms apply to your use of Tova Medical Apps and
            Services for Virtual Consults performed by video, audio (voice) or
            text chat, or by asynchronous text inbox consults (collectively,
            "Virtual Consults"). By using Virtual Consults, you represent that
            you understand and agree to all of the following:
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Understandings, Agreements, and Representations
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Virtual Consult Service Agreement
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                You are entering into an agreement with Tova Medical for
                professional health care services to be provided by Tova Medical
                Practitioners.
              </p>
            </li>
            <li>
              <p>
                You are entering into a practitioner - patient relationship with
                the Tova Medical Practitioner who personally performs the
                Virtual Consult for you.
              </p>
            </li>
            <li>
              <p>
                You agree to use your legal name and real personal health
                information in Virtual Consults.
              </p>
            </li>
            <li>
              <p>
                You agree to use only one Tova Medical account for maintaining
                your health records and for all Virtual Consults.
              </p>
            </li>
            <li>
              <p>
                You represent and warrant to Tova Medical that you have your own
                family doctor or other primary care provider.
              </p>
            </li>
            <li>
              <p>
                You understand and agree that a Tova Medical Practitioner can
                only provide minor non-emergency primary-care medical services
                with respect to Virtual Consults.
              </p>
            </li>
            <li>
              <p>
                You understand that you should never delay seeking advice from
                your family doctor or other primary care provider due to
                information provided by a Tova Medical Practitioner through a
                Virtual Consult. You agree to seek emergency help when needed,
                and continue to consult with your family doctor or primary care
                provider as recommended by a Tova Medical Practitioner and/or by
                your family doctor or primary care provider.
              </p>
            </li>
            <li>
              <p>
                You agree that in performing a Virtual Consult, a Tova Medical
                Practitioner may not prescribe for you, the following drugs:
              </p>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Prescriptions for narcotics, controlled drugs and substances
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Prescriptions for medications that are restricted or not
                    approved for use in that jurisdiction
                  </p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>Prescriptions for medications for psychiatric illnesses</p>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <p>
                    Prescriptions for lifestyle medications such as erectile
                    dysfunction or diet drugs
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Tova Medical does not practice medicine and does not participate
                in or interfere with the practice of medicine by Tova Medical
                Practitioners. Tova Medical Practitioners are independent
                contractors; each of whom is responsible for his or her health
                care services, professional activity and compliance with the
                requirements applicable to his or her profession and license.
              </p>
            </li>
            <li>
              <p>
                You understand that if your medical condition warrants emergency
                help, the Tova Medical Practitioner will direct you to the
                nearest local hospital emergency department or emergency room or
                direct you to call an ambulance.
              </p>
            </li>
            <li>
              <p>
                You understand that Virtual Consults offered by Tova Medical are
                not covered by provincial health insurance plans. Tova Medical
                does not charge for insured services that are covered by
                provincial health insurance plans. You understand that physician
                services may be insured by provincial health insurance plans
                when provided in another setting, such as a physician's office,
                hospital or clinic setting.
              </p>
            </li>
            <li>
              <p>
                You agree to refrain from excessive or inappropriate use of
                Virtual Consults, including but not limited to multiple consults
                for the same issue without a valid medical reason, initiating a
                subsequent Virtual Consult before you have received the Summary
                Notes from the Tova Medical Practitioner of the prior Virtual
                Consult, attempts to obtain prescriptions unavailable through
                Tova Medical or any other behavior deemed by Tova Medical or a
                Tova Medical Practitioner to be excessive or inappropriate.
              </p>
            </li>
            <li>
              <p>
                You agree to refrain from contacting or seeking to contact a
                Tova Medical Practitioner for virtual care outside of the
                platform (such as by phone, email, or other messaging system).
                This protects both patients and Practitioners and ensures that
                clinical care is delivered in a reliable, continuous, and
                controlled platform. Tova Medical is not responsible for any
                interactions with Tova Medical Practitioners not conducted on
                the Tova Medical platform.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Personal Health Information
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                In order to provide Virtual Consults, Tova Medical collects
                personal health information about you, and creates a health
                record within a secure database, which is managed and hosted by
                Tova Medical. Your personal health information is stored in the
                US and may be subject to US privacy laws. All reasonable
                measures have been taken to safeguard your personal health
                information, but no computer or phone system is completely
                secure. Tova Medical protects your privacy in accordance with
                its Privacy Statement and Virtual Consult Consent.
              </p>
            </li>
            <li>
              <p>
                You understand and agree that Virtual Consults may involve the
                communication of your personal health information, both orally
                and visually, to Tova Medical Practitioners located in other
                parts of the province or outside of the province in which you
                are located at the time of a Virtual Consult and afterward.
              </p>
            </li>
            <li>
              <p>
                You agree to and accept the{" "}
                <NavLink to="/terms/#virtualConsultConsent">
                  Virtual Consult Consent
                </NavLink>
                .
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Consult Quality
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                Virtual Consults are primary care consults, not specialist
                consults, and are not intended to address medical concerns
                typically addressed by medical specialists.
              </p>
            </li>
            <li>
              <p>
                You agree that Tova Medical Practitioners performing Virtual
                Consults may prescribe allowed medications in such Virtual
                Consults when in the Tova Medical Practitioner's sole judgement
                it is medically appropriate to do so. You agree that you are not
                guaranteed any prescription in a Virtual Consult. The
                determination that a medical concern warrants a prescription is
                always made at the discretion of the Tova Medical Practitioner
                in the context of the Virtual Consult. You understand that it is
                your obligation to provide up-to-date personal health
                information, including information about your medication history
                when seeking a Virtual Consult.
              </p>
            </li>
            <li>
              <p>
                You understand and agree that Tova Medical and Tova Medical
                Practitioners are not responsible for disconnections or
                connection quality issues you may experience during Virtual
                Consults as a result of your mobile device's or computer's
                internet connectivity. Tova Medical may not refund you for
                Virtual Consults in which your experience is impacted by issues
                resulting from your device's or computer's internet
                connectivity.
              </p>
            </li>
            <li>
              <p>
                You agree to refrain from abusive language or engaging in
                inappropriate behavior with the Tova Medical Practitioner during
                a Virtual Consult and agree that the Tova Medical Practitioner
                may terminate a consult at any time should inappropriate
                behavior or language be used or if in the Tova Medical
                Practitioner's sole judgment the consult is no longer
                appropriate or productive.
              </p>
            </li>
            <li>
              <p>
                Tova Medical is not responsible for any costs associated with
                treatment prescribed through a Virtual Consult, for example,
                prescription drugs or devices, or any related referrals.
              </p>
            </li>
            <li>
              <p>
                Tova Medical offers primary-care consults from specific
                physicians on Virtual Consults. Virtual Consults for specialist
                care are not available. Virtual Consults are available under an
                "on call" model where you will be connected with the
                first-available Tova Medical Practitioner.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Refunds
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                Tova Medical agrees to consider refunding you the cost of your
                Virtual Consult performed by video, voice, or text chat in the
                event that you are dissatisfied with the manner in which the
                consultation was conducted. All refund requests require you to
                submit a documented explanation supporting your request for a
                refund, and all such requests are subject to review for approval
                (satisfaction guarantee does not apply to asynchronous inbox
                consults.) This guarantee is subject to your submission to Tova
                Medical of documentation supporting your request for a refund.
                All determinations of your eligibility for a refund are made
                solely by Tova Medical and are final once Tova Medical reaches
                its decision.
              </p>
            </li>
            <li>
              <p>
                You are ineligible for a refund of subscription or one-time
                consult fees if it is determined at Tova Medical's discretion
                that you, at any time, have violated Tova Medical's conduct
                guidelines within a Virtual Consult (including but not limited
                to: inappropriate language or behavior).
              </p>
            </li>
            <li>
              <p>
                You are responsible for monitoring your financial accounts and
                statements, and Tova Medical is not responsible for any charges
                actually or allegedly not authorized by any account holder. In
                the event that Tova Medical agrees to provide you with a refund
                for any reason (including but not limited to claims of
                unauthorized charges), the maximum refund to which you will be
                entitled is sixty (60) days from the time of purchase.
                Unsubscribing from messages from Tova Medical and/or
                uninstalling the Tova Medical App do not automatically cancel
                any subscriptions you have on Tova Medical, nor do these actions
                cancel your obligation to pay for any such subscriptions still
                active on Tova Medical.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Your Rights
            </Typography>
          </p>
          <p>
            You understand that you have all the following rights with respect
            to Virtual Consults:
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Free Choice.
                </Typography>
                I have the right to withhold or withdraw my consent to Virtual
                Consults at any time without affecting my right to future care
                or treatment.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Access to Information.
                </Typography>
                I have the right to request access to my records of personal
                health information relating to a Virtual Consult, including,
                without limitation, the name of the Tova Medical Practitioner,
                and to receive copies of my records for a reasonable fee. I may
                also request correction to my records of personal health
                information if I believe that the information is erroneous or
                incomplete.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Confidentiality.
                </Typography>
                I understand that the laws that protect the confidentiality of
                personal health information apply to Virtual Consults, and that
                no personal health information or images from such interaction
                which identify me will be disclosed to other entities without my
                consent.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Risks.
                </Typography>
                I understand that there are risks from Virtual Consults and that
                Virtual Consults may not be appropriate in all circumstances. I
                understand that any treatment or diagnosis made by a Tova
                Medical Practitioner is based on undertaking a complete
                assessment, including medical history and physical examination
                and a Virtual Consult may be less precise than an in-person
                assessment. Other risks including the following: 1) loss of
                records from failure of electronic equipment, 2) power or other
                technical failures with loss of communication, and 3)
                unauthorized use or disclosure of electronic records by
                outsiders (hackers) or other security or privacy breaches.
                Finally, I understand that it is impossible to list every
                possible risk, that my condition may not be cured or improved,
                and may in fact get worse. I understand that the Tova Medical
                Practitioner is responsible for explaining any risks that may be
                associated with a particular treatment.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Benefits.
                </Typography>
                I understand that I can expect the following benefits from
                Virtual Consults, but that no results can be guaranteed or
                assured: (i) reduced visit time, (ii) rapid innovation of
                treatments, and (iii) focused information. I understand that the
                Tova Medical Practitioner is responsible for explaining any
                benefits that may be associated with a particular treatment.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Follow-up.
                </Typography>
                In the event that the diagnosis and treatment by the Tova
                Medical Practitioner does not resolve the health issue for which
                you sought a Virtual Consult, you agree to consult with your
                family doctor or primary health care provider for follow-up
                treatment; and/or seek treatment, if necessary at a local
                hospital emergency department.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Consequences.
                </Typography>
                I understand that, by having my consent to live Virtual Consults
                performed by video or telephone or asynchronous inbox consults,
                the Tova Medical Practitioner may communicate personal health
                information about me outside the province/jurisdiction.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Quality Assurance.
                </Typography>
                I understand that records related to Virtual Consults may be
                reviewed under a Tova Medical quality assurance program.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Your Obligations
            </Typography>
          </p>
          <ul>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Privacy Statement.
                </Typography>
                You agree to Tova Medical's{" "}
                <NavLink to="/privacy/statement/">Privacy Statement</NavLink>,
                the terms of which are incorporated herein by reference.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  True and Accurate Information
                </Typography>
                You agree that that all information you provide as part of a
                Virtual Consult relates to you or to a person for whom you are
                authorized to act and is current, complete, and accurate.
                Additionally, you agree to update and maintain such information
                as is necessary to provide Virtual Consults to you.
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Payment
                </Typography>
                You agree to pay all fees or charges to your account in
                accordance with the fees, charges, and billing terms in effect
                at the time a fee or charge is due and payable. You authorize
                Tova Medical to immediately invoice your credit card or other
                payment account provided for all fees and charges due and
                payable and agree that no additional notice or consent is
                required. Tova Medical's fees are net of any applicable sales
                tax and if any services or payments for any goods or services
                are subject to sales tax in any jurisdiction. You will be
                responsible for payment of such sales tax and any related
                penalties or interest and will indemnify Tova Medical for any
                liability or expense incurred in connection with such sales
                taxes (including any use tax and any other tax measured by sales
                proceeds that Tova Medical is permitted to pass to you) and Tova
                Medical may automatically charge and withhold such taxes for
                services to be delivered within any jurisdictions that it deems
                is required. Unless otherwise agreed to by Tova Medical in
                writing, all fees paid are non-refundable. Tova Medical reserves
                the right, without notice, to modify, change, terminate, or
                suspend service for a subscription plan you are on if, for any
                reason, payment for such plan is not made or cannot be processed
                on the due date. This right to modify a subscription plan
                includes, but is not limited to, the right to change a
                subscription plan type (for example, from a fixed fee plan
                without consult co-pays to a lower priced fixed-fee plan with
                consult co-pays). You may adjust your subscription settings at
                any time (where applicable, by paying any amounts due).
              </p>
            </li>
            <li>
              <p>
                <Typography className={classes.smallHeading} variant="p">
                  Emergency Help
                </Typography>
                You will agree to seek emergency help when needed or as
                recommended by Tova Medical Practitioners and you agree to
                consult with your family doctor or primary care provider as
                recommended by your family doctor, primary care provider or Tova
                Medical Practitioner.
              </p>
            </li>
          </ul>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Additional Terms that Apply to Medical Experts on Tova Medical
            </Typography>
          </p>
          <p>
            THESE ADDITIONAL TERMS APPLY TO TOVA MEDICAL MEDICAL EXPERTS
            ("ADDITIONAL EXPERT TERMS") WHEN USING TOVA MEDICAL APPS, CONTENT
            AND/OR SERVICES AS A TOVA MEDICAL MEDICAL EXPERT. IF YOU ARE A TOVA
            MEDICAL MEDICAL EXPERT YOU AGREE TO ABIDE BY ALL TOVA MEDICAL TERMS
            OF USE (INCLUDING THIS TOU AND ALL OTHER TERMS APPLICABLE TO MEDICAL
            EXPERTS), AND THAT WHERE A TERM APPLICABLE SPECIFICALLY TO AN EXPERT
            CONTRADICTS A TERM APPLICABLE TO A MEMBER, THE TERM APPLICABLE
            SPECIFICALLY TO A MEDICAL EXPERT SHALL PREVAIL. THESE ADDITIONAL
            EXPERT TERMS APPLY TO YOUR USE OF TOVA MEDICAL AS A TOVA MEDICAL
            MEDICAL EXPERT, YOU HEREBY AGREE TO THESE TOVA MEDICAL TERMS AS WELL
            AS ALL TERMS APPLICABLE TO MEMBERS.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Medical Experts: Information
            </Typography>
          </p>
          <p>
            As a Tova Medical Medical Expert, you are required to register with
            and use your real name, professional contact information, and (if
            you choose to upload a picture) a real image of you in your public
            profile. You consent to Tova Medical's use on or in connection with
            the Apps of your name, likeness, photograph, biographical
            information and other personal information provided by you to Tova
            Medical or publicly available about you.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Medical Experts: Doctors in Good Standing
            </Typography>
          </p>
          <p>
            Licensed doctors may apply to be and participate as Medical Experts
            on Tova Medical Apps and Services. Doctors whose licenses are or
            become suspended or revoked, for any reason, are not permitted and
            agree not to participate in any way, including but not limited to
            submitting Submissions or Content to Tova Medical, as a Medical
            Expert on Tova Medical Apps, Services or Virtual Consults. Tova
            Medical may expand the network to include other licensed medical
            experts.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Medical Experts: You Can Control Your Expert Submissions
            </Typography>
          </p>
          <p>
            You retain the right to edit and delete your own Expert Submissions
            that appear in your Public Profile at any time. You also retain the
            right to use and create derivative works from your own Expert
            Submissions elsewhere (such as on your own practice website, blog,
            or in your own social media posts).
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Medical Experts: Permission to Post and Use Expert Submissions
            </Typography>
          </p>
          <p>
            When a Tova Medical Medical Expert submissions related to any Tova
            Medical feature or function and made for publication on Tova Medical
            (such as an answer to a question or other Expert Content) (an
            "Expert Submission"), you grant Tova Medical an unrestricted,
            non-exclusive, perpetual license right, without limitation, to use,
            reproduce, extract data from or add data to, publish and post any
            such Expert Submissions, including in connection with or on Tova
            Medical Apps, Services or Virtual Consults, including in connection
            with your name. This means that when you make an Expert Submission
            to us, you are giving us rights to this content, including the right
            to post it on Tova Medical Apps, Services and Virtual Consults and
            use it in connection with Tova Medical. You also give us the right
            to give or transfer all rights granted herein to others. While
            Expert Submissions generally are posted to Tova Medical Apps, we do
            not have an obligation to post any particular Expert Submission on
            Tova Medical Apps and reserve the right, at our sole discretion and
            for any reason whatsoever (including but not limited to an Expert
            Submission being reported to us), to not post and/or to remove any
            Expert Submissions at any time.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Medical Experts: Rules when Submitting Expert Content
            </Typography>
          </p>
          <p>
            So that everyone can enjoy Tova Medical Apps, Services and Virtual
            Consults, we have rules for public Expert Submissions. By submitting
            content to Tova Medical you agree to follow our rules for Expert
            Submissions to Tova Medical.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Medical Experts: Conflicts Disclosure Policy
            </Typography>
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Disclosure of Relationships with Industry
            </Typography>
          </p>
          <p>
            You agree to comply with thisConflicts Disclosure Policy("CDP") when
            using Tova Medical Apps, Services or Virtual Consults, or making
            Expert Submissions. This CDP governs the disclosure of financial
            interests by Tova Medical Medical Experts. Conflicts of interest can
            be financial and/or personal. As used in this policy, "Industry"
            means any company, entity, or third party that produces,
            manufactures, or distributes a pharmaceutical, medical device,
            implant, or other medical care-related product or service.
          </p>
          <p>
            All Medical Experts with a material financial relationship with
            Industry must disclose such a relationship in any answer or other
            similar Expert Submission or Content submitted to Tova Medical when
            such Expert Submission or Content mentions any product or service in
            which such an interest exists, with the following or similar
            language: "The author or poster of this content has a financial
            interest in a product or service mentioned herein."
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              No Posting Public Content for Pay
            </Typography>
          </p>
          <p>
            Medical Experts may not post public Expert Submissions or Content on
            Tova Medical in exchange for compensation of any kind, including but
            not limited to as a paid consultant to any entity (including any
            company or organization) or individual. Medical Experts should
            ensure that any conflict or potential conflict of interest does not
            affect or appear to affect his or her contributions of Expert
            Submissions or Content to the Tova Medical Apps or Services. Medical
            Experts approved for Tova Medical Virtual Consults may receive
            compensation for services rendered through Tova Medical Virtual
            Consults.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              No Posting of Content Written by Interested Parties
            </Typography>
          </p>
          <p>
            Medical Experts may not publish Expert Submissions or Content under
            their own names that is written in whole or material part by (a)
            parties with a financial interest in any product or service
            mentioned in the Expert Submissions or Content, or (b) employees of
            Industry. Accepting compensation for posting Expert Submissions or
            Content to public areas and/or posting ghostwriting by interested
            parties on Tova Medical Apps, Services or Virtual Consults is
            prohibited.
          </p>
          <p>
            <Typography className={classes.smallHeading} variant="p">
              Foreign Languages
            </Typography>
          </p>
          <p>
            Where Tova Medical Apps, Services or Content (including this TOU),
            or portions thereof, are translated into languages other than
            English, all such translations are for the convenience of our users
            only, and Tova Medical is not responsible or liable in the event of
            any inaccuracy in such translation. In the event of a conflict
            between the English-language version of this TOU, or any other
            content on Tova Medical Apps, Services or Content, and a version
            that has been translated into another language, the English-language
            version shall control and apply. You understand that Tova Medical
            Apps, Services, Submissions and Content, including but not limited
            to questions and answers, may not have the same meaning in
            translation, and that treatments (including but not limited to
            medication names) and other information may differ from country to
            country and in different languages and may not be available in all
            places.
          </p>
          <p>
            Tova Medical Practitioners may be proficient in English, French or
            both. You understand and agree that Virtual Consults with Tova
            Medical Practitioners may not be available in other languages, and
            you agree not to conduct a consultation with a Tova Medical
            Practitioner in a language in which you are not proficient without
            the use of a professional translator. If you are connected to a Tova
            Medical Practitioner who speaks your language, you understand that
            the Tova Medical Practitioner may not be a native speaker and that
            the Tova Medical Practitioner's ability to communicate may be
            limited. If you choose to use a translator in connection with a
            Virtual Consult, you understand that Tova Medical is not liable for
            any errors or omissions in translation.
          </p>
          <p></p>
        </div>
      </Container>
      <div component="div" style={{ marginTop: "150px" }}></div>
      {/* Section 2 */}
      <Footer />
      {/* /Section 2 */}
    </div>
  );
}

export default Terms;
